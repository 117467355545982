import classNames from 'classnames';
import * as React from 'react';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import Input from 'core/components/Files/Input';
import useHandleAttachments from '../../../hooks/useHandleAttachments';
import styles from './FileInputIcon.module.scss';

interface FileInputIconProps {
    addAttachments: (attachments: ReadonlyArray<File>) => void;
}

function FileInputIcon({ addAttachments }: FileInputIconProps) {
    const handleChangeFiles = useHandleAttachments(addAttachments);
    return (
        <Input
            iconComponent={DocumentIcon}
            onChange={handleChangeFiles}
            classes={{
                root: classNames(styles['icon-button-root']),
            }}
        />
    );
}

export default FileInputIcon;
