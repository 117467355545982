import Select from 'core/components/Select';
import TextField from 'core/components/TextField';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';
import CircularProgress from '@mui/material/CircularProgress';
import useIsMobile from 'core/hooks/useIsMobile';
import { Sex } from '@bondvet/types/animals';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import { Step } from '../../lib/types';
import NextButton from '../NextButton';
import SpeciesSelect from '../SpeciesSelect';
import styles from './Start.module.scss';

export default function Start() {
    const intl = useIntl();
    const isMobile = useIsMobile();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramOrigin = queryParams.get('origin');
    const paramEmail = queryParams.get('email');

    const {
        firstName,
        lastName,
        email,
        patientsQueryLoading,
        emailQueryLoading,
        phone,
        setPhone,
        setOrigin,
        setFirstName,
        setLastName,
        setEmail,
        patients,
        selectedPatient,
        patientPlanTelehealthQuantities,
        pet,
        setPetId,
        setPetName,
        setPetSpecies,
        setStep,
    } = useClientContext();

    React.useEffect(() => {
        if (paramOrigin) {
            setOrigin(paramOrigin);
        }
    }, [paramOrigin, setOrigin]);

    React.useEffect(() => {
        if (paramEmail) {
            setEmail(paramEmail);
        }
    }, [paramEmail, setEmail]);

    const emailValid = !!email && isEmail(email);
    const formValid =
        !!firstName &&
        !!phone &&
        !!lastName &&
        emailValid &&
        (pet._id || (pet.name && pet.species));

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (formValid) {
                setStep(Step.reason);
            }
        },
        [formValid, setStep],
    );

    const handleFirstNameChange = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(value);
    };

    const handleLastNameChange = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(value);
    };

    const handleChangeEmail = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(value);
    };

    const handleChangePhone = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(value);
    };

    const handlePetIdChange = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setPetId(value);
    };

    const handlePetNameChange = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setPetName(value);
    };

    const patientOptions = React.useMemo(
        () =>
            patients.map(({ _id, name: label, patientPlan }) => ({
                value: _id,
                key: _id,
                label:
                    label ||
                    intl.formatMessage({ id: 'intake.form.patient.noName' }),
                badge: patientPlan
                    ? {
                          label: patientPlan?.preventionPlan?.name,
                          className: styles.patientPlanBadge,
                      }
                    : null,
            })),
        [patients, intl],
    );

    const remainingPlanVisits = Math.max(
        (patientPlanTelehealthQuantities?.quantity ?? 0) -
            (patientPlanTelehealthQuantities?.quantityUsed ?? 0),
        0,
    );

    const possessiveAdjective = React.useMemo(() => {
        if (selectedPatient?.sex === Sex.male) {
            return intl.formatMessage({
                id: 'pronouns.male.possessiveAdjective',
            });
        }
        if (selectedPatient?.sex === Sex.female) {
            return intl.formatMessage({
                id: 'pronouns.female.possessiveAdjective',
            });
        }
        return intl.formatMessage({
            id: 'pronouns.neutral.possessiveAdjective',
        });
    }, [intl, selectedPatient?.sex]);

    return (
        <>
            <form
                className={styles.root}
                onSubmit={handleSubmit}
                id="start-form"
                data-cy="startForm"
            >
                <div className={styles['grid-container']}>
                    <TextField
                        id="email"
                        data-cy="email"
                        name="email"
                        value={email}
                        type="email"
                        error={email.length > 0 && !emailValid}
                        onChange={handleChangeEmail}
                        required
                        Icon={() => {
                            if (emailQueryLoading)
                                return <CircularProgress size="1.375rem" />;

                            return null;
                        }}
                        inputProps={{ autoComplete: 'email' }}
                        label={intl.formatMessage({
                            id: 'intake.form.email.label',
                        })}
                        helperText={
                            email.length > 0 &&
                            !emailValid &&
                            intl.formatMessage({
                                id: 'intake.form.email.error',
                            })
                        }
                        classes={{
                            root: styles.control,
                        }}
                    />
                    <TextField
                        id="first-name"
                        data-cy="firstName"
                        name="first-name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        autoFocus
                        required
                        inputProps={{ autoComplete: 'given-name' }}
                        label={intl.formatMessage({
                            id: 'intake.form.firstName.label',
                        })}
                        classes={{
                            root: styles.control,
                        }}
                    />
                    <TextField
                        id="last-name"
                        data-cy="lastName"
                        name="last-name"
                        value={lastName}
                        onChange={handleLastNameChange}
                        required
                        inputProps={{ autoComplete: 'family-name' }}
                        label={intl.formatMessage({
                            id: 'intake.form.lastName.label',
                        })}
                        classes={{
                            root: styles.control,
                        }}
                    />
                    <TextField
                        id="phone"
                        data-cy="phone"
                        name="phone"
                        value={phone}
                        onChange={handleChangePhone}
                        required
                        inputProps={{ autoComplete: 'tel' }}
                        label={intl.formatMessage({
                            id: 'intake.form.phone.label',
                        })}
                        classes={{
                            root: styles.control,
                        }}
                    />

                    <hr className={styles.divider} />

                    {patients.length > 0 && (
                        <Select
                            id="pet-id"
                            name="pet-id"
                            data-cy="petId"
                            onChange={handlePetIdChange}
                            options={patientOptions}
                            value={pet._id}
                            classes={{
                                root: styles.control,
                            }}
                            inputProps={{
                                classes: {
                                    menu: styles.selectPet,
                                },
                            }}
                            label={intl.formatMessage({
                                id: 'intake.form.petId.label',
                            })}
                            placeholder={intl.formatMessage({
                                id: 'intake.form.petId.placeholder',
                            })}
                            disabled={patientsQueryLoading}
                        />
                    )}
                    <TextField
                        id="pet-name"
                        data-cy="petName"
                        name="pet-name"
                        onChange={handlePetNameChange}
                        value={pet._id === '' ? pet.name : ''}
                        classes={{
                            root: styles.control,
                            input: styles.newPetName,
                        }}
                        placeholder={intl.formatMessage({
                            id: 'intake.form.petName.label',
                        })}
                        inputProps={{ autoComplete: 'one-time-code' }}
                        disabled={patientsQueryLoading}
                    />
                    {pet.name && pet._id === '' && (
                        <SpeciesSelect
                            onChange={setPetSpecies}
                            id="petSpecies"
                            name="petSpecies"
                            classes={{ root: styles.control }}
                            value={pet.species}
                            disabled={!formValid}
                        />
                    )}
                    {selectedPatient?.patientPlan && (
                        <div className={styles.patientPlanNote}>
                            {intl.formatMessage(
                                {
                                    id: 'intake.planVisitsNote',
                                },
                                {
                                    patientName: selectedPatient.name,
                                    count: remainingPlanVisits,
                                    pluralS:
                                        remainingPlanVisits === 1 ? '' : 's',
                                    possessiveAdjective,
                                    planName:
                                        selectedPatient?.patientPlan
                                            ?.preventionPlan?.name ?? '',
                                },
                            )}
                        </div>
                    )}
                    {!isMobile && (
                        <NextButton
                            type="submit"
                            disabled={!formValid}
                            form="start-form"
                            variant="grid"
                        />
                    )}
                </div>
            </form>
            {isMobile && (
                <NextButton
                    type="submit"
                    disabled={!formValid}
                    form="start-form"
                    variant="fixed"
                />
            )}
        </>
    );
}
