import * as React from 'react';
import classnames from 'classnames';
import { ReactComponent as VISA } from 'assets/credit-card-icons/visa.svg';
import { ReactComponent as MASTERCARD } from 'assets/credit-card-icons/mastercard.svg';
import { ReactComponent as AMERICANEXPRESS } from 'assets/credit-card-icons/american-express.svg';
import { ReactComponent as DISCOVER } from 'assets/credit-card-icons/discover.svg';
import InputIconButton from '../InputIconButton';
import type { SelectProps } from './types';
import styles from './Select.module.scss';
import { InputComponentProps } from '../InputBase';
import { ReactComponent as Icon } from './assets/arrow.inline.svg';

export interface SelectInputProps extends InputComponentProps, SelectProps {
    classes?: {
        menu?: string;
    };
}

const VALID_KEY_DOWN_KEYS = [
    ' ',
    'ArrowUp',
    'ArrowDown',
    // The native select doesn't respond to enter on MacOS, but it's recommended by
    // https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
    'Enter',
];

type CardIconComponentOptions = {
    [key: string]: React.ElementType;
};

const cardIconComponents: CardIconComponentOptions = {
    VISA,
    MASTERCARD,
    AMERICANEXPRESS,
    DISCOVER,
};

function CardIcon(props: { cardBrand: string | number }) {
    const CardBrand = cardIconComponents[props.cardBrand];
    if (CardBrand) {
        return <CardBrand />;
    }
    return <></>;
}

function SelectInput({
    options = [],
    disabled,
    tabIndex,
    readOnly,
    id,
    'aria-describedby': ariaDescribedBy,
    'aria-label': ariaLabel,
    onOpen,
    onClose,
    inputRef,
    value: valueProp,
    name,
    autoFocus,
    placeholder,
    onChange,
    classes,
    ...props
}: SelectInputProps): React.ReactElement {
    const [open, setOpen] = React.useState(false);
    const displayRef = React.useRef<HTMLDivElement>(null);
    const [value, setValue] = React.useState<string | string[]>(
        valueProp ? (valueProp as string | string[]) : '',
    );

    // watch for changes on the `value` prop
    React.useEffect(() => {
        let handle: number | undefined = requestAnimationFrame(() => {
            handle = requestAnimationFrame(() => {
                handle = undefined;
                setValue(valueProp as string | string[]);
            });
        });

        return () => {
            if (handle !== undefined) {
                cancelAnimationFrame(handle);
            }
        };
    }, [valueProp]);

    // Close menu when the user clicks outside
    React.useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const element = event.target;
            if (
                displayRef &&
                element instanceof Node &&
                !displayRef.current?.parentNode?.contains(element) &&
                open
            ) {
                setOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const update = React.useCallback(
        (newOpen: boolean, event: React.SyntheticEvent) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    if (newOpen) {
                        if (onOpen) {
                            onOpen(event);
                        }
                    } else if (onClose) {
                        onClose(event);
                    }

                    setOpen(newOpen);
                });
            });
        },
        [onOpen, onClose],
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!readOnly) {
            if (VALID_KEY_DOWN_KEYS.indexOf(event.key) !== -1) {
                event.preventDefault();
                update(true, event);
            }
        }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        // ignore everything but left click
        if (event.button !== 0) {
            return;
        }
        // hijack the default focus behavior
        event.preventDefault();
        displayRef.current?.focus();

        update(true, event);
    };

    const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        update(true, event);
    };

    const handleSelect = React.useCallback(
        (event: React.SyntheticEvent, newValue: unknown) => {
            if (value !== newValue) {
                setValue(newValue as string | string[]);
            }
            if (onChange) {
                event.persist();
                Object.defineProperty(event, 'target', {
                    writable: true,
                    value: { value: newValue, name },
                });
                onChange(event as React.FormEvent<HTMLInputElement>);
            }
            update(false, event);
        },
        [onChange, name, value, update],
    );

    const handleItemClick = React.useCallback(
        (event: React.MouseEvent<HTMLLIElement>, newValue: unknown) => {
            event.preventDefault();
            handleSelect(event, newValue);
        },
        [handleSelect],
    );

    const handleItemKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLLIElement>, newValue: unknown) => {
            if (VALID_KEY_DOWN_KEYS.indexOf(event.key) !== -1) {
                event.preventDefault();
                handleSelect(event, newValue);
            }
        },
        [handleSelect],
    );

    let display: string | JSX.Element | undefined = placeholder;
    const displayOption = options.find((option) => option.value === value);

    if (value !== null && value !== undefined && value !== '') {
        if (Array.isArray(value)) {
            display = options
                .filter((option) => value.includes(option.value as string))
                .map((option) => option.label)
                .join(',');
        } else {
            display = displayOption?.cardBrand ? (
                <>
                    <CardIcon cardBrand={displayOption.cardBrand} />{' '}
                    {displayOption?.label}
                </>
            ) : (
                <>
                    {displayOption?.label || display}
                    {displayOption?.badge && (
                        <div
                            className={classnames(
                                styles.badge,
                                displayOption.badge.className,
                            )}
                        >
                            {displayOption.badge.label}
                        </div>
                    )}
                </>
            );
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
    };

    const optionItems = React.useMemo(() => {
        const cardBrandOptions = options.filter(
            (option) => option.cardBrand !== displayOption?.cardBrand,
        );
        const filteredOptions = displayOption?.cardBrand
            ? cardBrandOptions
            : options;
        return filteredOptions?.map((option) => {
            return (
                <li
                    key={option.key || option.label}
                    role="option"
                    aria-selected={value === option.value}
                    aria-label={option.label}
                    className={classnames({
                        [styles.selected]: value === option.value,
                        [styles.newCardOption]: option.value === 'new',
                    })}
                    onClick={(event) => handleItemClick(event, option.value)}
                    onKeyDown={(event) =>
                        handleItemKeyDown(event, option.value)
                    }
                >
                    <button type="button">
                        {option.cardBrand ? (
                            <CardIcon cardBrand={option.cardBrand} />
                        ) : null}
                        {option.label}
                        {option.badge && (
                            <div
                                className={classnames(
                                    styles.badge,
                                    option.badge.className,
                                )}
                            >
                                {option.badge.label}
                            </div>
                        )}
                    </button>
                </li>
            );
        });
    }, [
        options,
        value,
        handleItemClick,
        handleItemKeyDown,
        displayOption?.cardBrand,
    ]);

    return (
        <>
            <div
                ref={displayRef}
                className={classnames(styles.menu, classes?.menu, {
                    [styles.empty]: !value,
                })}
                role="button"
                tabIndex={tabIndex}
                aria-disabled={disabled ? 'true' : undefined}
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedBy}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="listbox"
                onKeyDown={handleKeyDown}
                onMouseDown={disabled || readOnly ? undefined : handleMouseDown}
            >
                <span>{display}</span>
            </div>
            <input
                value={value && Array.isArray(value) ? value.join(',') : value}
                name={name as string | undefined}
                ref={inputRef as React.RefObject<HTMLInputElement> | undefined}
                aria-hidden
                tabIndex={-1}
                disabled={disabled as boolean | undefined}
                {...props}
                className={styles.nativeInput}
                placeholder={placeholder}
                onChange={handleInputChange}
            />
            {!disabled && (
                <InputIconButton
                    icon={Icon}
                    label="Click to select an option"
                    onClick={handleIconClick}
                />
            )}
            <div
                className={classnames(styles.options, {
                    [styles.open]: open,
                })}
                aria-hidden={!open}
            >
                <span>{display}</span>
                <ul role="listbox">{optionItems}</ul>
            </div>
        </>
    );
}

export default SelectInput;
