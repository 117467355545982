import {
    ConfirmedAppointment,
    BookingAppointment,
} from '@bondvet/types/telehealth';
import { onSnapshot } from 'firebase/firestore';
import * as React from 'react';
import useBookingUtils from './useBookingUtils';

type UseBookingReceiver = {
    isSyncActive: boolean;
    activeBookingTab: number | string;
    appointment: BookingAppointment | undefined;
    confirmedAppointment: ConfirmedAppointment | undefined;
};

type UseBookingReceiverProps = {
    rootElement?: React.RefObject<HTMLDivElement>;
};

export default function useBookingReceiver({
    rootElement,
}: UseBookingReceiverProps): UseBookingReceiver {
    const { sessionRef } = useBookingUtils();
    const [isSyncActive, setIsSyncActive] = React.useState(false);
    const [size, setSize] = React.useState({
        width: Infinity,
        height: Infinity,
    });
    const [activeBookingTab, setActiveBookingTab] = React.useState<
        string | number
    >(0);
    const [scrollPosition, setScrollPosition] = React.useState<
        number | undefined
    >();
    const [appointment, setAppointment] = React.useState<BookingAppointment>({
        dateTime: null,
        location: null,
    });
    const [confirmedAppointment, setConfirmedAppointment] =
        React.useState<ConfirmedAppointment>({
            dateTime: null,
            location: null,
            id: null,
        });

    // Read data from firestore
    React.useEffect(() => {
        const unsubscribe = () => {};
        if (sessionRef) {
            return onSnapshot(sessionRef, (snapshot) => {
                const data = snapshot.data();
                if (data) {
                    if (data.active !== undefined) {
                        setIsSyncActive(data.active);
                    }
                    if (
                        data.width !== size.width ||
                        data.height !== size.height
                    ) {
                        setSize({ width: data.width, height: data.height });
                    }
                    setActiveBookingTab(data.activeTab ?? 0);
                    setScrollPosition(data.scrollPosition ?? 0);

                    if (data.newAppointment) {
                        if (
                            data.newAppointment?.dateTime !==
                                appointment?.dateTime ||
                            data.newAppointment?.location?._id !==
                                appointment?.location?._id
                        ) {
                            setAppointment(data.newAppointment);
                        }
                    }

                    if (data.confirmedAppointment) {
                        if (
                            data.confirmedAppointment?.id !==
                            confirmedAppointment?.id
                        ) {
                            setConfirmedAppointment(data.confirmedAppointment);
                        }
                    }
                }
                if (!snapshot.exists()) {
                    setIsSyncActive(false);
                }
            });
        }
        return unsubscribe;
    }, [
        appointment?.dateTime,
        appointment?.location?._id,
        confirmedAppointment?.id,
        sessionRef,
        size,
    ]);

    React.useEffect(() => {
        function resize() {
            const element = rootElement?.current;
            const maxWidth = window.innerWidth;
            const maxHeight = window.innerHeight / 1.5;

            if (element && size.width && size.height) {
                element.style.width = `${size.width.toString()}px`;
                element.style.height = `${size.height.toString()}px`;
                let scale = 1;

                if (maxWidth < size.width) {
                    scale = maxWidth / size.width;
                }

                if (maxHeight < size.height) {
                    scale = Math.min(maxHeight / size.height, scale);
                }
                element.style.transform = `scale(${scale})`;
            }
        }

        resize();
        window.addEventListener('resize', resize);

        return () => window.removeEventListener('resize', resize);
    }, [rootElement, size]);

    React.useLayoutEffect(() => {
        const scrollContainer = rootElement?.current?.querySelector(
            '*[role="tabpanel"]:not([hidden])',
        );

        if (scrollContainer) {
            scrollContainer.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    }, [rootElement, scrollPosition]);

    return {
        isSyncActive,
        activeBookingTab,
        appointment,
        confirmedAppointment,
    };
}
