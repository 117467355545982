import useBookingSyncAppointment from 'core/hooks/useBookingSyncAppointment';
import useCancelAppointment from 'core/hooks/useCancelAppointment';
import useCreateAppointment from 'core/hooks/useCreateAppointment';
import { formatAppointmentTime } from 'core/lib/formatAppointmentTime';
import * as React from 'react';
import { useIntl } from 'react-intl';
import TextField from 'team/components/TextField';
import { useBookingContext } from 'team/hooks/useBookingContext';
import Button from '../Button';
import styles from './BookAppointment.module.scss';

function BookAppointment() {
    const intl = useIntl();
    const {
        setAppointment,
        appointment,
        confirmedAppointment,
        setConfirmedAppointment,
    } = useBookingContext();
    const { notes, setNotes } = useBookingSyncAppointment();
    const { createAppointment } = useCreateAppointment();
    const { cancelAppointment } = useCancelAppointment();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    async function addAppointment(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (appointment) {
            setLoading(true);

            // Cancel existing appointment if there is one
            if (confirmedAppointment?.id) {
                cancelAppointment(confirmedAppointment.id);
            }

            const { appointmentId, error: appointmentError } =
                await createAppointment({
                    appointment,
                    additionalNotes: notes,
                });
            if (appointmentId) {
                setConfirmedAppointment({ ...appointment, id: appointmentId });
                setAppointment({ dateTime: null, location: null });
            }
            if (appointmentError) {
                setError(appointmentError.join('\n'));
            }
            setLoading(false);
        }
    }

    return (
        <form className={styles.root} onSubmit={addAppointment}>
            <h2 className={styles.title}>
                {intl.formatMessage({
                    id: 'booking.create.title',
                })}
            </h2>
            {appointment && (
                <div className={styles.infos}>
                    <div className={styles.time}>
                        {formatAppointmentTime({
                            dateTime: appointment.dateTime,
                            location: appointment.location,
                        })}
                    </div>
                    <div className={styles.location}>
                        {appointment.location?.name}
                    </div>
                </div>
            )}

            <TextField
                label={intl.formatMessage({ id: 'booking.create.notes' })}
                id="name"
                value={notes}
                onChange={(event) => {
                    setNotes(event.target.value);
                }}
                multiline
            />

            <div className={styles.actions}>
                <Button
                    as="button"
                    variant="tertiary"
                    onClick={() =>
                        setAppointment({
                            dateTime: null,
                            location: null,
                        })
                    }
                    label={intl.formatMessage({
                        id: 'booking.create.cancel',
                    })}
                />
                <Button
                    variant="primary"
                    as="button"
                    label={intl.formatMessage({
                        id: 'booking.create.confirm',
                    })}
                    disabled={loading}
                    loading={loading}
                />
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </form>
    );
}

export default BookAppointment;
