import * as React from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import MicIcon from '@mui/icons-material/Mic';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from 'core/components/IconButton';
import { createLocalVideoTrack } from 'twilio-video';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import ChatNotification from 'core/components/ChatNotification';
import useVideoContext from '../../../hooks/video/useVideoContext';
import styles from './VideoUI.module.scss';

interface VideoUIProps {
    hideChatIcon?: boolean;
    hideCameraIcon?: boolean;
    hideCameraSwitchIcon?: boolean;
    hideMicrophoneIcon?: boolean;
    hideEndCallIcon?: boolean;
    onEndCall?: () => void;
    chatOpen?: boolean;
    setChatOpen?: (chatOpen: boolean) => void;
    className?: string;
}

function VideoUI({
    hideChatIcon = false,
    hideCameraIcon = false,
    hideCameraSwitchIcon = false,
    hideMicrophoneIcon = false,
    hideEndCallIcon = false,
    onEndCall,
    chatOpen,
    setChatOpen,
    className,
}: VideoUIProps) {
    const {
        cameraOn,
        setCameraOn,
        microphoneOn,
        setMicrophoneOn,
        room,
        availableVideoDeviceIds,
        currentVideoDeviceIndex,
        setCurrentVideoDeviceIndex,
        setLocalVideoTrack,
        endLocalVideoStream,
    } = useVideoContext();

    const handleCameraOn = () => {
        setCameraOn(!cameraOn);
    };
    const handleMicrophoneOn = () => {
        setMicrophoneOn(!microphoneOn);
    };
    const switchChatOpen = () => {
        if (setChatOpen) setChatOpen(!chatOpen);
        else console.warn('switchChatOpen clicked without a passed function');
    };
    const handleCameraSwitch = () => {
        const newVideoDeviceIndex =
            (currentVideoDeviceIndex + 1) % availableVideoDeviceIds.length;
        setCurrentVideoDeviceIndex(newVideoDeviceIndex);
        endLocalVideoStream();

        createLocalVideoTrack({
            deviceId: availableVideoDeviceIds[newVideoDeviceIndex],
        }).then((newVideoTrack) => {
            setLocalVideoTrack(newVideoTrack);
            room?.localParticipant.publishTrack(newVideoTrack);
        });
    };

    const handleEndCall = () => {
        if (onEndCall) onEndCall();
        else console.warn('endCall clicked without a passed function.');
    };

    const multipleCameras = () =>
        availableVideoDeviceIds && availableVideoDeviceIds.length > 1;

    // Maybe change the button-state seperately from the camera state, so it is more responsive
    const debouncedHandleCameraOn = debounce(handleCameraOn, 500);

    return (
        <div className={classnames(styles.root, className)}>
            {!hideChatIcon && !!setChatOpen && (
                <div className={styles.chat}>
                    <IconButton icon={ChatIcon} onClick={switchChatOpen} />
                    <div className={styles.notification}>
                        <ChatNotification />
                    </div>
                </div>
            )}
            {!hideCameraIcon &&
                (cameraOn ? (
                    <IconButton
                        icon={VideocamIcon}
                        onClick={debouncedHandleCameraOn}
                    />
                ) : (
                    <IconButton
                        icon={VideocamOffOutlinedIcon}
                        onClick={debouncedHandleCameraOn}
                    />
                ))}
            {!hideCameraSwitchIcon && multipleCameras() && (
                <IconButton
                    icon={CameraswitchIcon}
                    onClick={handleCameraSwitch}
                />
            )}
            {!hideMicrophoneIcon &&
                (microphoneOn ? (
                    <IconButton icon={MicIcon} onClick={handleMicrophoneOn} />
                ) : (
                    <IconButton
                        icon={MicOffOutlinedIcon}
                        onClick={handleMicrophoneOn}
                        color="error"
                    />
                ))}
            {!hideEndCallIcon && !!onEndCall && (
                <IconButton
                    icon={CloseIcon}
                    color="error"
                    onClick={handleEndCall}
                />
            )}
        </div>
    );
}

export default VideoUI;
