import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import { messages } from 'lib/i18n';
import loadable from '@loadable/component';
import client from 'lib/apollo/client';
import { BrowserRouter } from 'react-router-dom';

const IntlProvider = loadable(() => import('react-intl'), {
    resolveComponent: (components) => components.IntlProvider,
});

function App({ children }: React.PropsWithChildren<unknown>) {
    return (
        <IntlProvider messages={messages} locale="en" defaultLocale="en">
            <BrowserRouter>
                <ApolloProvider client={client}>{children}</ApolloProvider>
            </BrowserRouter>
        </IntlProvider>
    );
}

export default App;
