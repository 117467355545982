import * as React from 'react';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import styles from './DocumentPreview.module.scss';

interface DocumentPreviewProps {
    title: string;
    url?: string;
}

function DocumentPreview({ title, url }: DocumentPreviewProps) {
    const Content = (
        <>
            <DocumentIcon />
            <span>{title}</span>
        </>
    );

    if (url)
        return (
            <a
                className={styles['document-preview']}
                href={url}
                target="_blank"
                rel="noreferrer"
            >
                {Content}
            </a>
        );

    return <div className={styles['document-preview']}>{Content}</div>;
}

export default DocumentPreview;
