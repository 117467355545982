import * as React from 'react';
import classnames from 'classnames';
import { InputBaseProps } from '../InputBase';
import styles from './FormControl.module.scss';

export interface FormControlProps {
    classes?: Partial<typeof styles>;
    label?: React.ReactNode;
    id?: string | undefined;
    required?: boolean;
    value?: InputBaseProps['value'];
    error?: boolean;
    helperText?: React.ReactNode;
    errorMessage?: React.ReactNode;
}

function FormControl({
    classes,
    label,
    id,
    value,
    children,
    error,
    helperText,
    errorMessage,
}: React.PropsWithChildren<FormControlProps>): React.ReactElement {
    return (
        <div
            className={classnames(classes?.root, styles.root, {
                [classnames(classes?.error, styles.error)]: error,
                [classnames(classes?.hasContent, styles.hasContent)]:
                    !!value || value === 0,
            })}
        >
            <div className={styles.wrapper}>
                {label && (
                    <label
                        htmlFor={id}
                        className={classnames(classes?.label, styles.label)}
                    >
                        {label}
                    </label>
                )}
                {children}
            </div>
            {helperText && (
                <p className={styles['helper-text']}>{helperText}</p>
            )}
            {error && errorMessage && (
                <p className={styles['helper-text']}>{errorMessage}</p>
            )}
        </div>
    );
}
FormControl.defaultProps = {
    required: false,
};

export default FormControl;
