import * as React from 'react';
import { useIntl } from 'react-intl';
import TimerRising from 'core/components/TimerRising';
import {
    FirestoreTelehealthSession,
    SessionStatus,
} from '@bondvet/types/telehealth';
import Card from 'core/components/Card';
import useIsMobile from 'core/hooks/useIsMobile';
import TinySlider from 'tiny-slider-react';
import useWaitingCards from 'core/hooks/useWaitingCards';
import { WaitingCard } from 'lib/types';
import HeadlineWithSubtitle from '../../HeadlineWithSubtitle';
import PreviewImage from '../PreviewImage';
import styles from './Queue.module.scss';

const sliderSettings = {
    lazyload: true,
    nav: true,
    controls: false,
    mouseDrag: true,
    loop: true,
    items: 1,
};

interface CarouselProps {
    content: WaitingCard[];
}

function Carousel({ content }: CarouselProps) {
    return (
        <div className={styles.carousel}>
            <TinySlider settings={sliderSettings}>
                {content.map((card) => (
                    <div key={card.text}>
                        <Card
                            title={card.title}
                            text={card.text}
                            img={card.imageUrl}
                            classes={{ root: styles.card }}
                        />
                    </div>
                ))}
            </TinySlider>
        </div>
    );
}

interface QueueProps {
    telehealthSession: FirestoreTelehealthSession;
}

function Queue({ telehealthSession: session }: QueueProps) {
    const intl = useIntl();
    const isMobile = useIsMobile();
    const queuePosition: number | null =
        session.nurseQueuePosition || session.veterinarianQueuePosition;
    const { cards } = useWaitingCards();

    return (
        <div className={styles.root}>
            {isMobile ? <Carousel content={cards} /> : <PreviewImage />}
            <HeadlineWithSubtitle
                headline={
                    session.status === SessionStatus.nurseQueue
                        ? intl.formatMessage({
                              id: 'callPage.queue.connecting.nurse',
                          })
                        : intl.formatMessage({
                              id: 'callPage.queue.connecting.veterinarian',
                          })
                }
                subtitle={
                    queuePosition
                        ? `#${queuePosition} ${intl.formatMessage({
                              id: 'callPage.queue.queuePosition',
                          })}`
                        : ''
                }
            />

            <TimerRising />
        </div>
    );
}

export default Queue;
