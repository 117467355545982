import { Role } from '@bondvet/roles';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useCaseDocument from 'core/hooks/useCaseDocument';
import useProviderBio from 'core/hooks/useProviderBio';
import { FirestoreTelehealthSession } from '@bondvet/types/telehealth';
import Card from 'core/components/Card';
import useIsMobile from 'core/hooks/useIsMobile';
import useProviderInfo from 'core/hooks/useProviderInfo';
import useCallContext from '../../hooks/useCallContext/useCallContext';
import styles from './VisitSummaryEnd.module.scss';

const Bio = ({ id, name, role }: { id: string; name: string; role: Role }) => {
    const { imageUrl, bio } = useProviderBio({
        providerId: id,
    });

    return (
        <Card
            title={name}
            subtitle={role}
            text={bio}
            avatar={imageUrl}
            avatarAlt={`${role}-image`}
            classes={{
                root: styles.card,
                'title-container': styles['card-title-container'],
            }}
        />
    );
};

const ProviderBios = ({
    document,
}: {
    document: FirestoreTelehealthSession;
}) => {
    const intl = useIntl();

    const nurse = useProviderInfo({ id: document.nurseId });
    const nurseName = intl.formatMessage(
        {
            id: 'callPage.joining.nurse',
        },
        {
            providerName: `${nurse?.firstName}`,
        },
    );

    const veterinarian = useProviderInfo({ id: document.veterinarianId });
    const veterinarianName = intl.formatMessage(
        {
            id: 'callPage.joining.veterinarian',
        },
        {
            providerName: `${veterinarian?.lastName}`,
        },
    );

    return (
        <div className={styles.participants}>
            {document.nurseId && (
                <Bio id={document.nurseId} name={nurseName} role={Role.nurse} />
            )}
            {document.veterinarianId && (
                <Bio
                    id={document.veterinarianId}
                    name={veterinarianName}
                    role={Role.veterinarian}
                />
            )}
        </div>
    );
};

export default function VisitSummaryEnd() {
    const intl = useIntl();
    const {
        sessionIds: { sessionId },
    } = useCallContext();
    const document = useCaseDocument(sessionId);
    const isMobile = useIsMobile();

    return (
        <div className={styles.root}>
            <p className={styles.headline}>
                {intl.formatMessage({
                    id: 'visitSummaryEnd.thankYou',
                })}
            </p>
            {!isMobile && document && <ProviderBios document={document} />}
            <a href="https://bondvet.com/">
                {intl.formatMessage({
                    id: 'visitSummaryEnd.back',
                })}
            </a>
        </div>
    );
}
