import * as React from 'react';
import styles from './HeadlineWithSubtitle.module.scss';

export interface HeadlineWithSubtitleProps {
    headline: string;
    subtitle: string;
}

function HeadlineWithSubtitle({
    headline,
    subtitle,
}: HeadlineWithSubtitleProps): React.ReactElement {
    return (
        <div className={styles.root}>
            <strong>{headline}</strong>
            <p className={styles['queue-position']}>{subtitle}</p>
        </div>
    );
}

export default HeadlineWithSubtitle;
