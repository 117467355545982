import { gql, useQuery } from '@apollo/client';
import { GraphqlProvider } from '@bondvet/types/telehealth';

export const query = gql`
    query provider($id: String!) {
        provider(id: $id) {
            id
            firstName
            lastName
            role
            email
        }
    }
`;

type UseProviderProps = {
    id: string | null | undefined;
};

type ProviderQueryResults = {
    provider: GraphqlProvider;
};

export default function useProviderInfo({
    id,
}: UseProviderProps): GraphqlProvider | undefined {
    const result = useQuery<ProviderQueryResults>(query, {
        variables: { id },
        skip: !id,
    });
    return result.data?.provider;
}
