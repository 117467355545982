import * as React from 'react';
import classnames from 'classnames';
import { InputProps } from 'core/components/Input';
import FormControl, { FormControlProps } from 'core/components/FormControl';
import { Species } from '@bondvet/types/booking';
import { useIntl } from 'react-intl';
import { PetSpecies } from '../../lib/types';
import styles from './SpeciesSelect.module.scss';
import { ReactComponent as Cat } from './assets/cat.inline.svg';
import { ReactComponent as Dog } from './assets/dog.inline.svg';

export interface SpeciesSelectProps
    extends Omit<InputProps, 'onChange'>,
        Omit<FormControlProps, 'classes' | 'label'> {
    onChange: (species: PetSpecies) => void;
}

function SpeciesSelect({
    classes,
    value,
    id,
    required,
    error,
    helperText,
    onChange,
    ...props
}: SpeciesSelectProps): React.ReactElement {
    const intl = useIntl();
    const currentSpecies = value as Species;

    return (
        <FormControl
            id={id}
            value={value}
            required={required}
            error={error}
            classes={classes}
            helperText={helperText}
            {...props}
        >
            <div className={styles.cards}>
                <button
                    type="button"
                    data-cy="dog"
                    className={classnames(styles.card, styles.dog, {
                        [styles.selected]: currentSpecies === Species.canine,
                    })}
                    onClick={() => onChange(Species.canine)}
                >
                    <Dog />
                    {intl.formatMessage({
                        id: 'species.dog',
                    })}
                </button>
                <button
                    type="button"
                    className={classnames(styles.card, styles.cat, {
                        [styles.selected]: currentSpecies === Species.feline,
                    })}
                    onClick={() => onChange(Species.feline)}
                >
                    <Cat />
                    {intl.formatMessage({
                        id: 'species.cat',
                    })}
                </button>
            </div>
        </FormControl>
    );
}

export default SpeciesSelect;
