import { useApolloClient } from '@apollo/client';
import { GraphQLClientNames } from 'lib/types';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useClientContext from '../../../hooks/useClientContext/useClientContext';
import isTelehealthAvailable from './availability';
import {
    createPaymentIntentForEmailAddressMutation,
    initialCardState,
} from './lib';
import {
    CreatePaymentIntentForEmailAddressResult,
    CreditCardClientKnownControls,
} from './types';
import useNewCreditCard from './useNewCreditCard';

export default function useClientKnownCreditCardForm(): CreditCardClientKnownControls {
    const intl = useIntl();
    const apolloClient = useApolloClient();
    const clientContext = useClientContext();
    const { sourceId } = clientContext;
    const {
        isNewCreditCard,
        setIsNewCreditCard,
        setCardNumberRef,
        setInitialized,
        setCardExpiryRef,
        handleSubmit: handleSubmitNewCard,
        setCardCvcRef,
        cvcValid,
        cvcElement,
        cardDataValid,
        processing,
        processingError,
        setErrorState,
        setStateToError,
        email,
        stripe,
        enterVideoCall,
    } = useNewCreditCard(false);

    // sourceId is set when the user has a saved (default) credit card
    // and they want to use it to pay for the call

    // we want to use the sourceId if it is set
    // if the user has a sourceId, we can skip the payment form
    // and just use the sourceId to create the payment intent

    // if the user has no sourceId, we need to show the payment form
    // and ask them to enter their credit card information
    const [sourceIdToUse, setSourceIdToUse] = React.useState<string | null>(
        sourceId || null,
    );

    const handleSubmit = async (
        signIntoFirestore: (authToken: string) => Promise<void>,
        vetspireLocationId: string,
        displayPrecallOverlay: (show: boolean) => void,
        markAsDefault: boolean,
        promoCode?:
            | string
            | number
            | readonly string[]
            | File
            | readonly File[]
            | undefined,
    ) => {
        if (cvcValid && stripe && !processing && cvcElement) {
            // check if we closed since the customer started the form
            if (!(await isTelehealthAvailable())) {
                return;
            }

            setErrorState({
                ...initialCardState,
                processing: true,
            });

            //  collect the cvc and tokenize it
            const { token: cvcToken, error } = await stripe.createToken(
                'cvc_update',
                cvcElement,
            );

            if (error) {
                setStateToError(
                    error.message ||
                        intl.formatMessage({
                            id: 'errors.unknownError',
                        }),
                );
                return;
            }

            // create a paymentIntent with a cvc-check by passing the cvcTokenId
            // this also checks the cvc in stripe
            const { data: paymentIntentData, errors: paymentIntentErrors } =
                await apolloClient.mutate<CreatePaymentIntentForEmailAddressResult>(
                    {
                        mutation: createPaymentIntentForEmailAddressMutation,
                        variables: {
                            // TODO: add the client name here as well
                            // (currently we are creating Stripe customers without name)
                            // related ticket: https://bondvet.atlassian.net/browse/BV-502
                            email,
                            sourceId: sourceIdToUse,
                            vetspireLocationId,
                            cvcTokenId: cvcToken?.id,
                            markAsDefault,
                            promoCode,
                            isTelehealth: true,
                        },
                        context: {
                            clientName: GraphQLClientNames.creditCards,
                        },
                    },
                );

            if (paymentIntentData?.createPaymentIntentForEmailAddress.error) {
                setStateToError(
                    paymentIntentData.createPaymentIntentForEmailAddress.error,
                );
                return;
            }

            if (
                paymentIntentData?.createPaymentIntentForEmailAddress
                    .paymentIntent.nextAction?.type === 'use_stripe_sdk'
            ) {
                // handles whatever is needed for 'use_stripe_sdk' state,
                // for example triggers 3ds/2fa popup window
                const stripeResult = await stripe.confirmCardPayment(
                    paymentIntentData.createPaymentIntentForEmailAddress
                        .paymentIntent.clientSecret,
                );

                if (
                    stripeResult.error ||
                    stripeResult.paymentIntent.status !== 'requires_capture'
                ) {
                    setStateToError(
                        stripeResult.error?.message ||
                            intl.formatMessage({
                                id: 'errors.unknownError',
                            }),
                    );
                    return;
                }
            }

            if (!paymentIntentData || paymentIntentErrors) {
                setStateToError(
                    intl.formatMessage({
                        id: 'errors.technicalError',
                    }),
                );
                return;
            }

            try {
                displayPrecallOverlay(true);
                await enterVideoCall(
                    clientContext,
                    signIntoFirestore,
                    paymentIntentData.createPaymentIntentForEmailAddress
                        .paymentIntent.id,
                );
            } catch (err) {
                setStateToError(
                    intl.formatMessage({
                        id: 'errors.technicalError',
                    }),
                );
                displayPrecallOverlay(false);
            }
        }
    };

    return {
        cardNumberRef: setCardNumberRef,
        cardCvcRef: setCardCvcRef,
        cardExpiryRef: setCardExpiryRef,
        cardDataValid,
        processing,
        processingError,
        handleSubmit,
        handleSubmitNewCard,
        setInitialized,
        setSourceIdToUse,
        setIsNewCreditCard,
        isNewCreditCard,
    };
}
