import { ChatMessage, FormattedChatMessage } from '../../lib/types';

export function formatChatMessages(
    msgs: ChatMessage[],
    clientName: string,
    clientImage: string | null,
    providerName: string,
    providerImage: string | null,
): FormattedChatMessage[] {
    return msgs.map((msg) => ({
        attachments: msg.attachments,
        timestamp: msg.createdAt.toString(),
        sentAt: msg.createdAt.toDate().toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }),
        message: msg.message?.replaceAll('\\n', '\n') || '',
        sender: msg.sender,
        senderName: msg.providerId ? providerName : clientName,
        senderImage: msg.providerId ? providerImage : clientImage,
    }));
}
