import { FirebaseApp } from 'firebase/app';
import { User } from 'firebase/auth';
import * as React from 'react';

export type FirebaseContextData = {
    firebase: null | FirebaseApp;
    user: null | User;
    authLoading: boolean;
    isAdmin: boolean;
};

export type FirebaseContextUpdaters = {
    signIntoFirestore: (authToken: string) => Promise<void>;
    signInWithGooglePopup: () => Promise<void>;
    signOutOfFirebase: () => Promise<void>;
};

export type FirebaseContextType = FirebaseContextData & FirebaseContextUpdaters;
const FirebaseContext = React.createContext<FirebaseContextType>({
    firebase: null,
    user: null,
    authLoading: true,
    isAdmin: false,
    signIntoFirestore: async () => {},
    signInWithGooglePopup: async () => {},
    signOutOfFirebase: async () => {},
});
export default FirebaseContext;
