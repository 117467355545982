import * as React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';
import { Step } from '../../lib/types';

const isEnumValue = <T extends { [k: string]: string }>(
    something: string,
    enumObject: T,
): something is T[keyof T] =>
    typeof something === 'string' &&
    Object.values(enumObject).includes(something);

export default function useLogging(): void {
    const analytics = getAnalytics();
    const location = useLocation();
    const step = location.pathname.replace(/^\/([^/]*).*$/, '$1');

    function mapStep(stepToMap: Step): string {
        const map = {
            intake: 'start-intake',
            join: 'start-rejoin',
            reason: 'intake-reason',
            payment: 'intake-payment',
            precall: 'call-precall',
            call: 'call',
            summary: 'postcall-summary',
            end: 'postcall-end',
            disconnected: 'postcall-disconnected',
            debugSkipForm: 'debug',
        };

        return map[stepToMap];
    }

    React.useEffect(() => {
        if (step && isEnumValue(step, Step)) {
            // Google analytics
            logEvent(analytics, mapStep(step), {
                event_category: 'telehealth',
            });
            // Facebook
            if (typeof fbq === 'function') {
                fbq('trackCustom', mapStep(step));
            }
        }
    }, [analytics, step]);
}
