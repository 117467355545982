import client from 'lib/apollo/client';
import {
    availabilityQuery,
    AvailabilityResponse,
} from 'core/hooks/useAvailability';
import { GraphQLClientNames } from 'lib/types';

async function isTelehealthAvailable(): Promise<boolean> {
    const { data, errors } = await client.query<AvailabilityResponse>({
        query: availabilityQuery,
        context: {
            clientName: GraphQLClientNames.telehealth,
        },
        // it is important to use `network-only` here, instead of `no-cache`
        // because with `network-only`, apollo-client will query the data
        // freshly from the backend, AND it will update the cache.
        // and due to the cache being updated, `AvailabilityWrapper` will
        // switch to the proper screen, if necessary.
        fetchPolicy: 'network-only',
    });

    if (!data || errors) {
        throw new Error('Failed to receive availability-data');
    }
    const open = data.availability.open;
    const blockedOff = data.availability.blockedOffUntil;

    return open && !blockedOff;
}

export default isTelehealthAvailable;
