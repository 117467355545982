import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import useInitializeOnPageRefresh from '../../hooks/useInitializeOnPageRefresh';
import { Step } from '../../lib/types';
import CallPage from '../CallPage';
import DebugSkipForm from '../DebugSkipForm';
import Disconnected from '../Disconnected';
import PaymentPage from '../PaymentPage';
import ReasonForm from '../ReasonForm';
import Rejoin from '../Rejoin';
import Start from '../Start';
import VisitSummaryEnd from '../VisitSummaryEnd';
import VisitSummaryForm from '../VisitSummaryForm';
import useLogging from './useLogging';

const BasicRouteToStep = (Component: React.ElementType, step: Step) => (
    <Route path={`/${step}`}>
        <Component />
    </Route>
);

function Router() {
    const { debugSkipForm } = useClientContext();
    useInitializeOnPageRefresh();
    const location = useLocation();
    useLogging();

    React.useEffect(() => {
        const scrollContainer = document.querySelector('main');
        if (scrollContainer) {
            scrollContainer.scrollTo(0, 0);
        }
    }, [location.pathname]);

    return (
        <Switch>
            <Redirect
                to={{
                    pathname: debugSkipForm ? '/debugSkipForm' : '/intake',
                    search: location.search,
                }}
                path="/"
                exact
            />
            {BasicRouteToStep(Start, Step.intake)}
            {BasicRouteToStep(ReasonForm, Step.reason)}
            {BasicRouteToStep(PaymentPage, Step.payment)}
            {BasicRouteToStep(VisitSummaryForm, Step.summary)}
            {BasicRouteToStep(VisitSummaryEnd, Step.end)}
            {BasicRouteToStep(Disconnected, Step.disconnected)}

            <Route path={`/${Step.join}/:rejoinId`}>
                <Rejoin />
            </Route>

            <Route path={`/${Step.call}/:rejoinId`}>
                <CallPage />
            </Route>

            {debugSkipForm &&
                BasicRouteToStep(DebugSkipForm, Step.debugSkipForm)}
        </Switch>
    );
}

export default Router;
