import * as React from 'react';

function useHandleAttachments(
    addAttachments: (attachments: ReadonlyArray<File>) => void,
): ({ target }: React.ChangeEvent<HTMLInputElement>) => void {
    return React.useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            const newTarget = target; // Eslint-rule "no-param-reassign" workaround
            if (newTarget.files?.length) {
                addAttachments(Array.prototype.slice.call(newTarget.files, 0));
            } else {
                addAttachments([]);
            }

            // Without this: when adding, deleting and re-adding an item, it won't be re-added
            newTarget.value = '';
        },
        [addAttachments],
    );
}

export default useHandleAttachments;
