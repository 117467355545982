import {
    gql,
    LazyQueryResult,
    OperationVariables,
    QueryLazyOptions,
} from '@apollo/client';
import { Availability, OpeningHoursDays } from '@bondvet/types/telehealth';
import * as React from 'react';
import useLazyTelehealthQuery from './useLazyTelehealthQuery';

export const availabilityQuery = gql`
    fragment OpeningHoursFragment on OpeningHours {
        from
        to
    }

    query Query {
        availability {
            open
            timezone
            openingHours {
                monday {
                    ...OpeningHoursFragment
                }
                sunday {
                    ...OpeningHoursFragment
                }
                tuesday {
                    ...OpeningHoursFragment
                }
                wednesday {
                    ...OpeningHoursFragment
                }
                thursday {
                    ...OpeningHoursFragment
                }
                friday {
                    ...OpeningHoursFragment
                }
                saturday {
                    ...OpeningHoursFragment
                }
            }
            blockedOffUntil
            blockedOffUntilEndOfDay
        }
    }
`;

export type RunAvailabilityQuery = (
    options?: QueryLazyOptions<OperationVariables>,
) => Promise<LazyQueryResult<AvailabilityResponse, OperationVariables>>;

export type AvailabilityResponse = { availability: Availability };

const useAvailability = (): {
    openingHours: OpeningHoursDays | undefined;
    loading: boolean;
    blockedOffUntil: Date | null;
    blockedOffUntilEndOfDay: boolean | undefined;
    runQuery: RunAvailabilityQuery;
    open: boolean;
} => {
    const [runQuery, { data, loading }] =
        useLazyTelehealthQuery<AvailabilityResponse>(availabilityQuery, {
            // it is important to use a caching policy here (we could also do
            // `cache-and-network`), because
            // `client/components/PaymentPage/useCreditCardForm/availability`
            // will trigger the same query and thus update the cache for the
            // `AvailabilityWrapper` as well.
            fetchPolicy: 'cache-first',
        });

    React.useEffect(() => {
        runQuery();
    }, [runQuery]);

    return {
        open: !!data?.availability.open,
        loading,
        openingHours: data?.availability.openingHours,
        blockedOffUntil: data?.availability.blockedOffUntil
            ? new Date(data.availability.blockedOffUntil)
            : null,
        blockedOffUntilEndOfDay: data?.availability.blockedOffUntilEndOfDay,
        runQuery,
    };
};

export default useAvailability;
