import { ClientFirestoreSessionIds } from '@bondvet/types/telehealth';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import * as React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import {
    readSessiondataFromLocalStorage,
    setSessiondataInLocalStorage,
} from 'core/lib/storage';
import { useIntl } from 'react-intl';
import { GraphQLClientNames } from 'lib/types';
import { VideoContextType } from 'core/hooks/video/context';
import {
    CreateTelehealthSessionResult,
    EndCallResult,
} from '../../components/PaymentPage/useCreditCardForm/types';
// eslint-disable-next-line max-len
import { createTelehealthInputFromClientContext } from '../../components/PaymentPage/useCreditCardForm/lib';
import {
    CallContextData,
    CallContextType,
    ClientContextType,
    Step,
} from '../../lib/types';
import useSetter from './useSetter';

const createTelehealthSessionMutation = gql`
    mutation CreateTelehealthSession(
        $input: TelehealthSessionInput!
        $paymentIntentId: String
    ) {
        createTelehealthSession(
            input: $input
            paymentIntentId: $paymentIntentId
        ) {
            error
            sessionIds {
                clientId
                sessionId
                authToken
                twilioAuthToken
            }
            rejoinId
        }
    }
`;

const endCallMutation = gql`
    mutation EndCall {
        endCall {
            success
            error
        }
    }
`;

export const initialCallContextState = {
    chatOpen: false,
    bookingOpen: false,
    sessionIds: {
        clientId: '',
        sessionId: '',
        twilioAuthToken: '',
        authToken: '',
    },
};

function trackGAConversion() {
    try {
        if (process.env.REACT_APP_GA_CONVERSION_EVENT) {
            gtag('event', 'conversion', {
                send_to: process.env.REACT_APP_GA_CONVERSION_EVENT,
            });
        }

        if (typeof fbq === 'function') {
            fbq('trackCustom', 'telehealth_submission');
        }
    } catch (error) {
        console.warn('error tracking conversion', error);
    }
}

interface AdRoll {
    track: (eventName: string, options?: { [key: string]: unknown }) => void;
}

declare global {
    interface Window {
        adroll: AdRoll;
    }
}

function trackYelpConversion() {
    try {
        if (process.env.REACT_APP_ADROLL_BOOK_TELEHEALTH_SEGMENT) {
            if (window.adroll) {
                window.adroll.track('pageView', {
                    segment_name:
                        process.env.REACT_APP_ADROLL_BOOK_TELEHEALTH_SEGMENT,
                });
            }
        }
    } catch (e) {
        console.error(e);
    }
}

export default function useCallContextHandler(): CallContextType {
    const apolloClient = useApolloClient();
    const intl = useIntl();

    const [{ chatOpen, bookingOpen, sessionIds }, setValue] =
        React.useState<CallContextData>(initialCallContextState);

    const setChatOpen = useSetter<boolean>('chatOpen', setValue);
    const setBookingOpen = useSetter<boolean>('bookingOpen', setValue);
    const setSessionIds = useSetter<ClientFirestoreSessionIds>(
        'sessionIds',
        setValue,
    );

    React.useEffect(() => {
        const sessionData = readSessiondataFromLocalStorage();
        if (!sessionData) return;
        setSessionIds(sessionData);
    }, [setSessionIds]);

    async function enterVideoCall(
        clientContext: ClientContextType,
        signIntoFirestore: (authToken: string) => Promise<void>,
        paymentIntentId: string | null,
    ) {
        trackGAConversion();
        trackYelpConversion();

        const { data: telehealthData } =
            await apolloClient.mutate<CreateTelehealthSessionResult>({
                mutation: createTelehealthSessionMutation,
                variables: {
                    input: createTelehealthInputFromClientContext(
                        clientContext,
                    ),
                    paymentIntentId,
                },
                context: {
                    clientName: GraphQLClientNames.telehealth,
                },
            });

        if (
            clientContext.origin === 'clientApp' &&
            telehealthData?.createTelehealthSession?.sessionIds?.clientId
        ) {
            const analytics = getAnalytics();

            setUserId(
                analytics,
                telehealthData.createTelehealthSession.sessionIds.clientId,
            );
            logEvent(analytics, 'clientApp_telehealth_queue_end', {
                email: clientContext.email,
                clientId:
                    telehealthData.createTelehealthSession.sessionIds.clientId,
            });
        }
        if (
            telehealthData?.createTelehealthSession.error ||
            !telehealthData?.createTelehealthSession.sessionIds
        ) {
            throw new Error(telehealthData?.createTelehealthSession.error);
        }

        setSessionIds(telehealthData.createTelehealthSession.sessionIds);

        setSessiondataInLocalStorage(
            telehealthData.createTelehealthSession.sessionIds,
        );

        await signIntoFirestore(
            telehealthData.createTelehealthSession.sessionIds.authToken,
        );

        clientContext.setStep(
            Step.call,
            telehealthData?.createTelehealthSession?.rejoinId || '',
        );
    }

    const endCall = async (
        clientContext: ClientContextType,
        videoContext: VideoContextType,
    ) => {
        clientContext.setStep(Step.summary);
        videoContext.endVideoCall();

        const { data: operationResult, errors: endCallErrors } =
            await apolloClient.mutate<EndCallResult>({
                mutation: endCallMutation,
                context: {
                    clientName: GraphQLClientNames.telehealth,
                },
            });

        if (
            endCallErrors ||
            !operationResult ||
            !operationResult.endCall.success
        ) {
            console.warn(
                intl.formatMessage({
                    id: 'errors.technicalError',
                }),
            );
        }
    };

    return {
        chatOpen,
        bookingOpen,
        sessionIds,
        setChatOpen,
        setBookingOpen,
        setSessionIds,
        enterVideoCall,
        endCall,
    };
}
