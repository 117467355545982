import { SessionStatus } from '@bondvet/types/telehealth';
import * as React from 'react';
import VideoUI from './VideoUI';
import VideoStreams from './VideoStreams';
import styles from './video.module.scss';

interface VideoProps {
    twilioAuthToken: string;
    roomId: string;
    sessionStatus: SessionStatus;
    hideChatIcon?: boolean;
    chatOpen?: boolean;
    mirrorVideoOpen?: boolean;
    onEndCall?: () => void;
    setChatOpen?: (chatOpen: boolean) => void;
    onAuthExpired?: () => void;
    onRoomExpired?: () => void;
    onDisconnected?: () => void;
    largeMirrorVideo?: boolean;
}

const Video = ({
    twilioAuthToken,
    roomId,
    sessionStatus,
    hideChatIcon = true,
    chatOpen = false,
    onEndCall,
    setChatOpen = () => {},
    onAuthExpired,
    mirrorVideoOpen,
    onDisconnected,
    onRoomExpired,
    largeMirrorVideo,
}: VideoProps) => {
    return (
        <div className={styles.root}>
            <VideoUI
                hideChatIcon={hideChatIcon}
                chatOpen={chatOpen}
                setChatOpen={setChatOpen}
                onEndCall={onEndCall}
            />
            {twilioAuthToken && (
                <VideoStreams
                    twilioAuthToken={twilioAuthToken}
                    roomId={roomId}
                    sessionStatus={sessionStatus}
                    onAuthExpired={onAuthExpired}
                    mirrorVideoOpen={mirrorVideoOpen}
                    chatOpen={chatOpen}
                    onDisconnected={onDisconnected}
                    onRoomExpired={onRoomExpired}
                    largeMirrorVideo={largeMirrorVideo}
                />
            )}
        </div>
    );
};

export default Video;
