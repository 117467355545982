import { asyncRetry } from 'core/lib/asyncRetry';
import {
    collection,
    doc,
    getDocs,
    getFirestore,
    query,
    orderBy,
    setDoc,
    deleteDoc,
} from 'firebase/firestore';
import {
    deleteObject,
    getDownloadURL,
    getStorage,
    listAll,
    ref,
    uploadBytes,
} from 'firebase/storage';
import { WaitingCard } from 'lib/types';
import * as React from 'react';

const STORAGE_PATH = 'telehealth/waitingcards';

type UseWaitingCards = {
    cards: Array<WaitingCard>;
    setCards: React.Dispatch<React.SetStateAction<WaitingCard[]>>;
    deleteCard: (id: string) => void;
    commitData: () => Promise<void[]>;
};

export default function useWaitingCards(): UseWaitingCards {
    const [cards, setCards] = React.useState<Array<WaitingCard>>([]);
    const [deadCards, setDeadCards] = React.useState<Array<string>>([]);

    const db = getFirestore();
    const storage = getStorage();
    const collectionRef = collection(db, 'telehealth.waitingCards');

    async function commitCard({
        image,
        id,
        imageUrl,
        thumbUrl,
        ...rest
    }: WaitingCard) {
        const imagePath = `${STORAGE_PATH}/${id}/${image?.name}`;
        const thumbnailPath = `${STORAGE_PATH}/${id}/thumb_${image?.name}`;
        const storageRef = ref(storage, imagePath);
        const storageThumbnailRef = ref(storage, thumbnailPath);
        let url = imageUrl || '';
        let thumbnailUrl = thumbUrl || '';

        if (image) {
            await uploadBytes(storageRef, image);
            url = await getDownloadURL(storageRef);

            thumbnailUrl = await asyncRetry(
                () => getDownloadURL(storageThumbnailRef),
                100,
            );
        }

        return setDoc(
            doc(collectionRef, id),
            { ...rest, imageUrl: url, thumbUrl: thumbnailUrl },
            { merge: true },
        );
    }

    async function deleteImages(id: string) {
        const path = `${STORAGE_PATH}/${id}/`;
        const files = await listAll(ref(storage, path));

        files.items.forEach((item) => {
            deleteObject(item);
        });
    }

    async function commitData() {
        const promises: Array<Promise<void>> = [];
        cards.forEach((card) => promises.push(commitCard(card)));

        if (deadCards.length > 0) {
            deadCards.forEach((deadId) => {
                promises.push(deleteDoc(doc(collectionRef, deadId)));
                deleteImages(deadId);
            });
            setDeadCards([]);
        }

        return Promise.all(promises);
    }

    async function deleteCard(id: string) {
        const cardToDelete = cards.find((card) => card.id === id);
        setDeadCards((prev) => [...prev, id]);

        if (cardToDelete) {
            const position = cardToDelete.position;
            setCards((prev) =>
                prev
                    .filter((card) => card.id !== id)
                    .map((card) => {
                        if (card.position <= position) return card;
                        return { ...card, position: card.position - 1 };
                    }),
            );
        }
    }

    React.useEffect(() => {
        async function fetchCards() {
            const results: Array<WaitingCard> = [];

            const q = query(collectionRef, orderBy('position'));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((document) => {
                results.push({
                    ...document.data(),
                    id: document.id,
                } as WaitingCard);
            });
            if (results.length > 0) setCards(results);
        }

        if (cards.length === 0 && deadCards.length === 0) fetchCards();
    }, [collectionRef, cards.length, deadCards.length]);

    return { cards, setCards, commitData, deleteCard };
}
