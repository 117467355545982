import { GraphqlProvider, SessionStatus } from '@bondvet/types/telehealth';
import classNames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useProviderBio from 'core/hooks/useProviderBio';
import Card from 'core/components/Card';
import useIsMobile from 'core/hooks/useIsMobile';
import HeadlineWithSubtitle from '../../HeadlineWithSubtitle';
import PreviewImage from '../PreviewImage';
import styles from './Joining.module.scss';

interface JoiningProps {
    sessionStatus: SessionStatus;
    provider: GraphqlProvider;
}

function Joining({ sessionStatus, provider }: JoiningProps) {
    const intl = useIntl();
    const isMobile = useIsMobile();
    const { imageUrl, bio } = useProviderBio({
        providerId: provider.id,
    });

    function getHeadline(): string {
        if (provider.firstName) {
            if (sessionStatus === SessionStatus.nurseJoining)
                return intl.formatMessage(
                    {
                        id: 'callPage.joining.nurse',
                    },
                    {
                        providerName: `${provider?.lastName}`,
                    },
                );

            if (sessionStatus === SessionStatus.veterinarianJoining)
                return intl.formatMessage(
                    {
                        id: 'callPage.joining.veterinarian',
                    },
                    {
                        providerName: `${provider?.lastName}`,
                    },
                );
        } else {
            if (sessionStatus === SessionStatus.nurseJoining)
                return intl.formatMessage({
                    id: 'callPage.joining.genericNurse',
                });

            if (sessionStatus === SessionStatus.veterinarianJoining)
                return intl.formatMessage({
                    id: 'callPage.joining.genericDoctor',
                });
        }

        return '';
    }

    return (
        <div className={styles.root}>
            <div
                className={classNames(
                    styles.carousel,
                    styles['image-and-card-container'],
                )}
            >
                <PreviewImage img={imageUrl} />
                {isMobile && (
                    <Card
                        title={`${intl.formatMessage({
                            id: 'callPage.joining.about',
                        })} ${getHeadline()}`}
                        text={bio}
                        classes={{ root: styles.card }}
                    />
                )}
            </div>

            <HeadlineWithSubtitle
                headline={getHeadline()}
                subtitle={intl.formatMessage({
                    id: 'callPage.joining.isJoining',
                })}
            />
        </div>
    );
}

export default Joining;
