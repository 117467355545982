import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import * as React from 'react';
import styles from './Loading.module.scss';

interface LoadingProps {
    classes?: Partial<typeof styles>;
}

function Loading({ classes }: LoadingProps) {
    return (
        <div className={classNames(classes?.root, styles.loading)}>
            <CircularProgress />
        </div>
    );
}

export default Loading;
