import { CreditCardClientUnknownControls } from './types';
import useNewCreditCard from './useNewCreditCard';

export default function useClientUnknownCreditCardForm(): CreditCardClientUnknownControls {
    const {
        handleSubmit,
        setCardNumberRef,
        setCardExpiryRef,
        setCardCvcRef,
        valid,
        processing,
        processingError,
        setIsNewCreditCard,
        email,
    } = useNewCreditCard(true);

    return {
        cardNumberRef: setCardNumberRef,
        cardCvcRef: setCardCvcRef,
        cardExpiryRef: setCardExpiryRef,
        cardDataValid: valid,
        processing,
        processingError,
        handleSubmit,
        setIsNewCreditCard,
        email,
    };
}
