import classNames from 'classnames';
import * as React from 'react';
import Preview from './Preview';
import styles from './Preview.module.scss';

interface PreviewListProps {
    files: ReadonlyArray<File>;
    onDelete: (file: File) => void;
    wrapperClasses?: Partial<typeof styles>;
}

function PreviewList({ files, onDelete, wrapperClasses }: PreviewListProps) {
    return (
        <div
            className={classNames(
                styles['preview-wrapper'],
                wrapperClasses?.root,
            )}
        >
            {files.map((file, idx) => (
                <Preview
                    file={file}
                    key={file.name + idx.toString()}
                    onDelete={() => {
                        if (onDelete) onDelete(file);
                    }}
                />
            ))}
        </div>
    );
}

export default PreviewList;
