import * as React from 'react';
import styles from './PillCheckBox.module.scss';

type PillCheckBoxProps = React.InputHTMLAttributes<HTMLInputElement> & {
    id: string;
    bgColor: string;
    value: string;
    onChange: React.ChangeEventHandler;
    checked: boolean;
};

function PillCheckBox({
    id,
    children,
    bgColor,
    value,
    checked,
    onChange,
    ...otherProps
}: React.PropsWithChildren<PillCheckBoxProps>) {
    return (
        <div className={styles.root}>
            <input
                checked={checked}
                onChange={onChange}
                type="checkbox"
                id={id}
                value={value}
                {...otherProps}
            />
            <label htmlFor={id} style={{ backgroundColor: bgColor }}>
                {children}
            </label>
        </div>
    );
}

export default PillCheckBox;
