import * as React from 'react';
import { useIntl } from 'react-intl';
import Loading from 'core/components/Loading';
import HeadlineWithSubtitle from '../../HeadlineWithSubtitle';
import styles from '../PaymentPage.module.scss';

function PreCallOverlay() {
    const intl = useIntl();

    return (
        <div className={styles.precall} data-cy="preCallPage">
            <HeadlineWithSubtitle
                headline={intl.formatMessage({ id: 'preCallPage.setup' })}
                subtitle={intl.formatMessage({ id: 'preCallPage.connecting' })}
            />
            <Loading />
        </div>
    );
}

export default PreCallOverlay;
