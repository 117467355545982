/**
 * Helper function to compare two objects to assist in sorting object arrays
 * @param objA First object to compare
 * @param objB Second object to compare
 * @param property Common property on objects to compare on
 * @param direction Sort direction, 'ASC' or 'DESC' only
 * @returns Comparison result as integer
 */
export default function compareObjects(
    objA: Record<string, unknown>,
    objB: Record<string, unknown>,
    property: string,
    direction: 'ASC' | 'DESC' = 'ASC',
): number {
    const propertyA = objA[property];
    const propertyB = objB[property];
    if (
        (typeof propertyA === 'string' && typeof propertyB === 'string') ||
        (typeof propertyA === 'number' && typeof propertyB === 'number')
    ) {
        const dir = direction === 'ASC' ? 1 : -1;

        if (propertyA < propertyB) {
            return -1 * dir;
        }
        if (propertyA > propertyB) {
            return 1 * dir;
        }
    }
    return 0;
}
