import * as React from 'react';
import { VideoContextData } from './context';

export default function useSetter<T = string>(
    key: keyof VideoContextData,
    setValue: React.Dispatch<React.SetStateAction<VideoContextData>>,
): (newValue: T) => void {
    return React.useCallback(
        (newValue: T) => {
            setValue((prev) => ({
                ...prev,
                [key]: newValue,
            }));
        },
        [key, setValue],
    );
}
