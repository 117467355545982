import * as React from 'react';
import Loading from 'core/components/Loading';
import useAvailability from 'core/hooks/useAvailability';
import { OpeningHoursDays } from '@bondvet/types/telehealth';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { DayOfTheWeek } from '@bondvet/types/common';
import styles from './AvailabilityWrapper.module.scss';
import BlockedOffText from './BlockedOffText';

const weekdaysInOrder: ReadonlyArray<DayOfTheWeek> = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];

const formatToAmPm = (time: string) => {
    let hours = Number(time.split(':')[0]);
    const minutes = time.split(':')[1];
    let suffix = 'AM';
    if (hours >= 12) suffix = 'PM';
    if (hours >= 13) hours %= 12;

    return `${hours}:${minutes} ${suffix}`;
};

interface OpeningHoursProps {
    openingHours: OpeningHoursDays;
}

function OpeningHoursTable({ openingHours }: OpeningHoursProps) {
    const intl = useIntl();
    return (
        <>
            <p>
                {intl.formatMessage({
                    id: 'availability.openingHours',
                })}
            </p>
            <table className={styles['opening-hours']}>
                <tbody>
                    {weekdaysInOrder.map((weekday) => {
                        return openingHours[weekday].map((hoursOfDay) => (
                            <tr key={weekday}>
                                <td className={styles['table-weekday']}>
                                    {weekday}
                                </td>
                                <td className={styles['table-hours']}>
                                    {formatToAmPm(hoursOfDay.from)}
                                    {intl.formatMessage({ id: 'app.hyphen' })}
                                    {formatToAmPm(hoursOfDay.to)}
                                </td>
                            </tr>
                        ));
                    })}
                </tbody>
            </table>
        </>
    );
}

function OpeningHours({ openingHours }: OpeningHoursProps) {
    const intl = useIntl();
    return (
        <>
            <h2>
                {intl.formatMessage({
                    id: 'availability.closed',
                })}
            </h2>
            <OpeningHoursTable openingHours={openingHours} />
            <br />
            <a href="https://bondvet.com/">
                {intl.formatMessage({
                    id: 'visitSummaryEnd.back',
                })}
            </a>
        </>
    );
}

function BlockedOffUntilEndOfDay({ openingHours }: OpeningHoursProps) {
    const intl = useIntl();
    return (
        <>
            <h3 className={styles['blocked-off-until-end-of-day-title']}>
                {intl.formatMessage({
                    id: 'availability.blockOffUntilEndOfDay',
                })}
            </h3>
            <p className={styles['blocked-off-until-end-of-day-subtitle']}>
                {intl.formatMessage({
                    id: 'availability.hotlineHintStart',
                })}
                <b>
                    {intl.formatMessage({
                        id: 'availability.hotlineHintNumber',
                    })}
                </b>
                {intl.formatMessage({
                    id: 'availability.hotlineHintEnd',
                })}
            </p>
            <OpeningHoursTable openingHours={openingHours} />
            <br />
            <a href="https://bondvet.com/">
                {intl.formatMessage({
                    id: 'visitSummaryEnd.back',
                })}
            </a>
        </>
    );
}

function AvailabilityWrapper({ children }: React.PropsWithChildren<unknown>) {
    const {
        open,
        loading,
        openingHours,
        blockedOffUntil,
        blockedOffUntilEndOfDay,
    } = useAvailability();
    const location = useLocation();
    const testingParameter = location.search.includes('testing');
    const [testing, setTesting] = React.useState(false);

    if (!testing && testingParameter) setTesting(true);
    const openOrTesting = (open && !blockedOffUntil) || testing;

    const isBlockedOff = !!blockedOffUntil || blockedOffUntilEndOfDay;

    return (
        <>
            {loading && <Loading classes={{ root: styles.centered }} />}
            {!loading && openOrTesting && children}
            {!loading && (
                <div className={styles['content-main']}>
                    {!openOrTesting && openingHours && !isBlockedOff && (
                        <OpeningHours openingHours={openingHours} />
                    )}
                    {blockedOffUntil && !blockedOffUntilEndOfDay && (
                        <BlockedOffText blockedOffUntil={blockedOffUntil} />
                    )}
                    {blockedOffUntil &&
                        blockedOffUntilEndOfDay &&
                        openingHours && (
                            <BlockedOffUntilEndOfDay
                                openingHours={openingHours}
                            />
                        )}
                </div>
            )}
        </>
    );
}

export default AvailabilityWrapper;
