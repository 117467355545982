import * as React from 'react';
import classNames from 'classnames';
import useFirebaseContext from 'core/hooks/firebase/useFirebaseContext';
import { useIntl } from 'react-intl';
import styles from 'client/components/PaymentPage/PaymentPage.module.scss';
import { FormFields } from './FormFieldsTypes';
import useVetspireLocationId from '../../hooks/useVetspireLocationId';
import useCoveredByPlan from './useCreditCardForm/useCoveredByPlan';
import NextButton from '../NextButton';

export default function CoveredByPlan({ displayPrecallOverlay }: FormFields) {
    const intl = useIntl();
    const { signIntoFirestore } = useFirebaseContext();

    const { loading: vetspireIdLoading } = useVetspireLocationId();

    const { processingError, processing, handleSubmit } = useCoveredByPlan();

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        handleSubmit(signIntoFirestore, displayPrecallOverlay).then();
    };

    return (
        <form
            className={classNames(styles.form, styles.noPayment)}
            onSubmit={onSubmit}
        >
            <div className={styles['grid-container']}>
                {processingError && (
                    <p className={styles['error-text']}>{processingError}</p>
                )}
            </div>
            <NextButton
                label={intl.formatMessage({
                    id: processing
                        ? 'paymentPage.nextButton.loading'
                        : 'paymentPage.nextButton.noPaymentLabel',
                })}
                type="submit"
                disabled={processing || vetspireIdLoading}
                loading={processing || vetspireIdLoading}
                variant="fixed"
                className={classNames(styles['next-button'], styles.noPayment)}
            />
        </form>
    );
}
