import { asyncRetry } from 'core/lib/asyncRetry';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import * as React from 'react';

type UseProviderBioProps = {
    providerId: string;
};

type UseProviderBio = {
    bio: string;
    setBio: React.Dispatch<React.SetStateAction<string>>;
    imageUrl: string;
    thumbnailUrl: string;
    setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
    commitData: () => Promise<void>;
};

export default function useProviderBio({
    providerId,
}: UseProviderBioProps): UseProviderBio {
    const db = getFirestore();
    const storage = getStorage();
    const providerRef = doc(db, 'telehealth.providerBios', providerId);
    const [bio, setBio] = React.useState('');
    const [image, setImage] = React.useState<File>();
    const [imageUrl, setImageUrl] = React.useState('');
    const [thumbnailUrl, setThumbnailUrl] = React.useState('');

    async function commitData() {
        const imagePath = `telehealth/providers/${providerId}/${image?.name}`;
        const thumbnailPath = `telehealth/providers/${providerId}/thumb_${image?.name}`;
        const storageRef = ref(storage, imagePath);
        const storageThumbnailRef = ref(storage, thumbnailPath);
        let url = imageUrl || '';
        let thumbUrl = thumbnailUrl || '';

        if (image) {
            await uploadBytes(storageRef, image);
            url = await getDownloadURL(storageRef);
            setImageUrl(url);

            thumbUrl = await asyncRetry(
                () => getDownloadURL(storageThumbnailRef),
                100,
            );
        }

        return setDoc(
            providerRef,
            { bio, imageUrl: url, thumbnailUrl: thumbUrl },
            { merge: true },
        );
    }

    React.useEffect(() => {
        async function fetchBio() {
            const snapshot = await getDoc(providerRef);
            if (snapshot.exists()) {
                if (!bio) {
                    setBio(snapshot.data().bio || '');
                }
                if (!imageUrl) {
                    setImageUrl(snapshot.data().imageUrl || '');
                }
                if (!thumbnailUrl) {
                    setThumbnailUrl(snapshot.data().thumbnailUrl || '');
                }
            }
        }

        fetchBio();
    }, [providerRef, bio, imageUrl, thumbnailUrl]);

    return { bio, setBio, imageUrl, setImage, commitData, thumbnailUrl };
}
