import * as React from 'react';
import classnames from 'classnames';
import TextField, { TextFieldProps } from '../TextField';
import styles from './FileInput.module.scss';
import Input from './Input';
import { FileInputProps as BaseProps } from './types';
import PreviewList from './preview/PreviewList';

export interface FileInputProps
    extends Omit<TextFieldProps, 'inputComponent'>,
        BaseProps {
    classes?: Partial<typeof styles>;
    accept?: string;
    onRemove?: (file: File) => void;
}

export default function FileInput({
    classes,
    inputProps,
    id,
    accept,
    value,
    onRemove,
    ...props
}: FileInputProps): React.ReactElement {
    const files = value as ReadonlyArray<File>;

    const handleDelete = React.useCallback(
        (file: File) => {
            if (onRemove) {
                onRemove(file);
            }
        },
        [onRemove],
    );
    return (
        <>
            <TextField
                {...props}
                id={id}
                inputComponent={Input}
                classes={{ ...classes }}
                inputClasses={{
                    root: classnames(classes?.root, styles.root),
                }}
                inputProps={{
                    ...inputProps,
                    accept,
                }}
            />
            <PreviewList
                files={files}
                onDelete={handleDelete}
                wrapperClasses={{
                    root: classnames(styles['preview-wrapper']),
                }}
            />
        </>
    );
}
