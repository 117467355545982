import { gql, useMutation } from '@apollo/client';
import { OperationResult } from '@bondvet/types';
import useCaseDocument from 'core/hooks/useCaseDocument';
import useClientsPatientsData from 'team/hooks/useClientsPatientsData';
import { GraphQLClientNames } from 'lib/types';
import { GraphQLError } from 'graphql';
import { BookingAppointment } from '@bondvet/types/telehealth';
import useBookingUtils from './useBookingUtils';

const createAppointmentMutation = gql`
    mutation createAppointment($input: AppointmentInput!) {
        createAppointment(input: $input, origin: telehealth) {
            success
            error
            appointment {
                _id
            }
        }
    }
`;

type CreateAppointmentResult = {
    createAppointment: OperationResult & { appointment: { _id: string } };
};

type UseCreateAppointmentType = {
    createAppointment: ({
        appointment,
        additionalNotes,
    }: CreateAppointmentProps) => Promise<{
        appointmentId: string | undefined;
        error: readonly GraphQLError[] | undefined;
    }>;
};

type CreateAppointmentProps = {
    appointment: BookingAppointment;
    additionalNotes: string | undefined;
};

export default function useCreateAppointment(): UseCreateAppointmentType {
    const { sessionId } = useBookingUtils();
    const document = useCaseDocument(sessionId);

    const { email, species, firstName, lastName, patientName } =
        useClientsPatientsData({
            patientsIds: [document?.patientId],
        });

    const appointmentInput = {
        firstName,
        lastName,
        pet: patientName,
        animalId: document?.patientId,
        sendReminder: true,
        sendSurvey: false,
        species,
        symptoms: document?.appointmentTypeId,
        phone: document?.phone,
        email,
    };

    const [commitAppointment] = useMutation<CreateAppointmentResult>(
        createAppointmentMutation,
    );

    type FormatNotesProps = {
        notes: string | undefined;
        additionalNotes: string | undefined;
    };

    function formatNotes({ notes, additionalNotes }: FormatNotesProps): string {
        let result = '';
        if (notes) {
            result += `Client notes: ${notes}`;
        }
        if (additionalNotes) {
            if (result !== '') result += '\n';
            result += `Intake notes: ${additionalNotes}`;
        }
        return result;
    }

    async function createAppointment({
        appointment,
        additionalNotes,
    }: CreateAppointmentProps) {
        try {
            const result = await commitAppointment({
                variables: {
                    input: {
                        ...appointmentInput,
                        time: appointment.dateTime,
                        locationId: appointment.location?._id,
                        notes: formatNotes({
                            notes: document?.notes,
                            additionalNotes,
                        }),
                    },
                },
                context: {
                    clientName: GraphQLClientNames.bondvet,
                },
            });
            return {
                appointmentId: result.data?.createAppointment.appointment._id,
                error: result.errors,
            };
        } catch (error) {
            return {
                appointmentId: undefined,
                error: [new GraphQLError(`${error}`)],
            };
        }
    }

    return { createAppointment };
}
