import * as React from 'react';
import { useIntl } from 'react-intl';
import useClientContext from 'client/hooks/useClientContext/useClientContext';
import useCallContext from 'client/hooks/useCallContext/useCallContext';
import isTelehealthAvailable from './availability';
import { initialCardState } from './lib';
import { CoveredByPlanControls } from './types';

export default function useCoveredByPlan(): CoveredByPlanControls {
    const intl = useIntl();
    const clientContext = useClientContext();
    const { enterVideoCall } = useCallContext();

    const [{ processing, processingError }, setErrorState] = React.useState({
        ...initialCardState,
    });

    const setStateToError = (message: string) =>
        setErrorState({
            processing: false,
            processingError: message,
        });

    const handleSubmit = async (
        signIntoFirestore: (authToken: string) => Promise<void>,
        displayPrecallOverlay: (show: boolean) => void,
    ) => {
        // check if we closed since the customer started the form
        if (!(await isTelehealthAvailable())) {
            return;
        }

        try {
            displayPrecallOverlay(true);
            await enterVideoCall(clientContext, signIntoFirestore, null);
        } catch (err) {
            setStateToError(
                intl.formatMessage({
                    id: 'errors.technicalError',
                }),
            );
            displayPrecallOverlay(false);
        }
    };
    return {
        processing,
        processingError,
        setErrorState,
        enterVideoCall,
        handleSubmit,
    };
}
