import * as React from 'react';
import classNames from 'classnames';
import styles from './InputIconButton.module.scss';

export interface InputIconButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: React.ElementType;
    label: React.ReactNode;
    classes?: Partial<typeof styles>;
}

export default function InputIconButton({
    icon: Icon,
    label,
    type = 'button',
    className,
    classes,
    ...props
}: InputIconButtonProps): React.ReactElement {
    /* eslint-disable react/button-has-type */
    return (
        <button
            {...props}
            type={type}
            className={classNames(className, classes?.root, styles.root)}
        >
            <Icon />
            <span>{label}</span>
        </button>
    );
}
