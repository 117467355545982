import classNames from 'classnames';
import * as React from 'react';
import styles from './Card.module.scss';

export interface CardProps {
    title: string;
    subtitle?: string;
    text: string;
    img?: string;
    imgAlt?: string;
    avatar?: string;
    avatarAlt?: string;
    classes?: typeof styles;
}

export default function Card({
    title,
    subtitle = '',
    text,
    img = '',
    imgAlt = '',
    avatar = '',
    avatarAlt = '',
    classes,
}: CardProps): React.ReactElement {
    return (
        <article className={classNames(classes?.root, styles.root)}>
            {img !== '' && (
                <img src={img} alt={imgAlt} className={styles.img} />
            )}
            <div
                className={classNames(
                    styles['title-container'],
                    classes?.['title-container'],
                )}
            >
                {avatar && <img src={avatar} alt={avatarAlt} />}
                <div>
                    <p className={classNames(styles.headline)}>{title}</p>
                    {subtitle && (
                        <p className={styles.subtitle}>
                            {subtitle.toUpperCase()}
                        </p>
                    )}
                </div>
            </div>
            <p className={styles.description}>{text}</p>
        </article>
    );
}
