import * as React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useBookingContext } from 'team/hooks/useBookingContext';
import useNextAvailableTimeSlots from 'core/hooks/useNextAvailableTimeSlots';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { apppointmentsEqualAndDefined } from 'core/lib/appointmentsEqualAndDefined';
import { formatAppointmentTime } from 'core/lib/formatAppointmentTime';
import useCancelAppointment from 'core/hooks/useCancelAppointment';
import { addDays } from 'date-fns';
import { BookingAppointment } from '@bondvet/types/telehealth';
import styles from './Booking.module.scss';

type BookingScheduleProps = {
    date: Date;
    setDate: React.Dispatch<React.SetStateAction<Date>>;
    scheduleLocation: string;
};

function BookingSchedule({
    date,
    setDate,
    scheduleLocation,
}: BookingScheduleProps) {
    const intl = useIntl();
    const { isProvider, setAppointment, confirmedAppointment } =
        useBookingContext();
    const { cancelAppointment } = useCancelAppointment();

    const locationsWithTimeSlots = useNextAvailableTimeSlots({
        locationIds: [scheduleLocation],
        startDate: date,
    });

    function nextDay() {
        setDate((prev) => addDays(prev, 1));
    }

    function previousDay() {
        setDate((prev) => addDays(prev, -1));
    }

    function firstDayReached() {
        return date.getTime() <= new Date().getTime();
    }

    const locationWithTimeSlots =
        locationsWithTimeSlots.length > 0
            ? locationsWithTimeSlots[0]
            : undefined;

    function Timeslot({ dateTime, location }: BookingAppointment) {
        return apppointmentsEqualAndDefined(
            { dateTime, location },
            confirmedAppointment,
        ) ? (
            <div
                className={classNames(
                    styles['timeslot-element'],
                    styles.booked,
                )}
            >
                {formatAppointmentTime({ dateTime, location })}
                <button
                    className={styles.undo}
                    type="button"
                    onClick={() => cancelAppointment(confirmedAppointment?.id)}
                >
                    {intl.formatMessage({ id: 'app.undo' })}
                </button>
            </div>
        ) : (
            <button
                type="button"
                className={styles['timeslot-element']}
                onClick={() =>
                    setAppointment({
                        dateTime,
                        location,
                    })
                }
            >
                {formatAppointmentTime({ dateTime, location })}
            </button>
        );
    }

    return (
        <div className={styles.schedule}>
            <div className={styles['day-slider']}>
                {isProvider && (
                    <button
                        disabled={firstDayReached()}
                        type="button"
                        onClick={() => previousDay()}
                    >
                        <KeyboardArrowLeftIcon />
                        <span className="sr-only">
                            {intl.formatMessage({ id: 'app.back' })}
                        </span>
                    </button>
                )}
                <span className={styles['day-slider__time']}>
                    {date.toLocaleDateString('en-EN')}
                </span>
                {isProvider && (
                    <button type="button" onClick={() => nextDay()}>
                        <KeyboardArrowRightIcon />
                        <span className="sr-only">
                            {intl.formatMessage({ id: 'app.next' })}
                        </span>
                    </button>
                )}
            </div>
            {date && scheduleLocation && (
                <div className={styles['timeslot-select']}>
                    {locationWithTimeSlots ? (
                        <>
                            {locationWithTimeSlots.timeSlots.map((time) => (
                                <Timeslot
                                    key={time}
                                    dateTime={time}
                                    location={locationWithTimeSlots.location}
                                />
                            ))}
                            {locationWithTimeSlots.timeSlots.length === 0 && (
                                <p className={styles.loading}>
                                    {intl.formatMessage({
                                        id: 'booking.noAppointments',
                                    })}
                                </p>
                            )}
                        </>
                    ) : (
                        <p className={styles.loading}>
                            {intl.formatMessage({ id: 'app.loading' })}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
}

export default BookingSchedule;
