import * as React from 'react';
import { REGEX_NAME_INITIALS } from '../../../constants';
import styles from './Message.module.scss';

function InitialsCircle({ name }: { name: string }) {
    const capture = name.match(REGEX_NAME_INITIALS);
    const firstNameInitial = capture?.[1];
    const lastNameInitial = capture?.[2];
    const initials = `${firstNameInitial || ''}${
        lastNameInitial || ''
    }`.toUpperCase();

    return (
        <div className={styles['initials-circle']}>
            <span>{initials}</span>
        </div>
    );
}

export default InitialsCircle;
