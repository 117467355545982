import { BookingAppointment } from '@bondvet/types/telehealth';

export function formatAppointmentTime({
    dateTime,
    location,
}: BookingAppointment): string {
    const timeZone = location?.timezone;
    if (dateTime && timeZone) {
        const date = new Date(dateTime);
        return date.toLocaleTimeString('en-US', {
            timeZone,
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h12',
            timeZoneName: 'short',
        });
    }
    return '';
}
