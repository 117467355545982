import {
    BookingAppointment,
    ConfirmedAppointment,
} from '@bondvet/types/telehealth';

import * as React from 'react';

type BookingContextType = {
    handleBookingTabChange: (newValue: number | string) => void;
    setAppointment: ({ dateTime, location }: BookingAppointment) => void;
    appointment: BookingAppointment | undefined;
    confirmedAppointment: ConfirmedAppointment | undefined;
    setConfirmedAppointment: ({
        dateTime,
        location,
        id,
    }: ConfirmedAppointment) => void;
    isProvider: boolean;
    activeBookingTab: string | number;
    setSync: (value: boolean) => void;
};

export const BookingContext = React.createContext<BookingContextType>({
    handleBookingTabChange: () => {},
    isProvider: false,
    activeBookingTab: 0,
    setAppointment: () => {},
    setConfirmedAppointment: () => {},
    appointment: undefined,
    confirmedAppointment: undefined,
    setSync: () => {},
});

export function useBookingContext(): BookingContextType {
    return React.useContext<BookingContextType>(BookingContext);
}
