import useBookingSyncLocations from 'core/hooks/useBookingSyncLocations';
import useLocations from 'core/hooks/useLocations';
import useNextAvailableTimeSlots, {
    LocationWithTimeSlots,
} from 'core/hooks/useNextAvailableTimeSlots';
import * as React from 'react';
import { useBookingContext } from 'team/hooks/useBookingContext';
import { Location } from '@bondvet/types/telehealth';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { apppointmentsEqualAndDefined } from 'core/lib/appointmentsEqualAndDefined';
import { formatAppointmentTime } from 'core/lib/formatAppointmentTime';
import useCancelAppointment from 'core/hooks/useCancelAppointment';
import PillCheckBox from '../PillCheckBox';
import styles from './Booking.module.scss';

type NextElement = {
    time: string;
    color: string;
    location: Location;
};

function getColor(locationId: string): string {
    const FIRST_ASCII_CHAR = 48;

    const charCode = locationId.charCodeAt(0);
    const charCode2 = locationId.charCodeAt(1);
    // Map ASCII value (48-122) to 0-360 deg
    const hashedCode = Math.round((charCode - FIRST_ASCII_CHAR) * 5);
    // Map ASCII value to a second variable for small lightness and saturation adjustments
    const hashedCode2 = Math.round((charCode2 - FIRST_ASCII_CHAR) / 4);
    return `hsl(${hashedCode}, ${hashedCode2 + 25}%, ${hashedCode2 + 60}%)`;
}

function mapNextElements(
    locations: Array<LocationWithTimeSlots>,
): Array<NextElement> {
    const result: Array<NextElement> = [];
    locations.forEach((locationWithTimeSlot) => {
        locationWithTimeSlot.timeSlots.forEach((timeslot) => {
            result.push({
                time: timeslot,
                location: locationWithTimeSlot.location,
                color: getColor(locationWithTimeSlot.location._id),
            });
        });
    });

    return result.sort((a, b) => a.time.localeCompare(b.time));
}

function BookingNext() {
    const intl = useIntl();
    const { cancelAppointment } = useCancelAppointment();
    const locations = useLocations();
    const locationIds = React.useMemo(
        () =>
            locations.length > 0
                ? locations.map((location) => location._id)
                : [],
        [locations],
    );

    const [selectedLocations, setSelectedLocations] = useBookingSyncLocations(
        locationIds ?? [],
    );

    const { setAppointment = () => {}, confirmedAppointment } =
        useBookingContext();

    const timeSlots = useNextAvailableTimeSlots({
        locationIds: selectedLocations,
        startDate: new Date(),
    });

    const nextElements = React.useMemo(
        () => mapNextElements(timeSlots),
        [timeSlots],
    );

    function hideLocation(locationId: string) {
        setSelectedLocations((prev) =>
            prev.filter((item) => item !== locationId),
        );
    }

    function showLocation(locationId: string) {
        setSelectedLocations((prev) => [...prev, locationId]);
    }

    function hasLocation(locationId: string): boolean {
        if (!selectedLocations) return false;

        return (
            selectedLocations.find((item) => item === locationId) !== undefined
        );
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            showLocation(event.target.value);
        } else {
            hideLocation(event.target.value);
        }
    }

    const RenderElement = (element: NextElement) =>
        apppointmentsEqualAndDefined(
            { dateTime: element.time, location: element.location },
            confirmedAppointment,
        ) ? (
            <div
                key={`${element.location.name}-${element.time}`}
                className={classNames(styles['next-element'], styles.booked)}
            >
                <div className={styles['next-element-time']}>
                    {formatAppointmentTime({
                        dateTime: element.time,
                        location: element.location,
                    })}
                </div>
                <div className={styles['next-element-location']}>
                    {element.location.name}
                </div>
                <button
                    type="button"
                    className={styles.undo}
                    onClick={() => cancelAppointment(confirmedAppointment?.id)}
                >
                    {intl.formatMessage({ id: 'app.undo' })}
                </button>
            </div>
        ) : (
            <button
                type="button"
                data-cy="bookingButton"
                key={`${element.location.name}-${element.time}`}
                className={styles['next-element']}
                onClick={() =>
                    setAppointment({
                        location: element.location,
                        dateTime: element.time,
                    })
                }
            >
                <div className={styles['next-element-time']}>
                    {formatAppointmentTime({
                        dateTime: element.time,
                        location: element.location,
                    })}
                </div>
                <div
                    className={styles['next-element-location']}
                    style={{ backgroundColor: element.color }}
                >
                    {element.location.name}
                </div>
            </button>
        );

    return (
        <>
            <ul className={styles['location-list']}>
                {locations.map((location) => (
                    <li key={location._id}>
                        <PillCheckBox
                            checked={hasLocation(location._id)}
                            value={location._id}
                            bgColor={
                                hasLocation(location._id)
                                    ? getColor(location._id)
                                    : '#ccc'
                            }
                            id={location._id}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                handleChange(event);
                            }}
                        >
                            {location.name}
                        </PillCheckBox>
                    </li>
                ))}
                {locations.length === 0 && (
                    <li className={styles.loading}>
                        {intl.formatMessage({ id: 'app.loading' })}
                    </li>
                )}
            </ul>
            <div className={styles.next}>
                {nextElements.map((timeslot) => RenderElement(timeslot))}
            </div>
        </>
    );
}

export default BookingNext;
