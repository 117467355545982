import { IconButton } from '@mui/material';
import * as React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import { Step } from '../../lib/types';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import styles from './PageHeader.module.scss';

export interface PageHeaderProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    classes?: typeof styles;
    label?: React.ReactNode;
    previousStep?: Step | null;
    onClose?: () => void;
    componentType: 'navigation' | 'textOnly' | 'close';
}

function PageHeader({
    classes,
    className,
    label,
    previousStep = null,
    onClose,
    componentType,
    ...props
}: PageHeaderProps): React.ReactElement {
    const { setStep } = useClientContext();

    const intl = useIntl();
    const labelText =
        label === undefined ? intl.formatMessage({ id: 'app.back' }) : label;

    switch (componentType) {
        case 'navigation':
            if (!previousStep)
                throw Error(
                    'tried to render navigation-pageHeader without previousStep function.',
                );
            return (
                <button
                    type="button"
                    className={classNames(
                        className,
                        classes?.root,
                        styles.root,
                        styles['back-button'],
                    )}
                    onClick={() => setStep(previousStep)}
                    {...props}
                >
                    <KeyboardArrowLeftIcon />
                    {labelText}
                </button>
            );

        case 'textOnly':
            return (
                <div
                    className={classNames(
                        className,
                        classes?.root,
                        styles.root,
                        styles['title-in-center'],
                    )}
                >
                    {labelText}
                </div>
            );

        case 'close':
            if (!onClose)
                throw Error(
                    'tried to render navigation-pageHeader without previousStep function.',
                );
            return (
                <div
                    className={classNames(
                        className,
                        classes?.root,
                        styles.root,
                        styles['title-in-center'],
                    )}
                >
                    {labelText}
                    <IconButton
                        onClick={onClose}
                        size="large"
                        className={styles['close-button']}
                    >
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </div>
            );

        default:
            throw Error('Error rendering PageHeader');
    }
}

export default PageHeader;
