import { gql } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import useTelehealthQuery from 'core/hooks/useTelehealthQuery';

const stripeAccountIdQuery = gql`
    query Query {
        telehealthStripeAccountId
    }
`;

type StripeAccountIdResult = {
    telehealthStripeAccountId: string;
};

export default function useStripeAccountId(): QueryResult<StripeAccountIdResult> {
    return useTelehealthQuery<StripeAccountIdResult>(stripeAccountIdQuery);
}
