import { updateDoc, onSnapshot } from 'firebase/firestore';
import * as React from 'react';
import useBookingUtils from './useBookingUtils';

type UseBookingSyncLocations = [
    selectedLocations: string[],
    setSelectedLocationsAndCommit: React.Dispatch<
        React.SetStateAction<string[]>
    >,
];

export default function useBookingSyncLocations(
    defaultLocations: Array<string>,
): UseBookingSyncLocations {
    const { sessionRef, isProvider } = useBookingUtils();
    const [selectedLocations, setSelectedLocations] = React.useState(
        defaultLocations ?? [],
    );

    React.useEffect(() => {
        if (isProvider && sessionRef) {
            updateDoc(sessionRef, {
                selectedLocations,
            });
        }
    }, [isProvider, selectedLocations, sessionRef]);

    React.useEffect(() => {
        let unsubscribe = () => {};

        if (!isProvider && sessionRef) {
            unsubscribe = onSnapshot(sessionRef, (snapshot) => {
                const data = snapshot.data();
                if (data) {
                    if (
                        data.selectedLocations?.length !==
                        selectedLocations.length
                    ) {
                        setSelectedLocations(data.selectedLocations ?? []);
                    }
                }
            });
        }
        return unsubscribe;
    }, [isProvider, selectedLocations, sessionRef]);

    return [selectedLocations, setSelectedLocations];
}
