import { updateDoc, onSnapshot } from 'firebase/firestore';
import * as React from 'react';
import useBookingUtils from './useBookingUtils';

type UseBookingSyncAppointment = {
    setNotes: (note: string) => void;
    notes: string | undefined;
};

export default function useBookingSyncAppointment(): UseBookingSyncAppointment {
    const { sessionRef, isProvider } = useBookingUtils();
    const [notes, setNotesLocally] = React.useState<string>();

    function setNotes(newNote: string) {
        setNotesLocally(newNote);

        if (sessionRef) {
            updateDoc(sessionRef, { appointmentNote: newNote });
        }
    }

    React.useEffect(() => {
        let unsubscribe = () => {};

        if (!isProvider && sessionRef) {
            unsubscribe = onSnapshot(sessionRef, (snapshot) => {
                const data = snapshot.data();
                if (data) {
                    setNotesLocally(data.appointmentNote);
                }
            });
        }
        return unsubscribe;
    }, [isProvider, sessionRef]);

    return { notes, setNotes };
}
