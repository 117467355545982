import { BookingAppointment } from '@bondvet/types/telehealth';

export function apppointmentsEqualAndDefined(
    appointment1: BookingAppointment | undefined,
    appointment2: BookingAppointment | undefined,
): boolean {
    if (!appointment2 || !appointment1) return false;
    if (
        appointment1.dateTime === appointment2.dateTime &&
        appointment1.location?._id === appointment2.location?._id
    )
        return true;
    return false;
}
