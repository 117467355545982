import * as React from 'react';
import { ClientContextType } from '../../lib/types';
import getInitialClientContextState from './initialState';

const ClientContext = React.createContext<ClientContextType>({
    setOrigin: () => {},
    setFirstName: () => {},
    setLastName: () => {},
    setEmail: () => {},
    setPhone: () => {},
    setStep: () => {},
    setPetId: () => {},
    setPetName: () => {},
    setPetSpecies: () => {},
    setReasonId: () => {},
    addUserFiles: () => {},
    removeUserFile: () => {},
    setNotes: () => {},
    setDisclaimerAcknowledged: () => {},
    ...getInitialClientContextState(),
});

export default ClientContext;
