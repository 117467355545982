import { SessionStatus } from '@bondvet/types/telehealth';
import classNames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useVideoContext from '../../../hooks/video/useVideoContext';
import VideoParticipant from './VideoParticipant';
import styles from './VideoStreams.module.scss';
import useIsMobile from '../../../hooks/useIsMobile';

type VideoStreamProps = {
    twilioAuthToken: string;
    roomId: string;
    sessionStatus: SessionStatus;
    onAuthExpired?: () => void;
    onRoomExpired?: () => void;
    fixedPosition?: boolean;
    isClient?: boolean;
    mirrorVideoOpen?: boolean;
    chatOpen?: boolean;
    onDisconnected?: () => void;
    largeMirrorVideo?: boolean;
};

function VideoStreams({
    sessionStatus,
    twilioAuthToken,
    roomId,
    onAuthExpired,
    onRoomExpired,
    fixedPosition = true,
    mirrorVideoOpen = true,
    chatOpen = false,
    isClient = true,
    onDisconnected,
    largeMirrorVideo = false,
}: VideoStreamProps) {
    const {
        cameraOn,
        room,
        connectedToCall,
        setConnectedToCall,
        localVideoTrack,
        localAudioTrack,
        remoteParticipant,
        remoteVideoTrack,
        remoteAudioTrack,
        joinRoom,
    } = useVideoContext();
    const intl = useIntl();

    const inCall =
        sessionStatus === SessionStatus.nurseCall ||
        sessionStatus === SessionStatus.veterinarianCall;

    const isMobile = useIsMobile();

    // create room and load remote-participants
    React.useEffect(() => {
        if (connectedToCall) return;
        setConnectedToCall(true);
        joinRoom(
            twilioAuthToken,
            roomId,
            onAuthExpired,
            onRoomExpired,
            onDisconnected,
        );
    }, [
        connectedToCall,
        joinRoom,
        onAuthExpired,
        onRoomExpired,
        roomId,
        setConnectedToCall,
        twilioAuthToken,
        onDisconnected,
    ]);

    const otherParticipantAway =
        inCall && !remoteVideoTrack && !remoteAudioTrack;
    const noRemoteVideo = inCall && !remoteVideoTrack && remoteAudioTrack;

    return (
        <div
            className={classNames({
                [styles.fixed]: fixedPosition,
                [styles.foreground]: inCall,
            })}
        >
            {inCall && remoteParticipant && (
                <VideoParticipant
                    key={remoteParticipant.sid}
                    classes={{
                        root: fixedPosition
                            ? styles['full-screen']
                            : 'part-screen',
                    }}
                    videoTrack={remoteVideoTrack}
                    audioTrack={remoteAudioTrack}
                />
            )}
            {otherParticipantAway && (
                <p className={styles['alone-message']}>
                    {isClient
                        ? intl.formatMessage({
                              id: 'callPage.video.clientAlone',
                          })
                        : intl.formatMessage({
                              id: 'callPage.video.providerAlone',
                          })}
                </p>
            )}
            {noRemoteVideo && (
                <div className={styles['video-placeholder']}>
                    <img
                        src="/user.svg"
                        alt={intl.formatMessage({
                            id: 'callPage.video.videoPlaceholder',
                        })}
                    />
                </div>
            )}
            {room && mirrorVideoOpen && (
                <VideoParticipant
                    key="client"
                    classes={{
                        root: inCall
                            ? classNames(styles['in-call-client'], {
                                  [styles['in-call-client-default']]:
                                      isMobile || !chatOpen,
                                  [styles['in-call-client-chat-open']]:
                                      !isMobile && chatOpen,
                                  [styles['in-call-client-large']]:
                                      largeMirrorVideo,
                              })
                            : classNames(
                                  styles['full-screen'],
                                  styles['client-waiting'],
                              ),
                        video: !cameraOn ? styles.hidden : '',
                    }}
                    videoTrack={localVideoTrack}
                    audioTrack={localAudioTrack}
                    draggable={inCall}
                />
            )}
        </div>
    );
}

export default VideoStreams;
