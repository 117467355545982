import { GraphqlProvider } from '@bondvet/types/telehealth';
import * as React from 'react';

type UseProviderNameProps = {
    provider: GraphqlProvider | undefined;
};

export default function useProviderName({
    provider,
}: UseProviderNameProps): string {
    const providerName = React.useMemo(() => {
        if (!provider?.firstName && !provider?.lastName) {
            return '';
        }
        return `${provider?.firstName} ${provider?.lastName}`;
    }, [provider?.firstName, provider?.lastName]);

    return providerName;
}
