import * as React from 'react';
import mapStyles from 'core/components/Booking/mapStyles.json';
import { updateDoc, onSnapshot } from 'firebase/firestore';
import debounce from 'lodash/debounce';
import useBookingUtils from './useBookingUtils';

type UseBookingSynMapProps = {
    ref: React.RefObject<HTMLDivElement>;
};

type UseBookingSyncMap = {
    map: google.maps.Map | undefined;
};

export default function useBookingSyncMap({
    ref,
}: UseBookingSynMapProps): UseBookingSyncMap {
    const [map, setMap] = React.useState<google.maps.Map>();
    const { sessionRef, isProvider } = useBookingUtils();

    React.useEffect(() => {
        if (ref.current) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center: { lat: 40.776676, lng: -73.971321 },
                    zoom: 12,
                    styles: mapStyles,
                    disableDefaultUI: true,
                    zoomControl: isProvider,
                }),
            );
        }
    }, [isProvider, ref]);

    React.useEffect(() => {
        let listener: google.maps.MapsEventListener;

        function handleCenterChange() {
            if (map && sessionRef) {
                const center = map.getCenter();

                updateDoc(sessionRef, {
                    mapCenter: {
                        lat: center?.lat(),
                        lng: center?.lng(),
                    },
                });
            }
        }

        const debouncedHandleCenterChange = debounce(handleCenterChange, 200, {
            leading: false,
        });

        if (map && isProvider) {
            // Send on load to make sure client is not out of sync
            debouncedHandleCenterChange();

            listener = map.addListener(
                'center_changed',
                debouncedHandleCenterChange,
            );
        }

        return () => {
            if (listener) {
                google.maps.event.removeListener(listener);
            }
        };
    }, [isProvider, map, sessionRef]);

    React.useEffect(() => {
        let listener: google.maps.MapsEventListener;

        function handleZoomChange() {
            if (map && sessionRef) {
                updateDoc(sessionRef, {
                    mapZoom: map.getZoom(),
                });
            }
        }

        const debouncedHandleZoomChange = debounce(handleZoomChange, 200, {
            leading: false,
        });

        if (map && isProvider) {
            // Send on load to make sure client is not out of sync
            debouncedHandleZoomChange();

            listener = map.addListener(
                'zoom_changed',
                debouncedHandleZoomChange,
            );
        }

        return () => {
            if (listener) {
                google.maps.event.removeListener(listener);
            }
        };
    }, [isProvider, map, sessionRef]);

    React.useEffect(() => {
        let unsubscribe = () => {};

        if (!isProvider && sessionRef) {
            unsubscribe = onSnapshot(sessionRef, (snapshot) => {
                const data = snapshot.data();
                if (data && map) {
                    if (data.mapZoom) {
                        map.setZoom(data.mapZoom);
                    }
                    if (data.mapCenter) {
                        map.panTo({
                            lat: data.mapCenter.lat,
                            lng: data.mapCenter.lng,
                        });
                    }
                }
            });
        }
        return unsubscribe;
    }, [isProvider, map, sessionRef]);

    return { map };
}
