import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'core/components/App';
import ClientApp from 'client/components/App';
import reportWebVitals from './reportWebVitals';
import 'styles/scss/global.scss';

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <App>
                <ClientApp />
            </App>
        </React.StrictMode>,
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
