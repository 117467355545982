import * as React from 'react';
import DocumentPreview from '../../Files/preview/DocumentPreview';
import styles from './Message.module.scss';

interface MessageAttachmentProps {
    fileName: string;
    url: string;
    thumbnailUrl?: string;
}

function MessageAttachment({
    fileName,
    url,
    thumbnailUrl,
}: MessageAttachmentProps) {
    // display thumbnail if available
    if (thumbnailUrl) {
        return (
            <a href={url} target="_blank" rel="noreferrer">
                <img
                    src={thumbnailUrl}
                    alt={fileName}
                    className={styles['attachment-image']}
                />
            </a>
        );
    }

    return <DocumentPreview title={fileName} url={url} />;
}

export default MessageAttachment;
