import { AppointmentType, Species } from '@bondvet/types/booking';
import {
    ClientFirestoreSessionIds,
    Patient,
    PatientPlanTelehealthQuantities,
} from '@bondvet/types/telehealth';
import { VideoContextType } from 'core/hooks/video/context';

export enum Step {
    intake = 'intake',
    reason = 'reason',
    payment = 'payment',
    join = 'join',
    call = 'call',
    summary = 'summary',
    end = 'end',
    disconnected = 'disconnected',

    debugSkipForm = 'debugSkipForm',
}

export type Reason = Pick<AppointmentType, '_id' | 'name'>;

export type CreditCardsForEmailAddress = {
    last4: string;
    id: string;
    isDefault: boolean;
    brand: string;
};

export type PetSpecies = undefined | Species.canine | Species.feline;

export type ClientContextData = {
    origin: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    patientsQueryLoading: boolean;
    emailQueryLoading: boolean;
    step: Step;
    pet: {
        _id: string;
        name: string;
        species: PetSpecies;
    };
    reasonId: string;
    notes: string;
    disclaimerAcknowledged: boolean;
    clientKnown: boolean;
    creditCardLast4: string;
    creditCards: ReadonlyArray<CreditCardsForEmailAddress>;
    sourceId: string;
    userFiles: ReadonlyArray<File>;
    patients: ReadonlyArray<Patient>;
    selectedPatient: Patient | null;
    patientPlanTelehealthQuantities: PatientPlanTelehealthQuantities | null;
    reasons: ReadonlyArray<Reason>;
    debugSkipForm: boolean;
};

export type ClientContextUpdaters = {
    setOrigin: (origin: string) => void;
    setFirstName: (name: string) => void;
    setLastName: (name: string) => void;
    setEmail: (email: string) => void;
    setPhone: (phone: string) => void;
    setStep: (step: Step, urlParameter?: string) => void;
    setPetId: (id: string) => void;
    setPetName: (petName: string) => void;
    setPetSpecies: (species: PetSpecies) => void;
    setReasonId: (reasonId: string) => void;
    setNotes: (notes: string) => void;
    setDisclaimerAcknowledged: (disclaimerAcknowledged: boolean) => void;
    addUserFiles: (files: ReadonlyArray<File>) => void;
    removeUserFile: (file: File) => void;
};

export type ClientContextType = ClientContextData & ClientContextUpdaters;

export type CallContextData = {
    chatOpen: boolean;
    bookingOpen: boolean;
    sessionIds: ClientFirestoreSessionIds;
};

export type CallContextUpdaters = {
    setChatOpen: (chatOpen: boolean) => void;
    setBookingOpen: (bookingOpen: boolean) => void;
    setSessionIds: (sessionIds: ClientFirestoreSessionIds) => void;
    enterVideoCall: (
        clientContext: ClientContextType,
        signIntoFirestore: (authToken: string) => Promise<void>,
        paymentIntentId: string | null,
    ) => Promise<void>;
    endCall: (
        clientContext: ClientContextType,
        videoContext: VideoContextType,
    ) => void;
};

export type CallContextType = CallContextData & CallContextUpdaters;
