import { gql, useQuery } from '@apollo/client';
import { ConfirmedAppointment, Location } from '@bondvet/types/telehealth';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { useBookingContext } from 'team/hooks/useBookingContext';

const query = gql`
    query nextAvailableTimeSlots($locationIds: [ID]!, $startDate: Date!) {
        nextAvailableTimeSlots(
            locationIds: $locationIds
            startDate: $startDate
        ) {
            location {
                _id
                name
                coordinates {
                    latitude
                    longitude
                }
                timezone
            }
            timeSlots
        }
    }
`;

export type LocationWithTimeSlots = {
    location: Location;
    timeSlots: Array<string>;
};

type NextAvailableTimeSlotsQuery = {
    nextAvailableTimeSlots: LocationWithTimeSlots[];
};

type UseNextAvailableTimeSlotsProps = {
    locationIds: Array<string>;
    startDate: Date;
};

function mergeConfirmedTimeSlot(
    locations: LocationWithTimeSlots[],
    confirmedAppointment: ConfirmedAppointment | undefined,
): LocationWithTimeSlots[] {
    if (!confirmedAppointment?.dateTime || !confirmedAppointment.location)
        return locations;

    const result = cloneDeep(locations);

    // eslint-disable-next-line no-restricted-syntax
    for (const location of result)
        if (location.location._id === confirmedAppointment.location._id) {
            // if location matches
            let j;
            for (j = 0; j < location.timeSlots.length; j += 1) {
                // If we are operating on different dates do nothing
                if (
                    new Date(location.timeSlots[j]).getDate() !==
                    new Date(confirmedAppointment.dateTime).getDate()
                ) {
                    return result;
                }
                // insert timeslot into sorted set
                if (location.timeSlots[j] === confirmedAppointment.dateTime) {
                    return result;
                }
                if (location.timeSlots[j] > confirmedAppointment.dateTime) {
                    break;
                }
            }
            location.timeSlots.splice(j, 0, confirmedAppointment.dateTime);

            return result;
        }

    return result;
}

export default function useNextAvailableTimeSlots({
    locationIds,
    startDate,
}: UseNextAvailableTimeSlotsProps): Array<LocationWithTimeSlots> {
    const fullDate = startDate.toISOString().split('T')[0];
    const { data, refetch } = useQuery<NextAvailableTimeSlotsQuery>(query, {
        variables: { locationIds, startDate: fullDate },
        skip: locationIds.some((id) => !id),
    });
    const { confirmedAppointment } = useBookingContext();

    React.useEffect(() => {
        refetch();
    }, [confirmedAppointment, refetch]);

    const slots = data?.nextAvailableTimeSlots ?? [];
    return mergeConfirmedTimeSlot(slots, confirmedAppointment);
}
