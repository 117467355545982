import { gql, useQuery } from '@apollo/client';
import { Location } from '@bondvet/types/locations';

const query = gql`
    query locations {
        locations {
            _id
            name
            coordinates {
                latitude
                longitude
            }
        }
    }
`;

type LocationsQuery = {
    locations: Location[];
};

export default function useLocations(): Location[] | never[] {
    const result = useQuery<LocationsQuery>(query);
    return result.data?.locations ?? [];
}
