import * as React from 'react';
import classnames from 'classnames';
import TextField, { TextFieldProps } from '../TextField';
import SelectInput from './SelectInput';
import type { SelectProps as BaseProps } from './types';
import styles from './Select.module.scss';

export type SelectProps = Omit<TextFieldProps, 'inputComponent'> & BaseProps;

function Select({
    classes,
    options,
    onOpen,
    onClose,
    inputProps,
    ...props
}: SelectProps): React.ReactElement {
    return (
        <TextField
            {...props}
            inputComponent={SelectInput}
            classes={{
                ...classes,
            }}
            inputClasses={{
                root: classnames(classes?.root, styles.root),
            }}
            inputProps={{
                ...inputProps,
                onOpen,
                onClose,
                options,
            }}
        />
    );
}

export default Select;
