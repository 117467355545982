import { gql } from '@apollo/client';
import useDebounced from 'core/hooks/useDebounced';
import useLazyTelehealthQuery from 'core/hooks/useLazyTelehealthQuery';
import useTelehealthQuery from 'core/hooks/useTelehealthQuery';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';
import {
    Patient,
    PatientPlanTelehealthQuantitiesQueryArguments,
    PatientPlanTelehealthQuantitiesQueryResult,
} from '@bondvet/types/telehealth';
import { ContactInfo } from '@bondvet/types/booking';
import { PHONE_MASKING_SYMBOL } from 'core/constants';
import {
    ClientContextData,
    ClientContextType,
    CreditCardsForEmailAddress,
    PetSpecies,
    Reason,
    Step,
} from '../../lib/types';
import useLazyCreditCardsQuery from '../useLazyCreditCardsQuery';
import useStripeAccountId from '../useStripeAccountId';
import getInitialClientContextState from './initialState';
import useSetter from './useSetter';

export const patientsAndContactDataQuery = gql`
    query patientsAndContactData($email: Email!) {
        getPatients(email: $email) {
            _id
            name
            sex
            species
            patientPlan {
                id
                preventionPlan {
                    id
                    name
                }
                telehealthQuantity
                telehealthQuantityUsed
            }
        }
        getContactData(email: $email) {
            _id
            firstName
            lastName
            phoneNumberLast4
        }
    }
`;

export const patientPlanTelehealthQuantitiesQuery = gql`
    query patientPlanTelehealthQuantities($patientPlanId: ID!) {
        patientPlanTelehealthQuantities(patientPlanId: $patientPlanId) {
            quantity
            quantityUsed
            hasUnused
        }
    }
`;

export const reasonsQuery = gql`
    query appointmentTypes {
        appointmentTypes {
            _id
            name
        }
    }
`;

export const creditCardsForEmailAddressQuery = gql`
    query CreditCardsForEmailAddress($email: Email!, $stripeAccountId: String) {
        creditCardsForEmailAddress(
            email: $email
            stripeAccountId: $stripeAccountId
        ) {
            last4
            id
            isDefault
            brand
        }
    }
`;

type PatientsAndContactDataQuery = {
    getPatients: ReadonlyArray<Patient>;
    getContactData?: Omit<ContactInfo, 'defaultLocation'>;
};

type PatientsAndContactDataQueryVariables = {
    email: string;
};

type ReasonsQuery = {
    appointmentTypes: ReadonlyArray<Reason>;
};

type CreditCardsForEmailAddressQuery = {
    creditCardsForEmailAddress: ReadonlyArray<CreditCardsForEmailAddress>;
};

export default function useClientContextHandler(): ClientContextType {
    const history = useHistory();
    const initialClientContextState = getInitialClientContextState();
    const { loading: stripeAccountIdLoading, data: stripeAccountIdData } =
        useStripeAccountId();

    const [
        {
            origin,
            firstName,
            lastName,
            email,
            phone,
            step,
            pet,
            patients,
            reasons,
            reasonId,
            notes,
            userFiles,
            disclaimerAcknowledged,
            creditCardLast4,
            creditCards,
            sourceId,
            clientKnown,
            debugSkipForm,
        },
        setValue,
    ] = React.useState<ClientContextData>(initialClientContextState);

    const setOrigin = useSetter('origin', setValue);
    const setFirstName = useSetter('firstName', setValue);
    const setLastName = useSetter('lastName', setValue);
    const setEmail = useSetter('email', setValue);
    const setPhone = useSetter('phone', setValue);
    const setReasonId = useSetter('reasonId', setValue);
    const setNotes = useSetter('notes', setValue);
    const setDisclaimerAcknowledged = useSetter<boolean>(
        'disclaimerAcknowledged',
        setValue,
    );
    const setStep = React.useCallback(
        (newStep: Step, urlParameter?: string) => {
            setValue((prev) => ({
                ...prev,
                step: newStep,
            }));

            if (Step[newStep]) {
                if (urlParameter) {
                    history.push(`/${newStep}/${urlParameter}`);
                } else {
                    history.push(`/${newStep}`);
                }
            } else {
                throw new Error('Tried to redirect to unknown page');
            }
        },
        [history],
    );
    const debouncedEmail = useDebounced<string>(email);
    const [
        runPatientsAndContactDataQuery,
        {
            data: patientsAndContactData,
            loading: patientsAndContactDataQueryLoading,
        },
    ] = useLazyTelehealthQuery<
        PatientsAndContactDataQuery,
        PatientsAndContactDataQueryVariables
    >(patientsAndContactDataQuery, {
        fetchPolicy: 'cache-first',
    });

    const { data: reasonsData } = useTelehealthQuery<ReasonsQuery>(
        reasonsQuery,
        {
            fetchPolicy: 'cache-first',
        },
    );

    const [
        runCreditCardsForEmailAddressQuery,
        { data: creditCardLast4Data, loading: creditCardLast4Loading },
    ] = useLazyCreditCardsQuery<CreditCardsForEmailAddressQuery>(
        creditCardsForEmailAddressQuery,
        {
            fetchPolicy: 'cache-first',
        },
    );

    React.useEffect(() => {
        if (reasonsData?.appointmentTypes) {
            setValue((prev) => ({
                ...prev,
                reasons: reasonsData.appointmentTypes,
            }));
        }
    }, [reasonsData?.appointmentTypes]);

    React.useEffect(() => {
        const cardsData = creditCardLast4Data?.creditCardsForEmailAddress;
        const defaultLast4 =
            cardsData?.find((card) => card.isDefault)?.last4 || '';
        const defaultSourceId =
            cardsData?.find((card) => card.isDefault)?.id || '';
        if (!cardsData || cardsData.length === 0) {
            setValue((prev) => ({
                ...prev,
                creditCardLast4: '',
                sourceId: '',
                creditCards: [],
                clientKnown: false,
            }));
        } else {
            // this is where we want to change the state to multiple cards
            setValue((prev) => ({
                ...prev,
                creditCardLast4: defaultLast4,
                sourceId: defaultSourceId,
                creditCards: cardsData,
                clientKnown: cardsData.length > 0,
            }));
        }
    }, [creditCardLast4Data?.creditCardsForEmailAddress]);

    const selectedPatient = React.useMemo(
        () =>
            (pet._id ? patients.find(({ _id }) => _id === pet._id) : null) ??
            null,
        [patients, pet._id],
    );

    const { data: patientPlanTelehealthQuantities } = useTelehealthQuery<
        PatientPlanTelehealthQuantitiesQueryResult,
        PatientPlanTelehealthQuantitiesQueryArguments
    >(patientPlanTelehealthQuantitiesQuery, {
        fetchPolicy: 'cache-first',
        variables: {
            patientPlanId: selectedPatient?.patientPlan?.id ?? '',
        },
        skip: !selectedPatient?.patientPlan?.id,
    });

    React.useEffect(() => {
        if (debouncedEmail && isEmail(debouncedEmail)) {
            runPatientsAndContactDataQuery({
                variables: { email: debouncedEmail },
            }).then();
        }
    }, [debouncedEmail, runPatientsAndContactDataQuery]);

    React.useEffect(() => {
        if (debouncedEmail && isEmail(debouncedEmail)) {
            runCreditCardsForEmailAddressQuery({
                variables: {
                    email: debouncedEmail,
                    stripeAccountId: null,
                },
            }).then();
        } else if (patients.length > 0) {
            setValue((prev) => ({
                ...prev,
                patients: [],
                creditCardLast4: '',
            }));
        }
    }, [
        debouncedEmail,
        patients.length,
        runCreditCardsForEmailAddressQuery,
        stripeAccountIdData,
    ]);

    React.useEffect(() => {
        if (patientsAndContactData?.getPatients) {
            setValue((prev) => ({
                ...prev,
                patients: patientsAndContactData.getPatients.slice(0),
            }));
        }
    }, [patientsAndContactData?.getPatients]);

    React.useEffect(() => {
        if (patientsAndContactData?.getContactData) {
            const contactData = patientsAndContactData?.getContactData;
            const autofillPhone = contactData?.phoneNumberLast4
                ? `${PHONE_MASKING_SYMBOL} ${contactData.phoneNumberLast4}`
                : null;
            setValue((prev) => ({
                ...prev,
                firstName: (prev?.firstName || contactData?.firstName) ?? '',
                lastName: (prev?.lastName || contactData?.lastName) ?? '',
                phone: (prev?.phone || autofillPhone) ?? '',
            }));
        }
    }, [patientsAndContactData?.getContactData]);

    const setPetId = React.useCallback(
        (newPetId: string) => {
            setValue((prev) => ({
                ...prev,
                pet: {
                    ...prev.pet,
                    _id: newPetId,
                    // if the user selected an existing pet,
                    // we want to clear pet.name and pet.species
                    name: newPetId
                        ? patients.find(({ _id }) => _id === newPetId)?.name ||
                          ''
                        : prev.pet.name,
                    species: newPetId ? undefined : prev.pet.species,
                },
            }));
        },
        [patients],
    );

    const setPetName = React.useCallback((newPetName: string) => {
        setValue((prev) => ({
            ...prev,
            pet: {
                ...prev.pet,
                name: newPetName,
                // if the user entered a pet name, we want to clear pet._id
                _id: newPetName.trim() ? '' : prev.pet._id,
                // we only clear the species if the previous name was blank
                // (i.e. the client started entering a name)
                species:
                    newPetName.trim() && !prev.pet.name.trim()
                        ? undefined
                        : prev.pet.species,
            },
        }));
    }, []);

    const setPetSpecies = React.useCallback((newSpecies: PetSpecies) => {
        setValue((prev) => ({
            ...prev,
            pet: {
                ...prev.pet,
                species: newSpecies,
            },
        }));
    }, []);

    const addUserFiles = React.useCallback((files: ReadonlyArray<File>) => {
        setValue((prev) => {
            return {
                ...prev,
                userFiles: [...prev.userFiles, ...files],
            };
        });
    }, []);

    const removeUserFile = React.useCallback((file: File) => {
        setValue((prev) => ({
            ...prev,
            userFiles: [
                ...prev.userFiles.filter((userFile) => userFile !== file),
            ],
        }));
    }, []);

    return {
        origin,
        firstName,
        lastName,
        email,
        patientsQueryLoading: patientsAndContactDataQueryLoading,
        emailQueryLoading:
            stripeAccountIdLoading ||
            patientsAndContactDataQueryLoading ||
            creditCardLast4Loading,
        phone,
        step,
        pet,
        reasonId,
        notes,
        disclaimerAcknowledged,
        creditCardLast4,
        creditCards,
        sourceId,
        clientKnown,
        userFiles,
        setOrigin,
        setFirstName,
        setLastName,
        setEmail,
        setPhone,
        setStep,
        setPetId,
        setPetName,
        setPetSpecies,
        setReasonId,
        setNotes,
        setDisclaimerAcknowledged,
        patients,
        patientPlanTelehealthQuantities:
            patientPlanTelehealthQuantities?.patientPlanTelehealthQuantities ??
            null,
        selectedPatient,
        reasons,
        addUserFiles,
        removeUserFile,
        debugSkipForm,
    };
}
