import * as React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Loading from 'core/components/Loading';
import styles from './NextButton.module.scss';

export interface NextButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    classes?: typeof styles;
    label?: React.ReactNode;
    loading?: boolean;
    variant: 'fixed' | 'grid';
}

function NextButton({
    classes,
    className,
    type = 'submit',
    label,
    loading = false,
    variant,
    ...props
}: NextButtonProps): React.ReactElement {
    /* eslint-disable react/button-has-type */
    const intl = useIntl();
    return (
        <button
            type={type || 'submit'}
            className={classNames(className, classes?.root, styles.root, {
                [styles.fixed]: variant === 'fixed',
                [styles.grid]: variant === 'grid',
            })}
            {...props}
        >
            {label || intl.formatMessage({ id: 'app.next' })}
            {loading && <Loading classes={{ root: styles.loading }} />}
        </button>
    );
}

export default NextButton;
