import * as React from 'react';
import FormControl, { FormControlProps } from '../FormControl';
import Input, { InputProps } from '../Input';

export interface TextFieldProps
    extends Omit<InputProps, 'classes'>,
        FormControlProps {
    inputClasses?: InputProps['classes'];
    Icon?: React.ElementType;
}

function TextField({
    classes,
    value,
    id,
    label,
    required,
    error,
    helperText,
    inputClasses,
    Icon,
    ...props
}: TextFieldProps): React.ReactElement {
    return (
        <FormControl
            id={id}
            value={value}
            label={label}
            required={required}
            error={error}
            classes={classes}
            helperText={helperText}
        >
            <Input
                value={value}
                id={id}
                error={error}
                Icon={Icon}
                {...props}
                classes={inputClasses}
            />
        </FormControl>
    );
}

export default TextField;
