import * as React from 'react';
import { AttachmentInfos } from '@bondvet/types/telehealth';
import useImageAttachments from '../../../hooks/useImageAttachments';
import MessageAttachment from './MessageAttachment';
import styles from './Message.module.scss';

interface MessageAttachmentsProps {
    attachments: AttachmentInfos[];
}

function MessageAttachments({ attachments }: MessageAttachmentsProps) {
    const attachmentImages = useImageAttachments(attachments);

    return (
        <div className={styles['attachments-container']}>
            {attachments.map((attachment) => {
                const REGEX_EXTRACT_FILENAME = /^.*[\\/]/;
                const fileName = attachment.filePath.replace(
                    REGEX_EXTRACT_FILENAME,
                    '',
                );
                if (attachmentImages?.[attachment.vetspireId]) {
                    return (
                        <MessageAttachment
                            fileName={fileName}
                            thumbnailUrl={
                                attachmentImages[attachment.vetspireId]
                                    .thumbnailUrl
                            }
                            url={attachmentImages[attachment.vetspireId].url}
                            key={attachment.vetspireId}
                        />
                    );
                }

                return null;
            })}
        </div>
    );
}

export default MessageAttachments;
