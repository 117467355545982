import * as React from 'react';
import { StripeCardCvcElement, StripeElements } from '@stripe/stripe-js';
import { GraphQLClientNames } from 'lib/types';
import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import isTelehealthAvailable from './availability';
import {
    createPaymentIntentForEmailAddressMutation,
    createStripeSource,
    initialCardState,
    useStripe,
} from './lib';
import {
    ClientUnknownCardElements,
    CreatePaymentIntentForEmailAddressResult,
    NewCreditCardControls,
    StripeChangeEvent,
    StripeElement,
} from './types';
import useClientContext from '../../../hooks/useClientContext/useClientContext';
import useCallContext from '../../../hooks/useCallContext/useCallContext';
import inputStyles from './inputStyles.json';

export default function useNewCreditCard(
    initialIsNewCreditCard: boolean,
): NewCreditCardControls {
    const apolloClient = useApolloClient();
    const stripe = useStripe(false);
    const intl = useIntl();
    const clientContext = useClientContext();
    const { email } = clientContext;
    const { enterVideoCall } = useCallContext();
    const [initialized, setInitialized] = React.useState(false);
    const [isNewCreditCard, setIsNewCreditCard] = React.useState(
        initialIsNewCreditCard,
    );
    const [cardNumberRef, setCardNumberRef] =
        React.useState<HTMLDivElement | null>(null);
    const [cardExpiryRef, setCardExpiryRef] =
        React.useState<HTMLDivElement | null>(null);
    const [cardCvcRef, setCardCvcRef] = React.useState<HTMLDivElement | null>(
        null,
    );
    const [cardElements, setCardElements] =
        React.useState<ClientUnknownCardElements | null>(null);

    const [cvcValid, setCvcValid] = React.useState<boolean>(false);
    const [cvcElement, setCvcElement] = React.useState<
        StripeCardCvcElement | undefined
    >(undefined);

    const [{ processing, processingError }, setErrorState] = React.useState({
        ...initialCardState,
    });

    const setStateToError = (message: string) =>
        setErrorState({
            processing: false,
            processingError: message,
        });

    const [{ valid }, setValid] = React.useState<Record<string, boolean>>({
        cardNumberValid: false,
        cardCvcValid: false,
        cardExpiryValid: false,
        valid: false,
    });

    // set up stripe-dom-elements
    React.useEffect(() => {
        if (
            !initialized &&
            stripe &&
            cardNumberRef &&
            cardCvcRef &&
            cardExpiryRef &&
            isNewCreditCard
        ) {
            const elements: StripeElements = stripe.elements();

            const createChangeHandler = (nextElement?: StripeElement) => {
                return ({ complete, elementType }: StripeChangeEvent) => {
                    requestAnimationFrame(() => {
                        const elementKey = `${elementType}Valid`;

                        // if isNewCreditCard is true validate all fields
                        setValid((prev) => {
                            const allValid = Object.keys(prev)
                                .filter(
                                    (key) =>
                                        key !== 'valid' && key !== elementKey,
                                )
                                .reduce((result, key) => {
                                    if (key === elementKey) {
                                        return result && complete;
                                    }

                                    return result && prev[key];
                                }, complete);

                            return {
                                ...prev,
                                [elementKey]: complete,
                                valid: allValid,
                            };
                        });
                    });

                    if (complete && nextElement) {
                        nextElement.focus();
                    }
                };
            };
            const cardNumberElement = elements.create('cardNumber', {
                showIcon: true,
                style: inputStyles,
            });
            cardNumberElement.mount(cardNumberRef);
            cardNumberElement.on('ready', () => {
                cardNumberElement.focus();
            });

            const cardExpiryElement = elements.create('cardExpiry', {
                style: inputStyles,
            });
            cardExpiryElement.mount(cardExpiryRef);

            const cardCvcElement = elements.create('cardCvc', {
                style: inputStyles,
            });
            cardCvcElement.mount(cardCvcRef);

            cardNumberElement.on(
                'change',
                createChangeHandler(cardExpiryElement),
            );
            cardExpiryElement.on('change', createChangeHandler(cardCvcElement));
            cardCvcElement.on('change', createChangeHandler());

            setCardElements({
                cardNumber: cardNumberElement,
                cardExpiry: cardExpiryElement,
                cardCvc: cardCvcElement,
            });
            setInitialized(true);
        }

        if (!initialized && stripe && cardCvcRef && !isNewCreditCard) {
            const elements: StripeElements = stripe.elements();

            const createChangeHandler = () => {
                return ({ complete }: StripeChangeEvent) => {
                    requestAnimationFrame(() => {
                        setCvcValid(complete);
                    });
                };
            };
            const newCvcElement = elements.create('cardCvc', {
                style: inputStyles,
            });
            newCvcElement.mount(cardCvcRef);
            newCvcElement.on('change', createChangeHandler());
            setCvcElement(newCvcElement);
            setInitialized(true);
        }
    }, [
        stripe,
        initialized,
        cardNumberRef,
        cardCvcRef,
        cardExpiryRef,
        isNewCreditCard,
    ]);

    const handleSubmit = async (
        signIntoFirestore: (authToken: string) => Promise<void>,
        vetspireLocationId: string,
        displayPrecallOverlay: (show: boolean) => void,
        markAsDefault: boolean,
        promoCode?:
            | string
            | number
            | readonly string[]
            | File
            | readonly File[]
            | undefined,
    ) => {
        if (valid && stripe && !processing && cardElements) {
            // check if we closed since the customer started the form
            if (!(await isTelehealthAvailable())) {
                return;
            }

            setErrorState({
                ...initialCardState,
                processing: true,
            });

            // create a stripe-source for the new card
            const sourceIdResp = await createStripeSource(
                stripe,
                apolloClient,
                cardElements.cardNumber,
            );
            // create a payment-intent with the card-data
            const { data: paymentIntentData, errors: paymentIntentErrors } =
                await apolloClient.mutate<CreatePaymentIntentForEmailAddressResult>(
                    {
                        mutation: createPaymentIntentForEmailAddressMutation,
                        variables: {
                            email,
                            sourceId: sourceIdResp.id,
                            vetspireLocationId,
                            markAsDefault,
                            promoCode,
                            isTelehealth: true,
                        },
                        context: {
                            clientName: GraphQLClientNames.creditCards,
                        },
                    },
                );

            if (paymentIntentErrors || !paymentIntentData) {
                setStateToError(
                    intl.formatMessage({
                        id: 'errors.technicalError',
                    }),
                );
                return;
            }

            const paymentIntentResult =
                paymentIntentData.createPaymentIntentForEmailAddress;

            if (paymentIntentResult.error) {
                setStateToError(paymentIntentResult.error);
                return;
            }

            // confirm the payment because cvc was correct, sets status to "uncaptured"
            const stripeResult = await stripe.confirmCardPayment(
                paymentIntentResult.paymentIntent.clientSecret,
                {
                    payment_method: {
                        card: cardElements.cardNumber,
                    },
                    setup_future_usage: 'off_session',
                },
            );

            if (
                stripeResult.error?.payment_intent?.status ===
                'requires_capture'
            ) {
                // TODO Seemingly sometimes we do not need to set the status of
                // payments to uncaptured, because it has already been done.
                // find out why that happens and then change the code accordingly.

                console.warn(
                    'Payment was already set to "uncaptured". Continuing...',
                );
            } else if (
                stripeResult.error ||
                stripeResult.paymentIntent.status !== 'requires_capture'
            ) {
                setStateToError(
                    stripeResult.error?.message ||
                        intl.formatMessage({
                            id: 'errors.unknownError',
                        }),
                );
                return;
            }

            try {
                displayPrecallOverlay(true);
                await enterVideoCall(
                    clientContext,
                    signIntoFirestore,
                    paymentIntentData.createPaymentIntentForEmailAddress
                        .paymentIntent.id,
                );
            } catch (err) {
                setStateToError(
                    intl.formatMessage({
                        id: 'errors.technicalError',
                    }),
                );
                displayPrecallOverlay(false);
            }
        }
    };
    return {
        isNewCreditCard,
        setIsNewCreditCard,
        cardNumberRef,
        setCardNumberRef,
        cardExpiryRef,
        setCardExpiryRef,
        cardCvcRef,
        setCardCvcRef,
        cardElements,
        setCardElements,
        cvcValid,
        setCvcValid,
        cvcElement,
        setCvcElement,
        valid,
        setValid,
        setInitialized,
        processing,
        processingError,
        setErrorState,
        setStateToError,
        email,
        stripe,
        enterVideoCall,
        handleSubmit,
        cardDataValid: isNewCreditCard ? valid : cvcValid,
    };
}
