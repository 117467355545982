import { AttachmentInfos } from '@bondvet/types/telehealth';
import { asyncRetry } from 'core/lib/asyncRetry';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import * as React from 'react';

export type AttachmentImages =
    | Record<string, { url: string; thumbnailUrl?: string }>
    | undefined;

export default function useImageAttachments(
    attachments: Array<AttachmentInfos>,
): AttachmentImages {
    const storage = getStorage();
    const [imageUrls, setImageUrls] = React.useState<AttachmentImages>({});

    React.useEffect(() => {
        async function fetchUrls(attachmentInfo: AttachmentInfos[]) {
            const urlsAsArray = await Promise.all(
                attachmentInfo.map(async (att) => {
                    let thumbnailUrl = '';

                    if (att.thumbnailPath) {
                        // try to get the thumbnail multiple times until it's received.
                        // it's generated asynchronously and we don't know when exactly it's ready.
                        thumbnailUrl = await asyncRetry(
                            () =>
                                getDownloadURL(
                                    ref(storage, `/${att.thumbnailPath}`),
                                ),
                            10,
                        );
                    }

                    const url = await getDownloadURL(
                        ref(storage, `/${att.filePath}`),
                    );

                    return [att.vetspireId, { url, thumbnailUrl }];
                }),
            );

            const urlsAsObject = Object.fromEntries(urlsAsArray);
            setImageUrls(urlsAsObject);
        }

        fetchUrls(attachments);
    }, [attachments, storage]);

    return imageUrls;
}
