import { gql } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import useTelehealthQuery from 'core/hooks/useTelehealthQuery';

const vetspireLocationIdQuery = gql`
    query Query {
        telehealthVetspireLocationId
    }
`;

type VetspireLocationIdResult = {
    telehealthVetspireLocationId: string;
};

export default function useVetspireLocationId(): QueryResult<VetspireLocationIdResult> {
    return useTelehealthQuery<VetspireLocationIdResult>(
        vetspireLocationIdQuery,
    );
}
