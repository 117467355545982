import { gql, useMutation } from '@apollo/client';
import { OperationResult } from '@bondvet/types';
import { GraphQLClientNames } from 'lib/types';
import * as React from 'react';
import { useBookingContext } from 'team/hooks/useBookingContext';

const cancelAppointmentMutation = gql`
    mutation cancelAppointment($appointmentId: ID!) {
        cancelAppointment(appointmentId: $appointmentId) {
            success
            error
        }
    }
`;

type CancelAppointmentResult = {
    cancelAppointment: OperationResult;
};

type CancelAppointment = {
    cancelAppointment: (
        appointmentId: string | null | undefined,
    ) => Promise<boolean>;
};

export default function useCancelAppointment(): CancelAppointment {
    const [callCancelAppointment] = useMutation<CancelAppointmentResult>(
        cancelAppointmentMutation,
    );

    const { setConfirmedAppointment } = useBookingContext();

    const cancelAppointment = React.useCallback(
        async (appointmentId: string | null | undefined) => {
            if (!appointmentId) return false;

            const result = await callCancelAppointment({
                variables: { appointmentId },
                context: {
                    clientName: GraphQLClientNames.bondvet,
                },
            });
            const successful = result?.data?.cancelAppointment.success ?? false;

            if (successful) {
                setConfirmedAppointment({
                    dateTime: null,
                    location: null,
                    id: null,
                });
            }

            return successful;
        },
        [callCancelAppointment, setConfirmedAppointment],
    );

    return { cancelAppointment };
}
