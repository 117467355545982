import * as React from 'react';
import { ChatMessage } from 'lib/types';
import {
    collection,
    getFirestore,
    orderBy,
    query,
    where,
    onSnapshot,
} from 'firebase/firestore';

type ChatContextType = {
    messages: Array<ChatMessage>;
    unreadMessages: boolean;
    setChatIsOpen: (isOpen: boolean) => void;
};

export const ChatContext = React.createContext<ChatContextType>({
    messages: [],
    unreadMessages: false,
    setChatIsOpen: () => {},
});

type UseChatContextHandlerProps = {
    sessionId: string;
};

export function useChatContextHandler({
    sessionId,
}: UseChatContextHandlerProps): ChatContextType {
    const [messages, setMessages] = React.useState<ChatMessage[]>([]);
    const [numberOfReadMessages, setNumberOfReadMessages] = React.useState(0);
    const [unreadMessages, setUnreadMessages] = React.useState(false);
    const [chatIsOpen, setChatIsOpen] = React.useState(false);

    React.useEffect(() => {
        const db = getFirestore();
        const chatQuery = query(
            collection(db, 'telehealth.chatMessages'),
            where('sessionId', '==', sessionId),
            orderBy('createdAt'),
        );
        return onSnapshot(chatQuery, (snapshot) => {
            const newChatMessages: ChatMessage[] = [];
            snapshot.forEach((doc) =>
                newChatMessages.push(doc.data() as ChatMessage),
            );

            setMessages(newChatMessages);
            if (chatIsOpen) {
                setNumberOfReadMessages(newChatMessages.length);
            } else if (newChatMessages.length > numberOfReadMessages) {
                setUnreadMessages(true);
            }
        });
    }, [chatIsOpen, numberOfReadMessages, sessionId]);

    React.useEffect(() => {
        if (chatIsOpen) {
            setUnreadMessages(false);
        }
    }, [chatIsOpen]);

    return { messages, setChatIsOpen, unreadMessages };
}

export function useChatContext(): ChatContextType {
    return React.useContext<ChatContextType>(ChatContext);
}
