import {
    BookingSync,
    TELEHEALTH_BOOKINGSYNC_COLLECTION_NAME,
} from '@bondvet/types/telehealth';
import useCallContext from 'client/hooks/useCallContext';
import { doc, getFirestore, DocumentReference } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { IdentifiedFirestoreTelehealthSession } from 'team/hooks/useCaseQueue';

type UseBookingUtils = {
    sessionRef: DocumentReference<BookingSync> | undefined;
    isProvider: boolean;
    sessionId: string;
};

export default function useBookingUtils(): UseBookingUtils {
    const db = getFirestore();
    const {
        sessionIds: { sessionId: clientSessionId },
    } = useCallContext();
    const { id: providerSessionId } =
        useParams<Pick<IdentifiedFirestoreTelehealthSession, 'id'>>();

    const sessionId = providerSessionId || clientSessionId;
    let sessionRef: DocumentReference<BookingSync> | undefined;
    if (sessionId) {
        sessionRef = doc(
            db,
            TELEHEALTH_BOOKINGSYNC_COLLECTION_NAME,
            sessionId,
        ) as DocumentReference<BookingSync>;
    }
    const isProvider = Boolean(providerSessionId);

    return { sessionRef, isProvider, sessionId };
}
