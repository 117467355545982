import * as React from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.inline.svg';
import DocumentPreview from './DocumentPreview';
import styles from './Preview.module.scss';

interface PreviewProps {
    file: File;
    onDelete: (file: File) => void;
}

export default function Preview({
    file,
    onDelete,
}: PreviewProps): React.ReactElement | null {
    const [previewImage, setPreviewImage] = React.useState<string | null>(null);

    const handleDelete = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();
            onDelete(file);
        },
        [onDelete, file],
    );

    React.useEffect(() => {
        let reader: FileReader | undefined;

        if (/^image\//.test(file.type)) {
            if (typeof window.FileReader === 'undefined') {
                console.warn('cannot preview file');
                setPreviewImage(null);
            } else {
                reader = new FileReader();

                reader.onload = (event: ProgressEvent<FileReader>) => {
                    setPreviewImage(event.target?.result as string);
                };

                reader.readAsDataURL(file);
            }
        }

        return () => {
            if (reader !== undefined) {
                reader.abort();
            }
        };
    }, [file]);

    return (
        <div className={styles.preview}>
            <button
                type="button"
                onClick={handleDelete}
                className={styles['preview-delete']}
            >
                <DeleteIcon />
            </button>
            {previewImage && <img src={previewImage} alt="preview" />}
            {!previewImage && <DocumentPreview title={file.name} />}
        </div>
    );
}
