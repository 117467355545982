import { gql } from '@apollo/client';
import { ClientFirestoreSessionIds } from '@bondvet/types/telehealth';
import * as React from 'react';
import Loading from 'core/components/Loading';
import { useIntl } from 'react-intl';
import { useParams, Link } from 'react-router-dom';
import useTelehealthQuery from 'core/hooks/useTelehealthQuery';
import { Step } from 'client/lib/types';
import useFirebaseContext from 'core/hooks/firebase/useFirebaseContext';
import classNames from 'classnames';
import useCallContext from '../../hooks/useCallContext/useCallContext';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import NextButton from '../NextButton';
import styles from './Rejoin.module.scss';

const telehelealthSessionsQuery = gql`
    query TelehealthSession($rejoinId: String!) {
        telehealthSession(rejoinId: $rejoinId) {
            authToken
            twilioAuthToken
            sessionId
        }
    }
`;

type TelehealthSessionsQuery = {
    telehealthSession: ClientFirestoreSessionIds;
};

function Rejoin() {
    const { setSessionIds } = useCallContext();
    const { setStep } = useClientContext();
    const { signIntoFirestore } = useFirebaseContext();
    const intl = useIntl();
    const { rejoinId } = useParams<{ rejoinId: string }>();
    const { data, loading, error } =
        useTelehealthQuery<TelehealthSessionsQuery>(telehelealthSessionsQuery, {
            variables: { rejoinId },
        });

    const enterCall = () => {
        if (data) {
            signIntoFirestore(data.telehealthSession.authToken);
            setSessionIds(data.telehealthSession);
            setStep(Step.call, rejoinId);
        }
    };
    return (
        <>
            {data && (
                <>
                    <span
                        className={classNames(styles.centered, styles.content)}
                    >
                        {intl.formatMessage({
                            id: 'rejoin.ready',
                        })}
                    </span>
                    <NextButton
                        label={intl.formatMessage({
                            id: 'rejoin.button',
                        })}
                        type="submit"
                        onClick={enterCall}
                        variant="fixed"
                    />
                </>
            )}
            {loading && <Loading classes={{ root: styles.centered }} />}
            {error && (
                <div className={classNames(styles.centered, styles.content)}>
                    <span>
                        {intl.formatMessage({
                            id: 'rejoin.notFound',
                        })}
                    </span>
                    <Link to="/">
                        {intl.formatMessage({
                            id: 'rejoin.back',
                        })}
                    </Link>
                </div>
            )}
        </>
    );
}

export default Rejoin;
