import * as React from 'react';
import { CallContextType } from '../../lib/types';
import { initialCallContextState } from './index';

const CallContext = React.createContext<CallContextType>({
    setChatOpen: () => {},
    setBookingOpen: () => {},
    setSessionIds: () => {},
    enterVideoCall: async () => {},
    endCall: () => {},
    ...initialCallContextState,
});

export default CallContext;
