import * as React from 'react';
import { CallContextData } from '../../lib/types';

export default function useSetter<T = string>(
    key: keyof CallContextData,
    setValue: React.Dispatch<React.SetStateAction<CallContextData>>,
): (newValue: T) => void {
    return React.useCallback(
        (newValue: T) => {
            setValue((prev) => ({
                ...prev,
                [key]: newValue,
            }));
        },
        [key, setValue],
    );
}
