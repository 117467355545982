import classnames from 'classnames';
import Checkbox from 'core/components/Checkbox';
import Select from 'core/components/Select';
import useFirebaseContext from 'core/hooks/firebase/useFirebaseContext';
import * as React from 'react';
import { useIntl } from 'react-intl';
import TextField from 'core/components/TextField';
import styles from 'client/components/PaymentPage/PaymentPage.module.scss';
import { FormFields } from './FormFieldsTypes';
import useClientKnownCreditCardForm from './useCreditCardForm/useClientKnownCreditCardForm';
import useScrollWindow from './useScrollWindow';
import usePromoCode from './usePromoCode';
import StripeInput from '../StripeInput/StripeInput';
import NextButton from '../NextButton';
import useVetspireLocationId from '../../hooks/useVetspireLocationId';
import useClientContext from '../../hooks/useClientContext/useClientContext';

const ClientKnownFormFields = ({
    displayPrecallOverlay,
    setTotalPrice,
    telehealthPrice,
}: FormFields) => {
    const [selectedIsDefault, setSelectedIsDefault] = React.useState(true);
    const [cardToDefault, setCardToDefault] = React.useState(false);
    const { creditCards } = useClientContext();
    const {
        cardNumberRef,
        cardExpiryRef,
        cardCvcRef,
        cardDataValid,
        handleSubmit,
        handleSubmitNewCard,
        setInitialized,
        setSourceIdToUse,
        setIsNewCreditCard,
        isNewCreditCard,
        processing,
        processingError,
    } = useClientKnownCreditCardForm();
    const {
        handlePromoCodeChange,
        promoCode,
        promoCodeAmount,
        promoCodeError,
    } = usePromoCode();
    const { signIntoFirestore } = useFirebaseContext();

    React.useEffect(() => {
        if (promoCodeAmount) {
            setTotalPrice(telehealthPrice - promoCodeAmount);
        }
        if (promoCodeError) {
            setTotalPrice(telehealthPrice);
        }
    }, [promoCodeAmount, telehealthPrice, setTotalPrice, promoCodeError]);

    const { loading: vetspireIdLoading, data: vetspireLocationIdData } =
        useVetspireLocationId();

    const intl = useIntl();

    useScrollWindow({ trigger: cardDataValid });

    // this function should also handle the reinitialization of the form
    const handleChangePaymentMethod = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setInitialized(false);

        const newCard = event.target.value === 'new';
        if (!newCard) {
            const selectedCard = creditCards.find(
                (card) => card.last4 === event.target.value,
            );

            if (!selectedCard) {
                throw new Error('Could not find card');
            }
            setSourceIdToUse(selectedCard.id);

            setSelectedIsDefault(selectedCard.isDefault);
        }
        setIsNewCreditCard(newCard);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!vetspireLocationIdData?.telehealthVetspireLocationId)
            throw new Error(
                'Invalid State, vetspire-locationId must be defined.',
            );

        if (isNewCreditCard) {
            handleSubmitNewCard(
                signIntoFirestore,
                vetspireLocationIdData.telehealthVetspireLocationId,
                displayPrecallOverlay,
                cardToDefault,
                promoCode,
            );
        } else {
            handleSubmit(
                signIntoFirestore,
                vetspireLocationIdData.telehealthVetspireLocationId,
                displayPrecallOverlay,
                cardToDefault,
                promoCode,
            );
        }
    };

    interface OptionProps {
        label: string;
        value: string;
        default: boolean;
        cardBrand?: string;
    }

    const options = React.useMemo((): OptionProps[] => {
        const initialOptions = creditCards.map((card) => ({
            label: ` ●●●● ●●●● ●●●● ${card.last4}`,
            value: card.last4,
            default: card.isDefault,
            cardBrand: card.brand.replace(/\s/g, '').toUpperCase(),
        }));
        const newPaymentOption = {
            label: '+ New Payment Method',
            value: 'new',
            default: false,
        };
        return [...initialOptions, newPaymentOption];
    }, [creditCards]);

    const selectedOption = React.useMemo(() => {
        if (options.length + creditCards.length > 0) {
            const defaultOptions = options.filter((option) => option.default);

            if (defaultOptions.length > 0) {
                return defaultOptions[0].value;
            }

            return options[0].value;
        }

        return null;
    }, [options, creditCards.length]);

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles['grid-container']}>
                {selectedOption !== null && (
                    <Select
                        id="paymentMethod"
                        value={selectedOption}
                        label={intl.formatMessage({
                            id: 'paymentPage.form.paymentMethod.label',
                        })}
                        onChange={handleChangePaymentMethod}
                        required
                        // options need key, value, and label
                        options={options}
                        classes={{ root: styles['full-width'] }}
                    />
                )}

                {isNewCreditCard && (
                    <>
                        <StripeInput
                            id="card-number"
                            label={intl.formatMessage({
                                id: 'paymentPage.form.cardNumber.label',
                            })}
                            ref={cardNumberRef}
                            classes={{ root: styles['full-width'] }}
                        />

                        <StripeInput
                            id="expiration-date"
                            label={intl.formatMessage({
                                id: 'paymentPage.form.expiration.label',
                            })}
                            ref={cardExpiryRef}
                            classes={{ root: styles['half-width'] }}
                        />
                    </>
                )}

                <StripeInput
                    id="cvc"
                    label={intl.formatMessage({
                        id: 'paymentPage.form.CVC.label',
                    })}
                    ref={cardCvcRef}
                    classes={{ root: styles['half-width'] }}
                />
                {(!selectedIsDefault || isNewCreditCard) && (
                    <Checkbox
                        id="setDefault"
                        label={intl.formatMessage(
                            {
                                id: 'paymentPage.form.setDefault.label',
                            },
                            {
                                disclaimerLink: (
                                    <a
                                        href="https://bondvet.com/telehealth-disclaimer"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {intl.formatMessage({
                                            id: 'reasonForm.form.disclaimer.link',
                                        })}
                                    </a>
                                ),
                            },
                        )}
                        checked={cardToDefault}
                        onChange={setCardToDefault}
                        classes={{
                            root: classnames(
                                styles['full-width'],
                                styles.checkbox,
                            ),
                        }}
                    />
                )}
                <TextField
                    id="promoCode"
                    data-cy="promoCode"
                    name="promoCode"
                    label={intl.formatMessage({
                        id: 'paymentPage.form.promoCode.label',
                    })}
                    classes={{
                        root: styles['full-width'],
                    }}
                    inputProps={{ autoComplete: 'one-time-code' }}
                    onChange={handlePromoCodeChange}
                    value={promoCode}
                    error={!!promoCodeError}
                    helperText={
                        promoCodeError ||
                        (promoCodeAmount &&
                            intl.formatMessage(
                                {
                                    id: 'paymentPage.form.promoCode.amount',
                                },
                                { amount: promoCodeAmount / 100 },
                            ))
                    }
                />

                {processingError && (
                    <p className={styles['error-text']}>{processingError}</p>
                )}
            </div>
            <NextButton
                label={intl.formatMessage({
                    id: processing
                        ? 'paymentPage.nextButton.loading'
                        : 'paymentPage.nextButton.label',
                })}
                type="submit"
                disabled={
                    processing ||
                    vetspireIdLoading ||
                    !cardDataValid ||
                    !!promoCodeError
                }
                loading={processing || vetspireIdLoading}
                variant="fixed"
                className={styles['next-button']}
            />
        </form>
    );
};

export default ClientKnownFormFields;
