import classNames from 'classnames';
import * as React from 'react';
import { FormattedChatMessage } from 'lib/types';
import InitialsCircle from './InitialsCircle';
import styles from './Message.module.scss';
import MessageAttachments from './MessageAttachments';

export type TextMessageProps = FormattedChatMessage & {
    userIsSender: boolean;
    hideSender?: boolean;
};

export default function Message({
    attachments = [],
    sentAt,
    message = '',
    senderName,
    senderImage = '',
    userIsSender,
    hideSender = false,
}: TextMessageProps): React.ReactElement {
    return (
        <div className={styles.root} key={sentAt}>
            {!hideSender && (
                <div
                    className={classNames(styles['sender-container'], {
                        [styles['user-is-sender']]: userIsSender,
                    })}
                >
                    {senderImage ? (
                        <img
                            src={senderImage}
                            alt="chat-sender"
                            className={styles['sender-image']}
                        />
                    ) : (
                        <InitialsCircle name={senderName} />
                    )}
                    <div className={styles['sender-info']}>
                        <strong>{senderName}</strong>
                        <p>{sentAt}</p>
                    </div>
                </div>
            )}
            <div
                className={classNames(styles['message-content'], {
                    [styles['user-is-sender']]: userIsSender,
                })}
            >
                {message && (
                    <span className={styles['message-text']}>{message}</span>
                )}
                {attachments.length > 0 && (
                    <MessageAttachments attachments={attachments} />
                )}
            </div>
        </div>
    );
}
