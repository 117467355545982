import * as React from 'react';
import Dragable from 'react-draggable';
import { AudioTrack, VideoTrack } from 'twilio-video';
import styles from '../VideoStreams.module.scss';

interface VideoParticipantProps {
    videoTrack: VideoTrack | null;
    audioTrack: AudioTrack | null;
    classes?: typeof styles;
    draggable?: boolean;
}

const VideoParticipant = ({
    classes,
    videoTrack,
    audioTrack,
    draggable = false,
}: VideoParticipantProps) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const audioRef = React.useRef<HTMLAudioElement>(null);

    React.useEffect(() => {
        if (videoTrack && videoRef.current) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
        return undefined;
    }, [videoRef, videoTrack]);

    React.useEffect(() => {
        if (audioTrack && audioRef.current) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
        return undefined;
    }, [audioRef, audioTrack]);

    return (
        <>
            <Dragable disabled={!draggable} bounds="parent">
                <div className={classes?.root}>
                    {videoTrack && (
                        <video
                            ref={videoRef}
                            muted
                            autoPlay
                            playsInline
                            className={classes?.video}
                        />
                    )}
                    {audioTrack && <audio ref={audioRef} autoPlay />}
                </div>
            </Dragable>
        </>
    );
};

export default VideoParticipant;
