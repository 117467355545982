import * as React from 'react';
import { useIntl } from 'react-intl';
import styles from './ResizableTextArea.module.scss';

export interface ResizableTextAreaProps {
    minRows?: number;
    maxRows?: number;
    value: string;
    setValue: (value: string) => void;
    onSubmit: () => void;
    rows: number;
    setRows: (value: number) => void;
}

export default function ResizableTextArea({
    minRows = 1,
    maxRows = 5,
    value = '',
    setValue,
    onSubmit,
    rows,
    setRows,
}: ResizableTextAreaProps): React.ReactElement {
    const intl = useIntl();
    const textArea = React.useRef<HTMLTextAreaElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const eventRef = event; // Eslint-rule "no-param-reassign" workaround

        const previousRows = eventRef.target.rows;
        eventRef.target.rows = minRows;

        const currentRows =
            Math.floor(
                (eventRef.target.scrollHeight - 4) /
                    (textArea.current?.clientHeight || 1),
            ) + 1;

        if (currentRows === previousRows) {
            eventRef.target.rows = currentRows;
        }
        if (currentRows >= maxRows) {
            eventRef.target.rows = maxRows;
            eventRef.target.scrollTop = eventRef.target.scrollHeight;
        }

        setRows(currentRows < maxRows ? currentRows : maxRows);
        setValue(eventRef.target.value);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.shiftKey && e.key === 'Enter') {
            return; // don't send the message (produces linebreak instead)
        }
        if (e.key === 'Enter' && onSubmit) {
            e.preventDefault();
            onSubmit();
        }
    };

    return (
        <textarea
            rows={rows}
            value={value}
            data-cy="chat"
            placeholder={intl.formatMessage({ id: 'callPage.chat.type' })}
            className={styles.root}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            ref={textArea}
        />
    );
}
