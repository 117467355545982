import * as React from 'react';
import classNames from 'classnames';
import styles from './StripeInput.module.scss';

export type StripeInputProps = {
    classes?: Partial<typeof styles>;
    id: string;
    label: React.ReactNode;
};

function StripeInput(
    { classes, id, label }: StripeInputProps,
    ref?: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
    return (
        <div className={classNames(classes?.root)}>
            <label
                className={classNames(classes?.label, styles.label)}
                htmlFor={id}
            >
                {label}
            </label>
            <div className={classNames(styles.root)}>
                <div
                    id={id}
                    data-cy={id}
                    ref={ref}
                    className={styles.control}
                />
            </div>
        </div>
    );
}

export default React.forwardRef(StripeInput);
