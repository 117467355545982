import * as React from 'react';
import { useIntl } from 'react-intl';

const formatOutput = (vals: [number, number, number, number]) => {
    // remove leading units that are 0 and fill single-digits to double-digits with leading 0
    for (let i = 0; i < vals.length; i += 1)
        if (vals[i] !== 0) {
            return vals.slice(i).map((val) => val.toString().padStart(2, '0'));
        }

    return [];
};

const getReturnValues = (
    countdown: number,
): [number, number, number, number] => {
    const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

const useCountdown = (targetDate: Date): string[] => {
    const countdownDate = new Date(targetDate).getTime();

    const [countdown, setCountdown] = React.useState(
        countdownDate - new Date().getTime(),
    );

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(countdownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countdownDate]);

    const allReturnValues = getReturnValues(countdown);
    return formatOutput(allReturnValues);
};

interface BlockedOffTextProps {
    blockedOffUntil: Date;
}

function BlockedOffText({ blockedOffUntil }: BlockedOffTextProps) {
    const intl = useIntl();
    const until = useCountdown(blockedOffUntil);

    React.useEffect(() => {
        // reload page on blockoff-end
        if (Number(until[until.length - 1]) <= 0) window.location.reload();
    }, [until]);

    return (
        <div>
            <h2>
                {intl.formatMessage({
                    id: 'availability.blockOff',
                })}
            </h2>
            <h3>{until.join(':')}</h3>
        </div>
    );
}

export default BlockedOffText;
