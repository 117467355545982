import * as React from 'react';
import { useIntl } from 'react-intl';
import { TabPanel, TabsList, Tabs as MuiTabs, Tab } from '@mui/base';
import { Tabs } from 'core/hooks/useBookingSender';
import { useBookingContext } from 'team/hooks/useBookingContext';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import useLocations from 'core/hooks/useLocations';
import useBookingSyncSchedule from 'core/hooks/useBookingSyncSchedule';
import Select from '../Select';
import styles from './Booking.module.scss';
import BookingMap from './BookingMap';
import BookingNext from './BookingNext';
import BookingSchedule from './BookingSchedule';
import BookAppointment from '../BookAppointment';
import Button from '../Button';

function Booking(
    _props: unknown,
    ref?: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
    const intl = useIntl();
    const locations = useLocations();
    const {
        isProvider,
        activeBookingTab,
        handleBookingTabChange,
        appointment,
        setSync,
    } = useBookingContext();
    const [isSyncActive, setIsSyncActive] = React.useState(false);
    const { scheduleLocation, setScheduleLocation, date, setDate } =
        useBookingSyncSchedule();

    function handleSync(newValue: boolean) {
        setSync(newValue);
        setIsSyncActive(newValue);
    }

    return (
        <div className={styles.root} ref={ref}>
            {!isSyncActive && isProvider && (
                <div className={styles.curtain}>
                    <Button
                        as="button"
                        label={intl.formatMessage({
                            id: 'booking.startSharing',
                        })}
                        onClick={() => handleSync(true)}
                    />
                </div>
            )}

            {!isProvider && (
                <div className={styles.blocker} aria-hidden="true" />
            )}
            <div className={styles.navigation}>
                <div className={styles.location}>
                    {activeBookingTab === Tabs.schedule && (
                        <Select
                            id="location-select"
                            placeholder={
                                locations.length > 0
                                    ? intl.formatMessage({
                                          id: 'booking.location',
                                      })
                                    : intl.formatMessage({ id: 'app.loading' })
                            }
                            options={locations.map((loc) => ({
                                value: loc._id,
                                label: loc.name ?? '',
                            }))}
                            classes={{ root: styles['location-select'] }}
                            value={scheduleLocation}
                            onChange={(event) =>
                                setScheduleLocation(event.target.value)
                            }
                            disabled={!isProvider}
                        />
                    )}
                </div>
                {isSyncActive && isProvider && (
                    <Button
                        className={styles['stop-sharing']}
                        label="Stop sharing"
                        as="button"
                        onClick={() => handleSync(false)}
                    />
                )}
            </div>
            <MuiTabs
                value={activeBookingTab}
                className={styles.tabs}
                onChange={(_, newValue) =>
                    handleBookingTabChange(newValue as number)
                }
            >
                <TabsList className={styles['navigation-buttons']}>
                    <Tab value={Tabs.schedule}>
                        {intl.formatMessage({
                            id: 'booking.navigation.schedule',
                        })}
                    </Tab>
                    <Tab value={Tabs.next}>
                        {intl.formatMessage({
                            id: 'booking.navigation.nextAvailable',
                        })}
                    </Tab>
                    <Tab value={Tabs.map}>
                        {intl.formatMessage({
                            id: 'booking.navigation.map',
                        })}
                    </Tab>
                </TabsList>
                <TabPanel className={styles.panel} value={Tabs.schedule}>
                    <BookingSchedule
                        date={date}
                        scheduleLocation={scheduleLocation}
                        setDate={setDate}
                    />
                </TabPanel>
                <TabPanel className={styles.panel} value={Tabs.next}>
                    <BookingNext />
                </TabPanel>
                <TabPanel className={styles.panel} value={Tabs.map}>
                    <BookingMap setScheduleLocation={setScheduleLocation} />
                </TabPanel>
            </MuiTabs>
            {appointment?.dateTime && (
                <TrapFocus open>
                    <div tabIndex={-1}>
                        <BookAppointment />
                    </div>
                </TrapFocus>
            )}
        </div>
    );
}

export default React.forwardRef(Booking);
