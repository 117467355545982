import {
    useQuery,
    type DocumentNode,
    type QueryHookOptions,
    type OperationVariables,
    type TypedDocumentNode,
} from '@apollo/client';
import { GraphQLClientNames } from '../../lib/types';

export default function useTelehealthQuery<
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
): ReturnType<typeof useQuery<TData, TVariables>> {
    return useQuery<TData, TVariables>(query, {
        ...options,
        context: {
            ...options?.context,
            clientName: GraphQLClientNames.telehealth,
        },
    });
}
