import { gql } from '@apollo/client';
import classNames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';
import TextField from 'core/components/TextField';
import useIsMobile from 'core/hooks/useIsMobile';
import useTelehealthMutation from 'core/hooks/useTelehealthMutation';
import { OperationResult } from '@bondvet/types';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import { Step } from '../../lib/types';
import NextButton from '../NextButton';
import PageHeader from '../PageHeader';
import styles from './VisitSummaryForm.module.scss';

const MOBILE_AMOUNT_OF_ROWS = 8;
const DESKTOP_AMOUNT_OF_ROWS = 16;

const RateVisitMutation = gql`
    mutation RateVisit($rating: Rating!) {
        rateVisit(rating: $rating) {
            success
            error
        }
    }
`;

type RateVisitResult = {
    rateVisit: OperationResult;
};

export default function VisitSummaryForm() {
    const intl = useIntl();
    const isMobile = useIsMobile();
    const [score, setScore] = React.useState<number | null>(null);
    const [notes, setNotes] = React.useState<string>('');
    const { setStep } = useClientContext();

    const formValid = score !== null;

    const [rateVisit, { loading, error }] =
        useTelehealthMutation<RateVisitResult>(RateVisitMutation);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        rateVisit({
            variables: {
                rating: {
                    score,
                    notes,
                },
            },
        }).then((res) => {
            if (res.data?.rateVisit.success) {
                setStep(Step.end);
            }
        });
    };

    const handleChangeDescription = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setNotes(value);
        },
        [setNotes],
    );

    return (
        <form onSubmit={handleSubmit} className={styles.root}>
            <PageHeader
                componentType="textOnly"
                label={intl.formatMessage({ id: 'visitSummaryForm.headline' })}
            />
            <div className={styles['form-fields']}>
                <p>
                    {intl.formatMessage({
                        id: 'visitSummaryForm.rating.description',
                    })}
                </p>
                <div>
                    <p className={styles['please-rate-text']}>
                        {intl.formatMessage({
                            id: 'visitSummaryForm.rating.pleaseRate',
                        })}
                    </p>
                    <p className={styles['please-rate-scale']}>
                        {intl.formatMessage({
                            id: 'visitSummaryForm.rating.scale',
                        })}
                    </p>
                    <div className={styles['rating-buttons']}>
                        {Array.from(Array(11).keys()).map((rating) => (
                            <button
                                type="button"
                                className={classNames({
                                    [styles.selected]: rating === score,
                                })}
                                onClick={() => setScore(rating)}
                                key={rating}
                            >
                                {rating}
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <TextField
                        id="notes"
                        value={notes}
                        label={intl.formatMessage({
                            id: 'visitSummaryForm.description.description',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'visitSummaryForm.description.label',
                        })}
                        required
                        classes={{ root: styles.control }}
                        multiline
                        rows={
                            isMobile
                                ? MOBILE_AMOUNT_OF_ROWS
                                : DESKTOP_AMOUNT_OF_ROWS
                        }
                        onChange={handleChangeDescription}
                    />
                </div>
                {error && (
                    <p className={styles['error-text']}>
                        {intl.formatMessage({
                            id: 'errors.technicalError',
                        })}
                    </p>
                )}
                {!isMobile && (
                    <NextButton
                        disabled={!formValid}
                        loading={loading}
                        variant="grid"
                    >
                        {intl.formatMessage({ id: 'visitSummaryForm.confirm' })}
                    </NextButton>
                )}
            </div>
            {isMobile && (
                <NextButton
                    disabled={!formValid}
                    loading={loading}
                    variant="fixed"
                >
                    {intl.formatMessage({ id: 'visitSummaryForm.confirm' })}
                </NextButton>
            )}
        </form>
    );
}
