import * as React from 'react';
import markerIcon from 'assets/marker.svg';
import { Location } from '@bondvet/types/telehealth';

const ICON_SIZE = 40;

type MapMarkerProps = {
    map: google.maps.Map | undefined;
    location: Location;
    onClick: () => void;
};

function MapMarker({ map, location, onClick }: MapMarkerProps) {
    React.useEffect(() => {
        let marker: google.maps.Marker;
        let listener: google.maps.MapsEventListener;

        if (map) {
            if (location.coordinates) {
                marker = new google.maps.Marker({
                    position: {
                        lat: location.coordinates?.latitude,
                        lng: location.coordinates?.longitude,
                    },
                    map,
                    title: location.name,
                    icon: {
                        url: markerIcon,
                        anchor: new google.maps.Point(ICON_SIZE / 2, ICON_SIZE),
                        scaledSize: new google.maps.Size(ICON_SIZE, ICON_SIZE),
                    },
                });

                listener = marker.addListener('click', onClick);
            }
        }

        return () => {
            if (marker) {
                google.maps.event.removeListener(listener);
                marker.setMap(null);
            }
        };
    }, [location, map, onClick]);

    return <div />;
}

export default MapMarker;
