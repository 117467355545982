import * as React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { useIntl } from 'react-intl';
import useLocations from 'core/hooks/useLocations';
import useBookingSyncMap from 'core/hooks/useBookingSyncMap';
import MapMarker from 'team/components/MapMarker';
import { Tabs } from 'core/hooks/useBookingSender';
import { useBookingContext } from 'team/hooks/useBookingContext';
import styles from './Booking.module.scss';

type BookingMapProps = {
    setScheduleLocation: React.Dispatch<React.SetStateAction<string>>;
};

function BookingMap({ setScheduleLocation }: BookingMapProps) {
    const intl = useIntl();
    const { handleBookingTabChange } = useBookingContext();

    const loadingWrapper = React.useCallback(
        (status: Status): React.ReactElement => {
            if (status === Status.LOADING)
                return <p>{intl.formatMessage({ id: 'app.loading' })}</p>;
            if (status === Status.FAILURE)
                return <p>{intl.formatMessage({ id: 'map.error' })}</p>;
            return <></>;
        },
        [intl],
    );

    function Map() {
        const ref = React.useRef<HTMLDivElement>(null);
        const { map } = useBookingSyncMap({ ref });
        const locations = useLocations();

        function handleClick(locationId: string) {
            setScheduleLocation(locationId);
            handleBookingTabChange(Tabs.schedule);
        }

        return (
            <div ref={ref} id="map" className={styles.map}>
                {locations.map((location) => (
                    <MapMarker
                        key={location._id}
                        map={map}
                        location={location}
                        onClick={() => handleClick(location._id)}
                    />
                ))}
            </div>
        );
    }

    return (
        <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
            render={loadingWrapper}
        >
            <Map />
        </Wrapper>
    );
}

export default BookingMap;
