import Checkbox from 'core/components/Checkbox';
import FileInput from 'core/components/Files';
import Select from 'core/components/Select';
import TextField from 'core/components/TextField';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useHandleAttachments from 'core/hooks/useHandleAttachments';
import classNames from 'classnames';
import useIsMobile from 'core/hooks/useIsMobile';
import compareObjects from 'utils/compareObjects';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import { Step } from '../../lib/types';
import PageHeader from '../PageHeader';
import NextButton from '../NextButton';
import styles from './ReasonForm.module.scss';

function ReasonForm(): React.ReactElement {
    const intl = useIntl();
    const isMobile = useIsMobile();
    const {
        pet,
        reasons,
        setReasonId,
        reasonId,
        notes,
        userFiles,
        addUserFiles,
        removeUserFile,
        setNotes,
        disclaimerAcknowledged,
        setDisclaimerAcknowledged,
        setStep,
    } = useClientContext();

    const userFilesUnderSizeLimit =
        userFiles.reduce((acc, file) => {
            return acc + file.size;
        }, 0) /
            1024 ** 2 <
        9;

    const valid =
        !!reasonId &&
        !!notes &&
        disclaimerAcknowledged &&
        userFilesUnderSizeLimit;

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (valid) {
                setStep(Step.payment);
            }
        },
        [valid, setStep],
    );

    const handleChangeReason = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setReasonId(value);
        },
        [setReasonId],
    );

    const handleChangeNotes = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setNotes(value);
        },
        [setNotes],
    );

    const options = React.useMemo(
        () =>
            reasons
                .map(({ _id, name }) => ({
                    value: _id,
                    label: name,
                }))
                .sort((a, b) => compareObjects(a, b, 'label')),
        [reasons],
    );

    const handleChangeFiles = useHandleAttachments(addUserFiles);

    return (
        <>
            <PageHeader
                componentType="navigation"
                previousStep={Step.intake}
                label={null}
            />
            <form
                onSubmit={handleSubmit}
                className={styles.root}
                id="reason-form"
            >
                <div className={styles['grid-container']}>
                    <Select
                        id="reasonId"
                        data-cy="reasonId"
                        label={intl.formatMessage(
                            { id: 'reasonForm.form.reason.label' },
                            { pet: pet.name },
                        )}
                        placeholder={intl.formatMessage({
                            id: 'reasonForm.form.reason.placeholder',
                        })}
                        required
                        options={options}
                        onChange={handleChangeReason}
                        value={reasonId}
                        classes={{ root: styles.control }}
                    />
                    <TextField
                        id="notes"
                        data-cy="notes"
                        value={notes}
                        label={intl.formatMessage({
                            id: 'reasonForm.form.notes.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'reasonForm.form.notes.placeholder',
                        })}
                        required
                        classes={{ root: styles.control }}
                        multiline
                        rows={8}
                        onChange={handleChangeNotes}
                    />
                    <p
                        className={classNames(
                            styles.control,
                            styles['file-hint'],
                        )}
                    >
                        {intl.formatMessage({
                            id: 'reasonForm.form.userFile.fileHint',
                        })}
                    </p>
                    <FileInput
                        id="file"
                        name="file"
                        accept="image/*, video/*, .pdf"
                        classes={{ root: styles.control }}
                        placeholder={intl.formatMessage({
                            id: 'reasonForm.form.userFile.placeholder',
                        })}
                        value={userFiles}
                        onChange={handleChangeFiles}
                        onRemove={removeUserFile}
                    />
                    {!userFilesUnderSizeLimit && (
                        <p className={styles['error-text']}>
                            {intl.formatMessage({
                                id: 'reasonForm.form.userFile.error',
                            })}
                        </p>
                    )}
                    <Checkbox
                        required
                        id="disclaimer"
                        label={intl.formatMessage(
                            {
                                id: 'reasonForm.form.disclaimer.label',
                            },
                            {
                                disclaimerLink: (
                                    <a
                                        href="https://bondvet.com/telehealth-disclaimer"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {intl.formatMessage({
                                            id: 'reasonForm.form.disclaimer.link',
                                        })}
                                    </a>
                                ),
                            },
                        )}
                        checked={disclaimerAcknowledged}
                        onChange={setDisclaimerAcknowledged}
                        classes={{ root: styles.control }}
                    />
                    {!isMobile && (
                        <NextButton
                            disabled={!valid}
                            form="reason-form"
                            variant="grid"
                        />
                    )}
                </div>
            </form>
            {isMobile && (
                <NextButton
                    disabled={!valid}
                    form="reason-form"
                    variant="fixed"
                />
            )}
        </>
    );
}

export default ReasonForm;
