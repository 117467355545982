import * as React from 'react';
import {
    FirestoreTelehealthSession,
    SessionStatus,
} from '@bondvet/types/telehealth';

type UseProviderId = {
    document: FirestoreTelehealthSession | undefined;
};

export default function useProviderId({
    document,
}: UseProviderId): string | null {
    const providerId = React.useMemo(() => {
        if (document?.status) {
            if (
                [
                    SessionStatus.nurseCall,
                    SessionStatus.nurseQueue,
                    SessionStatus.nurseJoining,
                ].includes(document.status)
            ) {
                return document.nurseId;
            }
            return document.veterinarianId;
        }
        return null;
    }, [document?.status, document?.nurseId, document?.veterinarianId]);

    return providerId;
}
