import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
    Auth,
    connectAuthEmulator,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithCustomToken,
    signInWithPopup,
    signOut,
    User,
} from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import * as React from 'react';
import useStorageEmulator from '../useStorageEmulator';
import { FirebaseContextData, FirebaseContextType } from './context';

export default function useFirebaseContextHandler(): FirebaseContextType {
    const { initStorageEmulator } = useStorageEmulator();
    const [{ firebase, user, isAdmin, authLoading }, setValue] =
        React.useState<FirebaseContextData>({
            firebase: null,
            user: null,
            isAdmin: false,
            authLoading: true,
        });

    React.useEffect(() => {
        const app = initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
                process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        });
        setValue((prev) => ({
            ...prev,
            firebase: app,
        }));
    }, []);

    // Log errors
    React.useEffect(() => {
        const analytics = getAnalytics();
        function errorHandler(event: ErrorEvent) {
            logEvent(analytics, 'exception', {
                description: event.error,
            });
        }

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);

    React.useEffect(() => {
        function connectToEmulatorInDevelopment(auth: Auth) {
            if (process.env.REACT_APP_ENV === 'develop') {
                connectAuthEmulator(auth, 'http://localhost:9099', {
                    disableWarnings: true,
                });
                initStorageEmulator();
            }
        }
        const auth = getAuth();
        connectToEmulatorInDevelopment(auth);
        const unsubscribe = onAuthStateChanged(auth, (newUser: User | null) => {
            if (newUser) {
                setValue((prev) => ({
                    ...prev,
                    authLoading: false,
                    user: newUser,
                }));
            } else {
                setValue((prev) => ({
                    ...prev,
                    authLoading: false,
                    user: null,
                }));
            }
        });

        return () => unsubscribe();
    }, [initStorageEmulator]);

    React.useEffect(() => {
        if (user) {
            user.getIdTokenResult().then(({ claims }) => {
                setValue((prev) => ({
                    ...prev,
                    isAdmin: Boolean(claims.telehealthAdmin) || false,
                }));
            });
        }
    }, [user]);

    const signIntoFirestore = async (authToken: string) => {
        try {
            const auth = getAuth();
            await signInWithCustomToken(auth, authToken);
        } catch (error) {
            console.warn(error);
        }
    };

    async function signOutOfFirebase() {
        const auth = getAuth();
        await signOut(auth);
    }

    React.useEffect(() => {
        const firestore = getFirestore();

        if (process.env.REACT_APP_ENV === 'develop') {
            connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
    }, []);

    const signInWithGooglePopup = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        await signInWithPopup(auth, provider);
    };

    return {
        firebase,
        signIntoFirestore,
        signInWithGooglePopup,
        signOutOfFirebase,
        user,
        isAdmin,
        authLoading,
    };
}
