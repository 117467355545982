import { updateDoc, onSnapshot } from 'firebase/firestore';
import * as React from 'react';
import useBookingUtils from './useBookingUtils';

type UseBookingSyncSchedule = {
    date: Date;
    setDate: React.Dispatch<React.SetStateAction<Date>>;
    scheduleLocation: string;
    setScheduleLocation: React.Dispatch<React.SetStateAction<string>>;
};

export default function useBookingSyncSchedule(): UseBookingSyncSchedule {
    const { sessionRef, isProvider } = useBookingUtils();
    const [date, setDate] = React.useState(new Date());
    const [scheduleLocation, setScheduleLocation] = React.useState('');

    React.useEffect(() => {
        if (isProvider && sessionRef) {
            updateDoc(sessionRef, {
                selectedDate: date,
            });
        }
    }, [date, isProvider, sessionRef]);

    React.useEffect(() => {
        if (isProvider && sessionRef) {
            updateDoc(sessionRef, {
                scheduleLocation,
            });
        }
    }, [isProvider, scheduleLocation, sessionRef]);

    React.useEffect(() => {
        let unsubscribe = () => {};

        if (!isProvider && sessionRef) {
            unsubscribe = onSnapshot(sessionRef, (snapshot) => {
                const data = snapshot.data();
                if (data) {
                    if (
                        data.selectedDate &&
                        data.selectedDate.toDate().toUTCString() !==
                            date.toUTCString()
                    ) {
                        setDate(data.selectedDate.toDate());
                    }
                    if (data.scheduleLocation !== scheduleLocation) {
                        setScheduleLocation(data.scheduleLocation ?? '');
                    }
                }
            });
        }
        return unsubscribe;
    }, [date, isProvider, scheduleLocation, sessionRef]);

    return { date, setDate, scheduleLocation, setScheduleLocation };
}
