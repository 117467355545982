import styles from 'client/components/PaymentPage/PaymentPage.module.scss';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useFirebaseContext from 'core/hooks/firebase/useFirebaseContext';
import TextField from 'core/components/TextField';
import useVetspireLocationId from '../../hooks/useVetspireLocationId';
import NextButton from '../NextButton';
import StripeInput from '../StripeInput/StripeInput';
import { FormFields } from './FormFieldsTypes';
import useClientUnknownCreditCardForm from './useCreditCardForm/useClientUnknownCreditCardForm';
import useScrollWindow from './useScrollWindow';
import usePromoCode from './usePromoCode';

const ClientUnknownFormFields = ({
    displayPrecallOverlay,
    setTotalPrice,
    telehealthPrice,
}: FormFields) => {
    const intl = useIntl();
    const {
        cardDataValid,
        processing,
        processingError,
        handleSubmit,
        cardNumberRef,
        cardCvcRef,
        cardExpiryRef,
    } = useClientUnknownCreditCardForm();
    const {
        handlePromoCodeChange,
        promoCode,
        promoCodeAmount,
        promoCodeError,
    } = usePromoCode();
    const { signIntoFirestore } = useFirebaseContext();

    React.useEffect(() => {
        if (promoCodeAmount) {
            setTotalPrice(telehealthPrice - promoCodeAmount);
        }
        if (promoCodeError) {
            setTotalPrice(telehealthPrice);
        }
    }, [promoCodeAmount, telehealthPrice, setTotalPrice, promoCodeError]);

    const { loading: vetspireIdLoading, data: vetspireLocationIdData } =
        useVetspireLocationId();

    useScrollWindow({ trigger: cardDataValid });

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!vetspireLocationIdData?.telehealthVetspireLocationId)
            throw new Error(
                'Invalid State, vetspire-locationId must be defined.',
            );

        const setToDefaultCard = true;

        handleSubmit(
            signIntoFirestore,
            vetspireLocationIdData.telehealthVetspireLocationId,
            displayPrecallOverlay,
            setToDefaultCard,
            promoCode,
        ).then();
    };

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles['grid-container']}>
                <StripeInput
                    id="card-number"
                    label={intl.formatMessage({
                        id: 'paymentPage.form.paymentMethod.label',
                    })}
                    ref={cardNumberRef}
                    classes={{ root: styles['full-width'] }}
                />
                <StripeInput
                    id="expiration-date"
                    label={intl.formatMessage({
                        id: 'paymentPage.form.expiration.label',
                    })}
                    ref={cardExpiryRef}
                    classes={{ root: styles['half-width'] }}
                />
                <StripeInput
                    id="cvc"
                    label={intl.formatMessage({
                        id: 'paymentPage.form.CVC.label',
                    })}
                    ref={cardCvcRef}
                    classes={{ root: styles['half-width'] }}
                />
                <TextField
                    id="promoCode"
                    data-cy="promoCode"
                    name="promoCode"
                    label={intl.formatMessage({
                        id: 'paymentPage.form.promoCode.label',
                    })}
                    classes={{
                        root: styles['full-width'],
                    }}
                    inputProps={{ autoComplete: 'one-time-code' }}
                    onChange={handlePromoCodeChange}
                    value={promoCode}
                    error={!!promoCodeError}
                    helperText={
                        promoCodeError ||
                        (promoCodeAmount &&
                            intl.formatMessage(
                                {
                                    id: 'paymentPage.form.promoCode.amount',
                                },
                                { amount: promoCodeAmount / 100 },
                            ))
                    }
                />
                {processingError && (
                    <p className={styles['error-text']}>{processingError}</p>
                )}
            </div>
            <NextButton
                label={intl.formatMessage({
                    id: processing
                        ? 'paymentPage.nextButton.loading'
                        : 'paymentPage.nextButton.label',
                })}
                disabled={
                    processing ||
                    vetspireIdLoading ||
                    !cardDataValid ||
                    !!promoCodeError
                }
                loading={processing || vetspireIdLoading}
                variant="fixed"
                className={styles['next-button']}
            />
        </form>
    );
};

export default ClientUnknownFormFields;
