import {
    DocumentReference,
    PartialWithFieldValue,
    setDoc,
    serverTimestamp,
} from 'firebase/firestore';
import debounce from 'lodash/debounce';
import * as React from 'react';
import {
    ConfirmedAppointment,
    BookingSync,
    BookingAppointment,
} from '@bondvet/types/telehealth';
import useBookingUtils from './useBookingUtils';

export enum Tabs {
    'schedule',
    'next',
    'map',
}

type UseBookingSender = {
    activeBookingTab: string | number;
    handleBookingTabChange: (newValue: string | number) => void;
    setSync: (isActive: boolean) => Promise<void>;
    setAppointment?: ({ dateTime, location }: BookingAppointment) => void;
    appointment?: BookingAppointment | undefined;
    setConfirmedAppointment?: ({
        dateTime,
        location,
        id,
    }: ConfirmedAppointment) => void;
    confirmedAppointment?: ConfirmedAppointment | undefined;
};

type UseBookingSenderProps = {
    rootElement?: React.RefObject<HTMLDivElement>;
};

const setDocWithTimestamp = <T extends BookingSync>(
    sessionRef: DocumentReference<T>,
    data: PartialWithFieldValue<T>,
    merge = true,
) => {
    setDoc(sessionRef, { ...data, updatedAt: serverTimestamp() }, { merge });
};

export default function useBookingSender({
    rootElement,
}: UseBookingSenderProps): UseBookingSender {
    const { sessionRef } = useBookingUtils();
    const [activeBookingTab, setActiveBookingTab] = React.useState<
        string | number
    >(Tabs.schedule);
    const [appointment, setAppointmentLocally] =
        React.useState<BookingAppointment>();
    const [confirmedAppointment, setConfirmedAppointmentLocally] =
        React.useState<ConfirmedAppointment>();

    async function setSync(isActive: boolean) {
        if (sessionRef) {
            setDocWithTimestamp(sessionRef, { active: isActive });
        }
    }

    const handleBookingTabChange = (newValue: string | number) => {
        setActiveBookingTab(newValue);
        if (sessionRef) {
            setDocWithTimestamp(sessionRef, { activeTab: newValue });
        }
    };

    const setAppointment = React.useCallback(
        (newAppointment: BookingAppointment) => {
            if (sessionRef) {
                setDocWithTimestamp(sessionRef, {
                    newAppointment,
                });
            }
            setAppointmentLocally(newAppointment);
        },
        [sessionRef],
    );

    const setConfirmedAppointment = React.useCallback(
        (newAppointment: ConfirmedAppointment) => {
            if (sessionRef) {
                setDocWithTimestamp(sessionRef, {
                    confirmedAppointment: newAppointment,
                });
            }
            setConfirmedAppointmentLocally(newAppointment);
        },
        [sessionRef],
    );

    React.useLayoutEffect(() => {
        const box = rootElement?.current?.getBoundingClientRect();
        if (box && sessionRef) {
            setDocWithTimestamp(sessionRef, {
                height: box.height,
                width: box.width,
            });
        }
    }, [rootElement, sessionRef]);

    React.useLayoutEffect(() => {
        let unsubscribe = () => {};

        function handleScroll(event: Event) {
            const target = event.target as HTMLElement;

            if (target && sessionRef) {
                setDocWithTimestamp(sessionRef, {
                    scrollPosition: target.scrollTop,
                });
            }
        }

        const debouncedHandleScroll = debounce(handleScroll, 200, {
            leading: false,
        });

        if (rootElement?.current) {
            const scrollContainer = rootElement.current?.querySelector(
                '*[role="tabpanel"]:not([hidden])',
            );

            if (scrollContainer) {
                scrollContainer.addEventListener(
                    'scroll',
                    debouncedHandleScroll,
                    {
                        passive: true,
                    },
                );

                unsubscribe = () =>
                    scrollContainer.removeEventListener(
                        'scroll',
                        debouncedHandleScroll,
                    );
            }
        }

        return unsubscribe;
    }, [
        rootElement,
        activeBookingTab,
        sessionRef,
        appointment,
        setAppointment,
        confirmedAppointment,
        setConfirmedAppointment,
    ]);

    return {
        handleBookingTabChange,
        activeBookingTab,
        setSync,
        setAppointment,
        setConfirmedAppointment,
        appointment,
        confirmedAppointment,
    };
}
