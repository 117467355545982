import * as React from 'react';
import classNames from 'classnames';
import styles from './IconButton.module.scss';

export interface InputIconButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: React.ElementType;
    onClick?: () => void;
    color?: 'primary' | 'error';
    classes?: Partial<typeof styles>;
}

export default function IconButton({
    icon: Icon,
    type = 'button',
    className,
    onClick,
    color = 'primary',
    classes,
    ...props
}: InputIconButtonProps): React.ReactElement {
    /* eslint-disable react/button-has-type */
    return (
        <button
            {...props}
            type={type}
            className={classNames(className, classes?.root, styles.root, {
                [styles.error]: color === 'error',
            })}
            onClick={onClick}
        >
            <Icon />
        </button>
    );
}
