import * as React from 'react';
import styles from './TimerRising.module.scss';

interface TimerRisingProps {
    startAtSeconds?: number;
}

const formatNumber = (input: number) =>
    input.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
    });

const formatDisplay = (value: number) => {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    return `${formatNumber(minutes)}:${formatNumber(seconds)}`;
};

export default function TimerRising({
    startAtSeconds = 0,
}: TimerRisingProps): React.ReactElement {
    const [timer, setTimer] = React.useState<number>(startAtSeconds);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    return <strong className={styles.root}>{formatDisplay(timer)}</strong>;
}
