import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { gql, useLazyQuery } from '@apollo/client';
import { GraphQLClientNames } from 'lib/types';
import useClientContext from 'client/hooks/useClientContext/useClientContext';

const query = gql`
    query checkPromoCode(
        $code: String!
        $email: String!
        $isTelehealth: Boolean
    ) {
        checkPromoCode(
            code: $code
            email: $email
            isTelehealth: $isTelehealth
        ) {
            valid
            amount
            errorReason
        }
    }
`;

type CheckPromoCodeVariables = {
    code: string;
    email: string;
    isTelehealth: boolean;
};

type CheckPromoCodeQuery = {
    checkPromoCode: {
        valid: boolean;
        amount: number | null;
        errorReason: string | null;
    };
};

export type PromoCodeState = {
    isValidatingPromoCode: boolean;
    promoCodeError: string | null;
    promoCodeAmount: number | null;
};

export type PromoCodeProps = {
    promoCode:
        | string
        | number
        | readonly string[]
        | File
        | readonly File[]
        | undefined;
    handlePromoCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isValidatingPromoCode: boolean;
    promoCodeError: string | null;
    promoCodeAmount: number | null;
};

function getPromoCodeError(
    errorReason: string | null,
    intl: IntlShape,
): string | null {
    switch (errorReason) {
        case 'not found':
            return intl.formatMessage({
                id: 'paymentPage.form.promoCode.notFound',
            }) as string;
        case 'not first time':
            return intl.formatMessage({
                id: 'paymentPage.form.promoCode.notFirstTime',
            }) as string;
        default:
            return errorReason;
    }
}

export default function usePromoCode(): PromoCodeProps {
    const intl = useIntl();
    const clientContext = useClientContext();
    const { email } = clientContext;
    const [promoCode, setPromoCode] = React.useState('');

    const [promoCodeState, setPromoCodeState] = React.useState<PromoCodeState>({
        isValidatingPromoCode: false,
        promoCodeError: null,
        promoCodeAmount: null,
    });

    const [validatePromoCode, { data: promoCodeResult }] = useLazyQuery<
        CheckPromoCodeQuery,
        CheckPromoCodeVariables
    >(query, {
        fetchPolicy: 'no-cache',
        context: { clientName: GraphQLClientNames.promoCodes },
    });

    // promoCode validation result
    React.useEffect(() => {
        if (promoCodeResult?.checkPromoCode) {
            const { errorReason, valid, amount } =
                promoCodeResult.checkPromoCode;
            let promoCodeAmount: number | null = null;

            if (valid && amount) {
                promoCodeAmount = amount;
            }

            setPromoCodeState({
                isValidatingPromoCode: false,
                promoCodeAmount,
                promoCodeError: getPromoCodeError(errorReason, intl),
            });
        }
    }, [promoCodeResult, intl]);

    const handlePromoCodeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setPromoCode(event.target.value.toUpperCase());
    };

    React.useEffect(() => {
        // promoCode validation
        let timer: NodeJS.Timeout | undefined;

        if (promoCode !== '' && email !== '') {
            setPromoCodeState((prev) => ({
                ...prev,
                isValidatingPromoCode: true,
            }));
            timer = setTimeout(() => {
                timer = undefined;
                validatePromoCode({
                    variables: {
                        email,
                        code: promoCode,
                        isTelehealth: true,
                    },
                });
            }, 1000);
        } else {
            setPromoCodeState({
                isValidatingPromoCode: false,
                promoCodeError: null,
                promoCodeAmount: null,
            });
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [validatePromoCode, promoCode, email]);

    return {
        promoCode,
        ...promoCodeState,
        handlePromoCodeChange,
    };
}
