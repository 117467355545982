import classNames from 'classnames';
import * as React from 'react';
import { InputBaseProps } from '../InputBase';
import styles from './Checkbox.module.scss';
import { ReactComponent as Icon } from './assets/check.inline.svg';

export interface CheckboxProps
    extends Omit<
        InputBaseProps,
        'classes' | 'inputComponent' | 'type' | 'value' | 'onChange'
    > {
    classes?: Partial<typeof styles>;
    label: React.ReactNode;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

export default function Checkbox({
    classes,
    id,
    label: formLabel,
    onChange,
    ...props
}: CheckboxProps): React.ReactElement {
    const handleChange = React.useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.checked);
        },
        [onChange],
    );
    return (
        <label
            htmlFor={id}
            data-cy={id}
            className={classNames(classes?.root, styles.root)}
        >
            <input {...props} type="checkbox" id={id} onChange={handleChange} />
            <Icon className={classNames(classes?.check, styles.check)} />
            <span className={classNames(classes?.label, styles.label)}>
                {formLabel}
            </span>
        </label>
    );
}
