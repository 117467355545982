import { gql, useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import { Species } from '@bondvet/types/booking';
import { GraphQLClientNames } from 'lib/types';

const query = gql`
    query ClientsQuery($patientsIds: [ID]!, $fetchVetspireData: Boolean!) {
        patients(ids: $patientsIds) {
            vetspireId
            name
            species
            vetspireData @include(if: $fetchVetspireData) {
                breed
                age
                sex
                immunizations {
                    name
                    dueDate
                }
            }
            client {
                vetspireId
                firstName
                lastName
                email
            }
        }
    }
`;

type ClientsVariables = {
    patientsIds: [string | undefined | null];
    fetchVetspireData?: boolean;
};

export type Client = {
    vetspireId: string;
    firstName: string;
    lastName: string;
    email: string;
};

type Immunization = {
    name: string;
    dueDate: Date | string;
};

export type Patient = {
    _id: string;
    name: string;
    species: Species;
    client?: Client;
    vetspireId: string;
    vetspireData?: VetspireData;
};

type ClientsQuery = {
    patients: Patient[];
};

function useClientsQuery({
    patientsIds,
    fetchVetspireData,
}: ClientsVariables): QueryResult<ClientsQuery, ClientsVariables> {
    return useQuery<ClientsQuery, ClientsVariables>(query, {
        variables: {
            patientsIds,
            fetchVetspireData,
        },
        skip: patientsIds.some((id) => !id),
        // This gets called a lot so the queries are batched for performance
        context: {
            clientName: GraphQLClientNames.batch,
        },
    });
}

type VetspireData = {
    breed: string;
    age: number | null;
    sex: string;
    immunizations: Immunization[];
};

type UseClientPatientsData = {
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    species: string;
    patientName: string;
    patientVetspireId: string;
    clientVetspireId: string;
    vetspireData?: VetspireData;
};

const defaultData: UseClientPatientsData = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    species: '',
    patientName: '',
    patientVetspireId: '',
    clientVetspireId: '',
};

function normalizeImmunizations(
    data: Array<Immunization>,
): Array<Immunization> {
    return data.map(({ dueDate, ...rest }) => ({
        ...rest,
        dueDate: dueDate ? new Date(dueDate) : '',
    }));
}

function normalizeVetspireData(
    data: VetspireData | undefined,
): VetspireData | undefined {
    if (!data) return undefined;
    return {
        ...data,
        immunizations: normalizeImmunizations(data.immunizations),
    };
}

export default function useClientPatientsData({
    patientsIds,
    fetchVetspireData = false,
}: ClientsVariables): UseClientPatientsData {
    const clientsData = useClientsQuery({ patientsIds, fetchVetspireData });
    const result = { ...defaultData };

    if (clientsData.data) {
        const patient = clientsData.data.patients[0];

        result.species = patient?.species ?? '';
        result.patientName = patient?.name ?? '';
        result.patientVetspireId = patient?.vetspireId ?? '';

        const client = patient?.client;

        result.name = `${client?.firstName} ${client?.lastName}`;
        result.firstName = client?.firstName ?? '';
        result.lastName = client?.lastName ?? '';
        result.clientVetspireId = client?.vetspireId ?? '';
        result.email = client?.email ?? '';

        result.vetspireData = normalizeVetspireData(patient?.vetspireData);
    }

    return result;
}
