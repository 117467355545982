import * as React from 'react';

type UseScrollWindowProps = {
    trigger: boolean;
};

export default function useScrollWindow({
    trigger,
}: UseScrollWindowProps): void {
    React.useEffect(() => {
        if (trigger) {
            const htmlElement = document.querySelector('html');
            if (htmlElement) {
                const height = htmlElement.clientHeight;
                htmlElement.scrollTo({ top: height / 2, behavior: 'smooth' });
            }
        }
    }, [trigger]);
}
