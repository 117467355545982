import { useChatContext } from 'core/hooks/useChatContext';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styles from './ChatNotification.module.scss';

function ChatNotification() {
    const intl = useIntl();
    const { unreadMessages } = useChatContext();
    return (
        <>
            {unreadMessages && (
                <>
                    <span className="sr-only">
                        {intl.formatMessage({ id: 'callPage.chat.unread' })}
                    </span>
                    <div className={styles.dot} />
                </>
            )}
        </>
    );
}

export default ChatNotification;
