import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Step } from '../lib/types';

const REGEX_JOIN_SUBURLS = RegExp(`^/${Step.join}/.*$`, 'g');

const redirectOnEnter = (
    pathname: string,
    redirect: (path: string) => void,
) => {
    if (pathname.match(REGEX_JOIN_SUBURLS)) return;
    if (pathname.startsWith(`/${Step.call}`)) return;
    if (pathname === `/${Step.debugSkipForm}`) return;

    redirect(`/${Step.intake}`);
};

export default function useInitializeOnPageRefresh(): void {
    const [loaded, setLoaded] = React.useState<boolean>(false);

    const { pathname } = useLocation();
    const redirect = useHistory();
    React.useEffect(() => {
        if (!loaded) {
            // redirect to correct page
            redirectOnEnter(pathname, redirect.push);
            setLoaded(true);
        }
    }, [loaded, pathname, redirect.push]);
}
