import { getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import * as React from 'react';

function useStorageEmulator(): {
    initStorageEmulator: () => void;
    emulatorReady: boolean;
} {
    const [emulatorReady, setEmulatorReady] = React.useState(false);
    const initStorageEmulator = React.useCallback(() => {
        const connectToEmulatorInDevelopment = async () => {
            const auth = getAuth();
            const storage = getStorage();
            await connectStorageEmulator(storage, 'localhost', 9199, {
                mockUserToken: await auth.currentUser?.getIdToken(),
            });

            setEmulatorReady(true);
        };

        connectToEmulatorInDevelopment();
    }, []);

    return {
        initStorageEmulator,
        emulatorReady,
    };
}

export default useStorageEmulator;
