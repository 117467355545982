import * as React from 'react';
import classnames from 'classnames';
import styles from './InputBase.module.scss';

type InputElementType = HTMLInputElement | HTMLTextAreaElement;

export interface InputComponentProps
    extends React.HTMLAttributes<InputElementType> {
    // accommodate arbitrary additional props coming from the `inputProps` prop
    [arbitrary: string]: unknown;
}

export interface InputBaseProps
    extends Omit<
        React.HTMLAttributes<HTMLDivElement>,
        | 'children'
        | 'onChange'
        | 'onKeyUp'
        | 'onKeyDown'
        | 'onBlur'
        | 'onFocus'
        | 'defaultValue'
    > {
    classes?: Partial<typeof styles>;
    'aria-describedby'?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    error?: boolean;
    Icon?: React.ElementType;
    id: string;
    inputComponent?: React.ElementType<InputComponentProps>;
    inputProps?: InputComponentProps;
    inputRef?: React.Ref<InputElementType>;
    multiline?: boolean;
    name?: string;
    onBlur?: React.FocusEventHandler<InputElementType>;
    onChange?: React.ChangeEventHandler<InputElementType>;
    onFocus?: React.FocusEventHandler<InputElementType>;
    onKeyDown?: React.KeyboardEventHandler<InputElementType>;
    onKeyUp?: React.KeyboardEventHandler<InputElementType>;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    rows?: string | number;
    maxRows?: string | number;
    minRows?: string | number;
    type?: string;
    value?:
        | string
        | ReadonlyArray<string>
        | number
        | File
        | ReadonlyArray<File>;
}

function InputBase(
    {
        className,
        classes,
        multiline,
        inputComponent = 'input',
        inputRef,
        type = 'text',
        rows,
        Icon,
        inputProps: inputPropsProp = {},
        minRows,
        maxRows,
        autoFocus,
        disabled,
        id,
        name,
        onBlur,
        onChange,
        onFocus,
        onKeyDown,
        onKeyUp,
        placeholder,
        readOnly,
        required,
        error,
        value: valueProp,
        ...props
    }: InputBaseProps,
    ref?: React.ForwardedRef<HTMLDivElement>,
) {
    let InputComponent: React.ElementType<InputComponentProps> = inputComponent;

    const value =
        inputPropsProp.value != null ? inputPropsProp.value : valueProp;

    const inputProps: InputComponentProps = {
        ...inputPropsProp,
        name,
        type,
        value,
        onChange,
        onKeyDown,
        onKeyUp,
        onBlur,
        onFocus,
        placeholder,
        readOnly,
        required,
        disabled,
        autoFocus,
        id,
    };

    if (multiline) {
        InputComponent = 'textarea';
        inputProps.type = undefined;
        inputProps.rows = rows;
    }

    return (
        <div
            {...props}
            className={classnames(className, classes?.root, styles.root, {
                [styles.error]: error,
                [styles.disabled]: disabled,
                [styles.multiline]: multiline || InputComponent === 'textarea',
            })}
            ref={ref}
        >
            <InputComponent
                {...inputProps}
                className={classnames(classes?.input, styles.input)}
            />
            {Icon && <Icon />}
        </div>
    );
}

export default React.forwardRef<HTMLDivElement, InputBaseProps>(InputBase);
