import * as React from 'react';
import useFirebaseContextHandler from 'core/hooks/firebase';
import FirebaseContext from 'core/hooks/firebase/context';
import useCallContextHandler from '../../hooks/useCallContext';
import useClientContextHandler from '../../hooks/useClientContext';
import ClientContext from '../../hooks/useClientContext/context';
import CallContext from '../../hooks/useCallContext/context';
import Router from '../Router';
import styles from './App.module.scss';
import AvailabilityWrapper from '../AvailabilityWrapper';

export default function App() {
    const firebaseContext = useFirebaseContextHandler();
    const clientContext = useClientContextHandler();
    const callContext = useCallContextHandler();
    return (
        <AvailabilityWrapper>
            <FirebaseContext.Provider value={firebaseContext}>
                <ClientContext.Provider value={clientContext}>
                    <CallContext.Provider value={callContext}>
                        <main className={styles.root}>
                            {firebaseContext.firebase && <Router />}
                        </main>
                    </CallContext.Provider>
                </ClientContext.Provider>
            </FirebaseContext.Provider>
        </AvailabilityWrapper>
    );
}
