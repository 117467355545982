import { Drawer } from '@mui/material';
import Chat from 'core/components/Chat';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { TelehealthAppType } from '@bondvet/types/telehealth';
import useIsMobile from 'core/hooks/useIsMobile';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import useCallContext from '../../../hooks/useCallContext/useCallContext';
import useClientContext from '../../../hooks/useClientContext/useClientContext';
import PageHeader from '../../PageHeader';
import styles from './InVideoCall.module.scss';

interface InVideoCallProps {
    providerName: string;
}

function InVideoCall({ providerName }: InVideoCallProps) {
    const intl = useIntl();
    const {
        chatOpen,
        setChatOpen,
        sessionIds: { sessionId },
    } = useCallContext();

    const { firstName, lastName } = useClientContext();
    const closeChat = () => setChatOpen(false);
    const isMobile = useIsMobile();

    return (
        <div className={styles.root}>
            <TrapFocus open={chatOpen}>
                <Drawer
                    anchor={isMobile ? 'bottom' : 'right'}
                    variant="persistent"
                    open={chatOpen}
                    onClose={closeChat}
                    className={styles['drawer-content']}
                    elevation={0}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            width: isMobile ? '100%' : '33%',
                            minWidth: '300px',
                            backgroundColor: 'var(--beige-strong-color)',
                        },
                    }}
                    tabIndex={-1}
                >
                    <PageHeader
                        componentType="close"
                        onClose={closeChat}
                        label={intl.formatMessage({
                            id: 'callPage.chat.header',
                        })}
                    />
                    {chatOpen && (
                        <Chat
                            appUser={TelehealthAppType.client}
                            sessionId={sessionId}
                            fullClientName={`${firstName} ${lastName}`}
                            fullProviderName={providerName}
                        />
                    )}
                </Drawer>
            </TrapFocus>
        </div>
    );
}

export default InVideoCall;
