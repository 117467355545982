import {
    FirestoreTelehealthSession,
    TELEHEALTH_SESSIONS_COLLECTION_NAME,
} from '@bondvet/types/telehealth';
import { onSnapshot, doc, getFirestore } from 'firebase/firestore';
import * as React from 'react';

export default function useCaseDocument(
    id: string,
): FirestoreTelehealthSession | undefined {
    const [document, setDocument] =
        React.useState<FirestoreTelehealthSession>();
    const db = getFirestore();

    React.useEffect(() => {
        let unsubscribe = () => {};
        if (id) {
            unsubscribe = onSnapshot(
                doc(db, TELEHEALTH_SESSIONS_COLLECTION_NAME, id),
                (snapshot) => {
                    setDocument(snapshot.data() as FirestoreTelehealthSession);
                },
            );
        }
        return unsubscribe;
    }, [id, db]);

    return document;
}
