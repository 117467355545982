import { Species } from '@bondvet/types/booking';
import { ClientContextData, PetSpecies, Step } from '../../lib/types';

const initialClientContextState = {
    origin: '',
    clientId: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    patientsQueryLoading: false,
    emailQueryLoading: false,
    step: Step.intake,
    pet: {
        _id: '',
        name: '',
        species: undefined,
    },
    reasonId: '',
    notes: '',
    attachments: [],
    disclaimerAcknowledged: false,
    clientKnown: false,
    creditCardLast4: '',
    creditCards: [],
    sourceId: '',
    userFiles: [],
    patients: [],
    selectedPatient: null,
    patientPlanTelehealthQuantities: null,
    reasons: [],
    visitRating: null,
    visitDescription: '',
    debugSkipForm: false,
};

const skippedFormInitialClientContextState = {
    origin: '',
    clientId: null,
    firstName: 'asdasd@asd.at',
    lastName: 'asdasd@asd.at',
    email: 'asdasd@asd.at', // must be email with creditCards saved in stripe.
    phone: '123456789',
    patientsQueryLoading: false,
    emailQueryLoading: false,
    step: Step.call,
    pet: {
        _id: '',
        name: 'test_pet_name',
        species: Species.canine as PetSpecies,
    },
    reasonId: '1576',
    notes: 'test_notes',
    attachments: [],
    disclaimerAcknowledged: true,
    clientKnown: true,
    creditCardLast4: '4444',
    creditCards: [
        { last4: '4444', id: '123', isDefault: true, brand: 'Visa' },
        { last4: '4242', id: '456', isDefault: false, brand: 'MasterCard' },
    ],
    sourceId: 'src_1K02fTGObyRuMKq3quHsYtj2',
    userFiles: [],
    patients: [],
    selectedPatient: null,
    patientPlanTelehealthQuantities: null,
    reasons: [
        {
            __typename: 'AppointmentType',
            _id: '1576',
            name: 'Telehealth',
        },
    ],
    visitRating: null,
    visitDescription: '',
    debugSkipForm: true,
};

export default function getInitialClientContextState(): ClientContextData {
    // Set this env-var to pre-submit the initial form and skip it.
    if (process.env.REACT_APP_DEBUG_SKIP_FORM === 'true') {
        return skippedFormInitialClientContextState;
    }
    return initialClientContextState;
}
