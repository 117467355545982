import classNames from 'classnames';
import * as React from 'react';
import { InputComponentProps } from '../InputBase';
import { ReactComponent as PlusIcon } from './assets/plus.inline.svg';
import InputIconButton from '../InputIconButton';
import styles from './FileInput.module.scss';
import { FileInputProps } from './types';

export interface InputProps extends InputComponentProps, FileInputProps {
    classes?: Partial<typeof styles>;
    iconComponent?: React.FunctionComponent;
}

const VALID_KEY_DOWN_KEYS = [
    ' ',
    'ArrowUp',
    'ArrowDown',
    // The native select doesn't respond to enter on MacOS, but it's recommended by
    // https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
    'Enter',
];

export default function Input({
    multiple = true,
    placeholder,
    tabIndex,
    disabled,
    'aria-describedby': ariaDescribedBy,
    'aria-label': ariaLabel,
    classes,
    iconComponent,
    ...props
}: InputProps): React.ReactElement {
    const input = React.useRef<HTMLInputElement>(null);
    const IconButtonIcon = iconComponent || PlusIcon;

    const handleIconClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();
            input.current?.click();
        },
        [input],
    );

    const handleMouseDown = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            // ignore everything but left click
            if (event.button !== 0) {
                return;
            }
            // hijack the default focus behavior
            event.preventDefault();
            event.stopPropagation();
            input.current?.click();
        },
        [input],
    );

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (VALID_KEY_DOWN_KEYS.indexOf(event.key) !== -1) {
                event.preventDefault();
                input.current?.click();
            }
        },
        [input],
    );

    return (
        <>
            <div
                className={classNames(styles.display, classes?.display)}
                role="button"
                tabIndex={tabIndex}
                aria-disabled={disabled ? 'true' : undefined}
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedBy}
                onMouseDown={handleMouseDown}
                onKeyDown={handleKeyDown}
            >
                <span>{placeholder}</span>
            </div>
            <input
                {...props}
                multiple={multiple}
                type="file"
                className={classNames(styles.nativeInput, classes?.nativeInput)}
                ref={input}
                aria-hidden
                tabIndex={-1}
                disabled={disabled as boolean | undefined}
            />
            <InputIconButton
                type="button"
                onClick={handleIconClick}
                icon={IconButtonIcon}
                label="Click to select a file"
                className={classNames(classes?.root)}
            />
        </>
    );
}
