import classNames from 'classnames';
import { Step } from 'client/lib/types';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gql } from '@apollo/client';
import Loading from 'core/components/Loading';

import { ReactComponent as Check } from 'assets/icons/checkmark-white-background-green-circle.svg';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import PageHeader from '../PageHeader';
import ClientKnownFormFields from './ClientKnownFormFields';
import ClientUnknownFormFields from './ClientUnknownFormFields';
import CoveredByPlan from './CoveredByPlan';
import styles from './PaymentPage.module.scss';
import useCreditCardsQuery from '../../hooks/useCreditCardsQuery';
import PreCallOverlay from './PreCallOverlay';

export type PaymentPageProps = {
    classes?: Partial<typeof styles>;
};

const TelehealthPriceQuery = gql`
    query Query {
        telehealthPrice
    }
`;

type TelehealthPriceResult = {
    telehealthPrice: number;
};

function PaymentPage({ classes }: PaymentPageProps): React.ReactElement {
    const { loading, data: telehealthPriceData } =
        useCreditCardsQuery<TelehealthPriceResult>(TelehealthPriceQuery);

    const [telehealthPrice, setTelehealthPrice] = React.useState(0);
    const [totalPrice, setTotalPrice] = React.useState(0);

    React.useEffect(() => {
        if (telehealthPriceData?.telehealthPrice) {
            setTotalPrice(telehealthPriceData.telehealthPrice);
            setTelehealthPrice(telehealthPriceData.telehealthPrice);
        }
    }, [telehealthPriceData, setTotalPrice, setTelehealthPrice]);

    const intl = useIntl();
    const { clientKnown, patientPlanTelehealthQuantities, selectedPatient } =
        useClientContext();

    const [precallOverlayDisplayed, displayPrecallOverlay] =
        React.useState(false);

    const isCoveredByPlan = !!patientPlanTelehealthQuantities?.hasUnused;

    return (
        <>
            <PageHeader
                componentType="navigation"
                previousStep={Step.reason}
                label={null}
            />
            <div
                className={classNames(styles.root, {
                    [styles.noPayment]: isCoveredByPlan,
                })}
            >
                <header
                    className={classNames(classes?.header, styles.header, {
                        [styles.noPayment]: isCoveredByPlan,
                    })}
                >
                    {loading || !telehealthPriceData ? (
                        <Loading classes={{ root: styles.loading }} />
                    ) : (
                        <>
                            <strong>
                                {(isCoveredByPlan
                                    ? 0
                                    : totalPrice / 100
                                ).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </strong>
                            {(isCoveredByPlan ||
                                telehealthPrice - totalPrice > 0) && (
                                <p className={styles.promoCodeHeader}>
                                    <Check />
                                    <span>
                                        {intl.formatMessage({
                                            id: 'paymentPage.headerPromoCode',
                                        })}
                                    </span>
                                    {(telehealthPrice / 100).toLocaleString(
                                        'en-US',
                                        {
                                            style: 'currency',
                                            currency: 'USD',
                                        },
                                    )}
                                </p>
                            )}
                        </>
                    )}
                    <p className={styles.disclaimerHeader}>
                        {intl.formatMessage(
                            {
                                id: isCoveredByPlan
                                    ? 'paymentPage.planHeader'
                                    : 'paymentPage.header',
                            },
                            {
                                patientName: selectedPatient?.name ?? '',
                                planName:
                                    selectedPatient?.patientPlan?.preventionPlan
                                        ?.name ?? '',
                            },
                        )}
                    </p>
                    <p className={styles.disclaimer}>
                        <FormattedMessage
                            id={
                                isCoveredByPlan
                                    ? 'paymentPage.planDisclaimer'
                                    : 'paymentPage.disclaimer'
                            }
                            values={{
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                strong: (chunks: string) => (
                                    <span>{chunks}</span>
                                ),
                                cost: (totalPrice / 100).toLocaleString(
                                    'en-US',
                                    {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0,
                                    },
                                ),
                            }}
                        />
                    </p>
                </header>
                {isCoveredByPlan && (
                    <CoveredByPlan
                        displayPrecallOverlay={displayPrecallOverlay}
                        setTotalPrice={setTotalPrice}
                        telehealthPrice={telehealthPrice}
                    />
                )}
                {!isCoveredByPlan && !clientKnown && (
                    <ClientUnknownFormFields
                        displayPrecallOverlay={displayPrecallOverlay}
                        setTotalPrice={setTotalPrice}
                        telehealthPrice={telehealthPrice}
                    />
                )}
                {!isCoveredByPlan && clientKnown && (
                    <ClientKnownFormFields
                        displayPrecallOverlay={displayPrecallOverlay}
                        setTotalPrice={setTotalPrice}
                        telehealthPrice={telehealthPrice}
                    />
                )}
            </div>
            {precallOverlayDisplayed && <PreCallOverlay />}
        </>
    );
}

export default PaymentPage;
