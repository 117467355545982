import * as React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import {
    TelehealthAppType,
    TwilioVideoStreamNames,
} from '@bondvet/types/telehealth';
import { useIntl } from 'react-intl';
import {
    AudioTrack,
    LocalAudioTrack,
    LocalVideoTrack,
    Participant,
    RemoteAudioTrack,
    RemoteVideoTrack,
    Room,
    TwilioError,
    VideoTrack,
    createLocalAudioTrack,
    createLocalVideoTrack,
    connect,
} from 'twilio-video';
import { StoreSessionMetaDataResult, GraphQLClientNames } from 'lib/types';
import {
    CallMetaData,
    InitialVideoContext,
    VideoContextData,
    VideoContextType,
} from './context';
import useSetter from './useSetter';

const TWILIO_TOKEN_EXPIRED = 20104;
const TWILIO_UNABLE_TO_CREATE_ROOM = 53103;
const TWILIO_ROOM_COMPLETED = 53118;

const storeSessionMetaDataMutation = gql`
    mutation StoreSessionMetaData($data: SessionMetaDataInput!) {
        storeSessionMetaData(data: $data) {
            success
            error
        }
    }
`;

export default function useVideoContextHandler(
    appType: TelehealthAppType,
): VideoContextType {
    const apolloClient = useApolloClient();
    const intl = useIntl();
    const [
        {
            microphoneOn,
            cameraOn,
            room,
            availableVideoDeviceIds,
            currentVideoDeviceIndex,
            localVideoTrack,
            localAudioTrack,
            localParticipant,
            remoteVideoTrack,
            remoteAudioTrack,
            remoteParticipant,
            connectedToCall,
            localVideoRef,
            localAudioRef,
            remoteVideoRef,
            remoteAudioRef,
            hasReceivedPermissions,
            hasLostPermissions,
            userPermissionsGranted,
        },
        setValue,
    ] = React.useState<VideoContextData>(InitialVideoContext);

    const setRoom = useSetter<Room>('room', setValue);

    const setAvailableVideoDeviceIds = useSetter<string[]>(
        'availableVideoDeviceIds',
        setValue,
    );
    const setCurrentVideoDeviceIndex = useSetter<number>(
        'currentVideoDeviceIndex',
        setValue,
    );
    const setLocalVideoTrack = useSetter<VideoTrack | null>(
        'localVideoTrack',
        setValue,
    );
    const setLocalAudioTrack = useSetter<AudioTrack | null>(
        'localAudioTrack',
        setValue,
    );
    const setLocalParticipant = useSetter<Participant | null>(
        'localParticipant',
        setValue,
    );
    const setRemoteVideoTrack = useSetter<VideoTrack | null>(
        'remoteVideoTrack',
        setValue,
    );
    const setRemoteAudioTrack = useSetter<AudioTrack | null>(
        'remoteAudioTrack',
        setValue,
    );
    const setRemoteParticipant = useSetter<Participant | null>(
        'remoteParticipant',
        setValue,
    );
    const setConnectedToCall = useSetter<boolean>('connectedToCall', setValue);
    const setLocalVideoRef = useSetter<HTMLVideoElement>(
        'localVideoRef',
        setValue,
    );
    const setLocalAudioRef = useSetter<HTMLAudioElement>(
        'localAudioRef',
        setValue,
    );
    const setRemoteVideoRef = useSetter<HTMLVideoElement>(
        'remoteVideoRef',
        setValue,
    );
    const setRemoteAudioRef = useSetter<HTMLAudioElement>(
        'remoteAudioRef',
        setValue,
    );
    const setHasReceivedPermissions = useSetter<boolean>(
        'hasReceivedPermissions',
        setValue,
    );
    const setHasLostPermissions = useSetter<boolean>(
        'hasLostPermissions',
        setValue,
    );
    const setUserPermissionsGranted = useSetter<boolean>(
        'userPermissionsGranted',
        setValue,
    );

    const setSessionMetaData = React.useCallback(
        async ({
            hasMicrophonePermission,
            hasCameraPermission,
        }: CallMetaData) => {
            const { data: operationResult, errors: sessionMetaDataErrors } =
                await apolloClient.mutate<StoreSessionMetaDataResult>({
                    mutation: storeSessionMetaDataMutation,
                    variables: {
                        data: {
                            hasMicrophonePermission,
                            hasCameraPermission,
                        },
                    },
                    context: {
                        clientName: GraphQLClientNames.telehealth,
                    },
                });

            if (
                sessionMetaDataErrors ||
                !operationResult ||
                !operationResult.storeSession?.success
            ) {
                console.warn(
                    intl.formatMessage({
                        id: 'errors.technicalError',
                    }),
                );
            }
        },
        [apolloClient, intl],
    );

    const startLocalAudioStream = React.useCallback(() => {
        createLocalAudioTrack().then((track) => {
            setLocalAudioTrack(track);
            room?.localParticipant.publishTrack(track);
        });
    }, [room?.localParticipant, setLocalAudioTrack]);

    const startLocalVideoStream = React.useCallback(() => {
        createLocalVideoTrack().then((track) => {
            setLocalVideoTrack(track);
            room?.localParticipant.publishTrack(track);
        });
    }, [room?.localParticipant, setLocalVideoTrack]);

    const endLocalAudioStream = React.useCallback(() => {
        room?.localParticipant.audioTracks.forEach((tracks) => {
            tracks.track.stop();
            tracks.track?.disable();
            room?.localParticipant.unpublishTrack(tracks.track);
        });

        localAudioTrack?.stop();
        localAudioTrack?.disable();

        setLocalAudioTrack(null);
    }, [localAudioTrack, room?.localParticipant, setLocalAudioTrack]);

    const endLocalVideoStream = React.useCallback(() => {
        room?.localParticipant.videoTracks.forEach((tracks) => {
            tracks.track.stop();
            tracks.track?.disable();
            room?.localParticipant.unpublishTrack(tracks.track);
        });

        localVideoTrack?.stop();
        localVideoTrack?.disable();

        setLocalVideoTrack(null);
    }, [localVideoTrack, room?.localParticipant, setLocalVideoTrack]);

    const setCameraOn = (isCameraOn: boolean) => {
        if (isCameraOn) {
            startLocalVideoStream();
        } else {
            endLocalVideoStream();
        }

        setValue((prev) => ({
            ...prev,
            cameraOn: isCameraOn,
        }));
    };

    const setMicrophoneOn = (isMicrophoneOn: boolean) => {
        if (isMicrophoneOn) {
            startLocalAudioStream();
        } else {
            endLocalAudioStream();
        }

        setValue((prev) => ({
            ...prev,
            microphoneOn: isMicrophoneOn,
        }));
    };

    function getVideoDevices(
        devices: Array<MediaDeviceInfo>,
    ): Array<MediaDeviceInfo> {
        const videoDevices = devices.filter(
            (dev) => dev.label && dev.kind === 'videoinput',
        );

        return videoDevices;
    }

    function getAudioDevices(
        devices: Array<MediaDeviceInfo>,
    ): Array<MediaDeviceInfo> {
        const audioDevices = devices.filter(
            (dev) => dev.label && dev.kind === 'audioinput',
        );

        return audioDevices;
    }

    function setAvailableMediaDeviceIds(devices: Array<MediaDeviceInfo>): void {
        const deviceIds = devices.map((device) => device.deviceId);
        setAvailableVideoDeviceIds(deviceIds);
    }

    const pollIdRef = React.useRef<number>();
    const contextAlive = React.useRef(true);

    const [metaData, setMetaData] = React.useState<CallMetaData>();

    React.useEffect(() => {
        const interval = setInterval(() => {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                const hasCameraPermission = getVideoDevices(devices).length > 0;
                const hasMicrophonePermission =
                    getAudioDevices(devices).length > 0;
                if (
                    hasMicrophonePermission !==
                        metaData?.hasMicrophonePermission ||
                    hasCameraPermission !== metaData.hasCameraPermission
                ) {
                    setSessionMetaData({
                        hasCameraPermission,
                        hasMicrophonePermission,
                    });
                    setMetaData({
                        hasCameraPermission,
                        hasMicrophonePermission,
                    });
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [
        metaData?.hasCameraPermission,
        metaData?.hasMicrophonePermission,
        setSessionMetaData,
    ]);

    const pollDevicesAllowed = () => {
        if (!contextAlive.current) return;
        // manually request access once, otherwise chrome thinks we don't need it
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .catch(() => {
                /* expected */
            });

        const id = window.setInterval(() => {
            pollIdRef.current = id;
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                const videoDevices = getVideoDevices(devices);

                const permissionGranted = videoDevices.length > 0;
                if (permissionGranted) {
                    setHasReceivedPermissions(true);
                    setUserPermissionsGranted(true);
                    setAvailableMediaDeviceIds(videoDevices);

                    clearInterval(id);
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    pollDevicesDisallowed();
                }
            });
        }, 1000);
    };
    const pollDevicesDisallowed = () => {
        if (!contextAlive.current) return;

        const id = window.setInterval(() => {
            pollIdRef.current = id;

            navigator.mediaDevices.enumerateDevices().then((devices) => {
                const videoDevices = getVideoDevices(devices);

                const permissionLost = videoDevices.length <= 0;
                if (permissionLost) {
                    setHasLostPermissions(false);
                    if (userPermissionsGranted)
                        setUserPermissionsGranted(false);

                    setAvailableMediaDeviceIds(videoDevices);

                    clearInterval(id);
                    pollDevicesAllowed();
                }
            });
        }, 1000);
    };

    // create/remove device-tracks if user allows/denies device-permissions
    React.useEffect(() => {
        if (hasReceivedPermissions) {
            setHasReceivedPermissions(false);
            if (cameraOn) {
                startLocalVideoStream();
            }
            if (microphoneOn) {
                startLocalAudioStream();
            }
        }

        if (hasLostPermissions) {
            setHasLostPermissions(false);
            endLocalVideoStream();
            endLocalAudioStream();
        }
    }, [
        cameraOn,
        endLocalAudioStream,
        endLocalVideoStream,
        hasLostPermissions,
        hasReceivedPermissions,
        microphoneOn,
        room,
        setHasLostPermissions,
        setHasReceivedPermissions,
        setLocalAudioTrack,
        setLocalVideoTrack,
        setUserPermissionsGranted,
        startLocalAudioStream,
        startLocalVideoStream,
    ]);

    const joinRoom = async (
        twilioAuthToken: string,
        roomId: string,
        onAuthExpired?: () => void,
        onRoomExpired?: () => void,
        onDisconnected?: () => void,
    ) => {
        let videoTrack = null;
        let audioTrack = null;
        try {
            videoTrack = await createLocalVideoTrack({
                name: TwilioVideoStreamNames[appType],
            });
            audioTrack = await createLocalAudioTrack();

            navigator.mediaDevices.enumerateDevices().then((devices) => {
                setAvailableMediaDeviceIds(getVideoDevices(devices));
            });

            setUserPermissionsGranted(true);
            pollDevicesDisallowed();
        } catch (error) {
            if ((error as { name: string }).name === 'NotAllowedError') {
                // User did not grant device-permissions
                setUserPermissionsGranted(false);
                pollDevicesAllowed();

                // TODO: tell user how to activate their device-permissions
            }
        }

        const initialTracks: (LocalVideoTrack | LocalAudioTrack)[] = [];
        if (videoTrack !== null) initialTracks.push(videoTrack);
        if (audioTrack !== null) initialTracks.push(audioTrack);

        try {
            const newRoom = await connect(twilioAuthToken, {
                name: roomId,
                tracks: initialTracks,
                video: { height: 720, frameRate: 24, width: 1280 },
            });
            setLocalVideoTrack(videoTrack);
            setLocalAudioTrack(audioTrack);
            setRoom(newRoom);
            newRoom.on('participantConnected', (newParticipant: Participant) =>
                setRemoteParticipant(newParticipant),
            );
            newRoom.on('participantDisconnected', () =>
                setRemoteParticipant(null),
            );
            newRoom.on('trackStarted', (track) => {
                if (track.kind === 'video') {
                    setRemoteVideoTrack(track as RemoteVideoTrack);
                } else if (track.kind === 'audio') {
                    setRemoteAudioTrack(track as RemoteAudioTrack);
                }
            });

            newRoom.participants.forEach((newParticipant: Participant) =>
                setRemoteParticipant(newParticipant),
            );

            newRoom.on('trackUnpublished', (trackPublication) => {
                if (trackPublication.kind === 'video') {
                    setRemoteVideoTrack(null);
                } else if (trackPublication.kind === 'audio') {
                    setRemoteAudioTrack(null);
                }
            });

            newRoom.on('disconnected', (_, error) => {
                const twilioError = error as TwilioError;
                // Ignore if no error is set, since that means disconnection is intended
                if (!twilioError || !twilioError.code) return;
                // ignore if room was completed (intended flow)
                if (twilioError.code === TWILIO_ROOM_COMPLETED) return;

                if (onDisconnected) {
                    onDisconnected();
                } else {
                    console.warn('Disconnected from Twilio-Room!');
                }
            });
        } catch (error) {
            const twilioError = error as TwilioError;
            if (twilioError)
                switch (twilioError.code) {
                    case TWILIO_TOKEN_EXPIRED:
                        if (onAuthExpired) onAuthExpired();
                        break;
                    case TWILIO_UNABLE_TO_CREATE_ROOM:
                        if (onRoomExpired) {
                            onRoomExpired();
                        } else {
                            console.warn('Twilio-Room expired!');
                        }
                        break;
                    default:
                        throw error;
                }
        }
    };

    const endVideoCall = React.useCallback(() => {
        endLocalVideoStream();
        endLocalAudioStream();
        setConnectedToCall(false);
    }, [endLocalAudioStream, endLocalVideoStream, setConnectedToCall]);

    const endVideoCallRef = React.useRef(() => {});

    React.useEffect(() => {
        endVideoCallRef.current = endVideoCall;
    }, [endVideoCall]);

    React.useEffect(() => {
        return () => {
            endVideoCallRef.current();
            clearInterval(pollIdRef.current);
            contextAlive.current = false;
        };
    }, []);

    return {
        microphoneOn,
        cameraOn,
        room,
        availableVideoDeviceIds,
        currentVideoDeviceIndex,
        localVideoTrack,
        localAudioTrack,
        localParticipant,
        remoteVideoTrack,
        remoteAudioTrack,
        remoteParticipant,
        connectedToCall,
        localVideoRef,
        localAudioRef,
        remoteVideoRef,
        remoteAudioRef,
        hasReceivedPermissions,
        hasLostPermissions,
        setMicrophoneOn,
        setCameraOn,
        setRoom,
        setAvailableVideoDeviceIds,
        setCurrentVideoDeviceIndex,
        setLocalVideoTrack,
        setLocalAudioTrack,
        setLocalParticipant,
        setRemoteVideoTrack,
        setRemoteAudioTrack,
        setRemoteParticipant,
        setConnectedToCall,
        setLocalVideoRef,
        setLocalAudioRef,
        setRemoteVideoRef,
        setRemoteAudioRef,
        joinRoom,
        endVideoCall,
        endLocalVideoStream,
        userPermissionsGranted,
        setSessionMetaData,
    };
}
