import * as React from 'react';
import InputBase, { InputBaseProps } from '../InputBase';

export type InputProps = InputBaseProps;

function Input(props: InputProps, ref?: React.ForwardedRef<HTMLDivElement>) {
    return <InputBase {...props} ref={ref} />;
}

export default React.forwardRef<HTMLDivElement, InputProps>(Input);
