export async function asyncRetry<T>(
    fct: () => Promise<T>,
    attempts: number,
    millisecondsUntilAttempt = 300,
): Promise<T> {
    try {
        return await fct();
    } catch (err) {
        if (attempts === 1) throw err;

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(asyncRetry(fct, attempts - 1));
            }, millisecondsUntilAttempt);
        });
    }
}
