import * as React from 'react';
import {
    AudioTrack,
    LocalAudioTrack,
    LocalVideoTrack,
    RemoteVideoTrack,
    RemoteAudioTrack,
    Participant,
    Room,
    VideoTrack,
} from 'twilio-video';

export const InitialVideoContext = {
    microphoneOn: true,
    cameraOn: true,
    room: null,
    availableVideoDeviceIds: [],
    currentVideoDeviceIndex: 0,
    localVideoTrack: null,
    localAudioTrack: null,
    localParticipant: null,
    remoteVideoTrack: null,
    remoteAudioTrack: null,
    remoteParticipant: null,
    connectedToCall: false,
    localVideoRef: null,
    localAudioRef: null,
    remoteVideoRef: null,
    remoteAudioRef: null,
    hasReceivedPermissions: false,
    hasLostPermissions: false,
    userPermissionsGranted: false,
};

export type CallMetaData = {
    hasMicrophonePermission: boolean;
    hasCameraPermission: boolean;
};

export type VideoContextData = {
    microphoneOn: boolean;
    cameraOn: boolean;
    room: Room | null;
    availableVideoDeviceIds: string[];
    currentVideoDeviceIndex: number;
    localVideoTrack: LocalVideoTrack | null;
    localAudioTrack: LocalAudioTrack | null;
    localParticipant: Participant | null;
    remoteVideoTrack: RemoteVideoTrack | null;
    remoteAudioTrack: RemoteAudioTrack | null;
    remoteParticipant: Participant | null;
    connectedToCall: boolean;
    localVideoRef: HTMLVideoElement | null;
    localAudioRef: HTMLAudioElement | null;
    remoteVideoRef: HTMLVideoElement | null;
    remoteAudioRef: HTMLAudioElement | null;
    hasReceivedPermissions: boolean;
    hasLostPermissions: boolean;
    userPermissionsGranted: boolean;
};

export type VideoContextUpdaters = {
    setMicrophoneOn: (microphoneOn: boolean) => void;
    setCameraOn: (cameraOn: boolean) => void;
    setRoom: (newRoom: Room) => void;
    setAvailableVideoDeviceIds: (data: string[]) => void;
    setCurrentVideoDeviceIndex: (index: number) => void;
    setLocalVideoTrack: (newVideoTrack: VideoTrack | null) => void;
    setLocalAudioTrack: (newAudioTrack: AudioTrack | null) => void;
    setLocalParticipant: (participant: Participant | null) => void;
    setRemoteVideoTrack: (newVideoTrack: VideoTrack | null) => void;
    setRemoteAudioTrack: (newAudioTrack: AudioTrack | null) => void;
    setRemoteParticipant: (participant: Participant | null) => void;
    setConnectedToCall: (connected: boolean) => void;
    setLocalVideoRef: (ref: HTMLVideoElement) => void;
    setLocalAudioRef: (ref: HTMLAudioElement) => void;
    setRemoteVideoRef: (ref: HTMLVideoElement) => void;
    setRemoteAudioRef: (ref: HTMLAudioElement) => void;
    joinRoom: (
        twilioAuthToken: string,
        roomId: string,
        onAuthExpired: undefined | (() => void),
        onRoomExpired: undefined | (() => void),
        onDisconnected: undefined | (() => void),
    ) => void;
    endVideoCall: () => void;
    endLocalVideoStream: () => void;
    setSessionMetaData: ({
        hasMicrophonePermission,
        hasCameraPermission,
    }: CallMetaData) => void;
};

export type VideoContextType = VideoContextData & VideoContextUpdaters;
const VideoContext = React.createContext<VideoContextType>({
    setMicrophoneOn: () => {},
    setCameraOn: () => {},
    setRoom: () => {},
    setAvailableVideoDeviceIds: () => {},
    setCurrentVideoDeviceIndex: () => {},
    setLocalVideoTrack: () => {},
    setLocalAudioTrack: () => {},
    setLocalParticipant: () => {},
    setRemoteVideoTrack: () => {},
    setRemoteAudioTrack: () => {},
    setRemoteParticipant: () => {},
    setConnectedToCall: () => {},
    setLocalVideoRef: () => {},
    setLocalAudioRef: () => {},
    setRemoteVideoRef: () => {},
    setRemoteAudioRef: () => {},
    joinRoom: () => {},
    endVideoCall: () => {},
    endLocalVideoStream: () => {},
    setSessionMetaData: () => {},
    ...InitialVideoContext,
});
export default VideoContext;
