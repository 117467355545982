import { ApolloClient, gql } from '@apollo/client';
import { Species } from '@bondvet/types/booking';
import {
    loadStripe,
    Stripe,
    Source,
    StripeCardNumberElement,
} from '@stripe/stripe-js';
import * as React from 'react';
import { PHONE_MASKING_SYMBOL } from 'core/constants';
import useStripeAccountId from '../../../hooks/useStripeAccountId';
import { ClientContextData } from '../../../lib/types';

export async function createStripeSource(
    stripe: Stripe,
    client: ApolloClient<unknown>,
    cardNumber: StripeCardNumberElement,
): Promise<Source> {
    const { error, source } = await stripe.createSource(cardNumber, {
        usage: 'reusable',
    });

    if (error) throw new Error(error.message || error.code || error.toString());
    if (!source) throw new Error('No SourceId received.');

    return source;
}

export const createPaymentIntentForEmailAddressMutation = gql`
    mutation createPaymentIntentForEmailAddress(
        $email: Email!
        $sourceId: String!
        $vetspireLocationId: String!
        $cvcTokenId: String
        $firstName: String
        $lastName: String
        $markAsDefault: Boolean
        $promoCode: String
        $isTelehealth: Boolean
    ) {
        createPaymentIntentForEmailAddress(
            email: $email
            sourceId: $sourceId
            vetspireLocationId: $vetspireLocationId
            cvcTokenId: $cvcTokenId
            firstName: $firstName
            lastName: $lastName
            markAsDefault: $markAsDefault
            promoCode: $promoCode
            isTelehealth: $isTelehealth
        ) {
            paymentIntent {
                id
                clientSecret
                nextAction {
                    type
                }
            }
            error
        }
    }
`;

export const mobileStyle = {
    base: {
        color: '#103559',
        // eslint-disable-next-line quotes
        fontFamily: "'Sailec', 'Helvetica', 'Arial', sans-serif",
        fontSize: '18px',
        iconColor: '#103559',
        lineHeight: '22px',

        '::placeholder': {
            color: '#a4b2bf',
        },

        ':-webkit-autofill': {
            backgroundColor: 'transparent',
        },
    },
};

export const initialCardState = {
    processing: false,
    processingError: '',
};

export function useStripe(clientKnown: boolean): Stripe | null {
    const { loading: stripeAccountIdLoading, data: stripeAccountIdData } =
        useStripeAccountId();

    const [stripe, setStripe] = React.useState<Stripe | null>(null);
    React.useEffect(() => {
        if (!stripeAccountIdLoading && stripeAccountIdData) {
            loadStripe(
                process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
                stripeAccountIdData?.telehealthStripeAccountId && clientKnown
                    ? {
                          stripeAccount:
                              stripeAccountIdData?.telehealthStripeAccountId,
                      }
                    : undefined,
            ).then((stripeApi: Stripe | null) => {
                setStripe(stripeApi);
            });
        }
    }, [stripeAccountIdLoading, stripeAccountIdData, clientKnown]);

    return stripe || null;
}

type TelehealthInput = {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    reasonId: string;
    attachments: ReadonlyArray<File>;
    notes: string;
    patientId: string | undefined;
    pet: string | undefined;
    species: Species | undefined;
};

export const createTelehealthInputFromClientContext = ({
    email,
    phone,
    firstName,
    lastName,
    pet,
    reasonId,
    userFiles,
    notes,
}: ClientContextData): TelehealthInput => {
    let petFields;

    if (pet._id) {
        petFields = {
            patientId: pet._id,
            pet: undefined,
            species: undefined,
        };
    } else {
        petFields = {
            patientId: undefined,
            pet: pet.name,
            species: pet.species,
        };
    }

    const sanitizedPhoneNumber = phone.includes(PHONE_MASKING_SYMBOL)
        ? ''
        : phone;

    return {
        email,
        phone: sanitizedPhoneNumber,
        firstName,
        lastName,
        reasonId,
        attachments: userFiles,
        notes,
        ...petFields,
    };
};
