import classNames from 'classnames';
import * as React from 'react';
import { ReactComponent as NursePlaceholder } from '../assets/nursePlaceholder.svg';
import styles from './PreviewImage.module.scss';

interface PreviewImageProps {
    img?: string;
}

const PreviewImage = ({ img }: PreviewImageProps) => {
    return (
        <div
            className={classNames({
                [styles['svg-root']]: !img,
                [styles.pulse]: !img,
            })}
        >
            {img ? (
                <img
                    className={classNames(
                        styles['preview-image'],
                        styles.pulse,
                    )}
                    src={img}
                    alt="nurse joining"
                />
            ) : (
                <NursePlaceholder />
            )}
        </div>
    );
};
export default PreviewImage;
