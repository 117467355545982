import * as React from 'react';

export type WindowSize = {
    width: number;
    height: number;
};

// use iPhone 11 as fallback for SSR
const DEFAULT_SSR_WINDOW_SIZE = {
    width: 414,
    height: 896,
};

function getCurrentSize(): WindowSize {
    if (typeof window === 'undefined') {
        return DEFAULT_SSR_WINDOW_SIZE;
    }

    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
}

export default function useWindowSize(): WindowSize {
    const [size, setSize] = React.useState(getCurrentSize);

    React.useEffect(() => {
        const handler = () => {
            setSize(getCurrentSize());
        };

        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    }, []);

    return size;
}
