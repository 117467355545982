import {
    OperationVariables,
    useLazyQuery,
    TypedDocumentNode,
    DocumentNode,
    LazyQueryHookOptions,
} from '@apollo/client';
import { GraphQLClientNames } from '../../lib/types';

export default function useLazyCreditCardsQuery<
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: LazyQueryHookOptions<TData, TVariables>,
): ReturnType<typeof useLazyQuery<TData, TVariables>> {
    return useLazyQuery<TData, TVariables>(query, {
        ...options,
        context: {
            ...options?.context,
            clientName: GraphQLClientNames.creditCards,
        },
    });
}
