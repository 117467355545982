import { SessionStatus, TelehealthAppType } from '@bondvet/types/telehealth';
import { Modal } from '@mui/material';
import Booking from 'core/components/Booking';
import Button from 'core/components/Button';
import Loading from 'core/components/Loading';
import Video from 'core/components/Video';
import useFirebaseContext from 'core/hooks/firebase/useFirebaseContext';
import useBookingReceiver from 'core/hooks/useBookingReceiver';
import useCaseDocument from 'core/hooks/useCaseDocument';
import { ChatContext, useChatContextHandler } from 'core/hooks/useChatContext';
import useProviderId from 'core/hooks/useProviderId';
import useProviderInfo from 'core/hooks/useProviderInfo';
import useProviderName from 'core/hooks/useProviderName';
import useVideoContextHandler from 'core/hooks/video';
import VideoContext from 'core/hooks/video/context';
import { clearLocalStorage } from 'core/lib/storage';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { BookingContext } from 'team/hooks/useBookingContext';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { initialCallContextState } from '../../hooks/useCallContext';
import useCallContext from '../../hooks/useCallContext/useCallContext';
import useClientContext from '../../hooks/useClientContext/useClientContext';
import { Step } from '../../lib/types';
import styles from './CallPage.module.scss';
import InVideoCall from './InVideoCall';
import Joining from './Joining';
import Queue from './Queue';
import PermissionWarning from '../PermissionWarning';

const withFirebaseInitialized =
    // eslint-disable-next-line react/display-name
    (CallPageComponent: React.ElementType) => () => {
        const { firebase } = useFirebaseContext();

        if (!firebase) {
            return <Loading classes={{ root: styles.loading }} />;
        }
        return <CallPageComponent />;
    };

function CallPage() {
    const intl = useIntl();
    const { debugSkipForm } = useClientContext();
    const videoContext = useVideoContextHandler(TelehealthAppType.client);
    const [debugSessionStatus, setDebugSessionStatus] =
        React.useState<SessionStatus>(SessionStatus.nurseQueue);

    const clientContext = useClientContext();
    const {
        bookingOpen,
        setBookingOpen,
        setSessionIds,
        chatOpen,
        setChatOpen,
        sessionIds: { twilioAuthToken, sessionId },
        endCall,
    } = useCallContext();
    const chatContext = useChatContextHandler({ sessionId });
    const [endCallModalOpen, setEndCallModalOpen] =
        React.useState<boolean>(false);
    const handleSwitchEndCallModal = () =>
        setEndCallModalOpen(!endCallModalOpen);
    const handleEndCall = () => endCall(clientContext, videoContext);

    const { rejoinId } = useParams<{ rejoinId: string }>();
    const history = useHistory();
    if (!sessionId && rejoinId) {
        history.push(`/${Step.join}/${rejoinId}`);
    }

    const document = useCaseDocument(sessionId);
    const providerId = useProviderId({ document });
    const provider = useProviderInfo({ id: providerId });
    const providerName = useProviderName({ provider });

    // if debugging, go back so that form can be resubmitted on refresh
    React.useEffect(() => {
        if (debugSkipForm && !twilioAuthToken) {
            clientContext.setStep(Step.debugSkipForm);
        }
    }, [clientContext, twilioAuthToken, debugSkipForm]);

    React.useEffect(() => {
        if (document?.status === SessionStatus.finished) {
            clientContext.setStep(Step.summary);
            videoContext.endVideoCall();
        }
    }, [clientContext, document?.status, videoContext]);

    // Update booking state
    const bookingRef = React.createRef<HTMLDivElement>();
    const {
        isSyncActive,
        activeBookingTab,
        appointment,
        confirmedAppointment,
    } = useBookingReceiver({
        rootElement: bookingRef,
    });
    React.useEffect(() => {
        setBookingOpen(isSyncActive);
    }, [isSyncActive, setBookingOpen]);

    if (!document) return <Loading classes={{ root: styles.loading }} />;

    const sessionStatusToUse = debugSkipForm
        ? debugSessionStatus
        : document.status;

    const onAuthExpired = () => {
        clearLocalStorage();
        setSessionIds(initialCallContextState.sessionIds);
        clientContext.setStep(Step.intake);
    };

    const RenderMainComponent = (
        sessionStatus: SessionStatus | null = null,
    ) => {
        if (bookingOpen)
            return (
                <BookingContext.Provider
                    value={{
                        activeBookingTab,
                        appointment,
                        confirmedAppointment,
                        isProvider: false,
                        setSync: () => {},
                        setAppointment: () => {},
                        setConfirmedAppointment: () => {},
                        handleBookingTabChange: () => {},
                    }}
                >
                    <Booking ref={bookingRef} />
                </BookingContext.Provider>
            );

        if (
            sessionStatus === SessionStatus.nurseQueue ||
            sessionStatus === SessionStatus.veterinarianQueue
        ) {
            return <Queue telehealthSession={document} />;
        }

        if (
            (sessionStatus === SessionStatus.nurseJoining ||
                sessionStatus === SessionStatus.veterinarianJoining) &&
            provider
        ) {
            return (
                <Joining sessionStatus={sessionStatus} provider={provider} />
            );
        }

        if (
            sessionStatus === SessionStatus.nurseCall ||
            sessionStatus === SessionStatus.veterinarianCall
        ) {
            return <InVideoCall providerName={providerName} />;
        }

        return null;
    };

    const handleOnDisconnected = () => {
        clientContext.setStep(Step.disconnected);
    };

    const handleOnRoomExpired = () => {
        clientContext.setStep(Step.intake);
    };

    return (
        <VideoContext.Provider value={videoContext}>
            <ChatContext.Provider value={chatContext}>
                <div
                    data-cy="callPage"
                    className={classNames(styles.root, 'no-body-scrolling')}
                >
                    {debugSkipForm && (
                        // eslint-disable-next-line react/jsx-no-literals
                        <div className={styles['debug-buttons']}>
                            Debug Nav
                            <button
                                type="button"
                                onClick={() =>
                                    setDebugSessionStatus(
                                        SessionStatus.nurseQueue,
                                    )
                                }
                                // eslint-disable-next-line react/jsx-no-literals
                            >
                                to nurse queue
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setDebugSessionStatus(
                                        SessionStatus.nurseJoining,
                                    )
                                }
                                // eslint-disable-next-line react/jsx-no-literals
                            >
                                to nurse-joining
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setDebugSessionStatus(
                                        SessionStatus.nurseCall,
                                    )
                                }
                                // eslint-disable-next-line react/jsx-no-literals
                            >
                                to nurse-call
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setDebugSessionStatus(
                                        SessionStatus.veterinarianQueue,
                                    )
                                }
                                // eslint-disable-next-line react/jsx-no-literals
                            >
                                to vet-queue
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setDebugSessionStatus(
                                        SessionStatus.veterinarianJoining,
                                    )
                                }
                                // eslint-disable-next-line react/jsx-no-literals
                            >
                                to vet-joining
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setDebugSessionStatus(
                                        SessionStatus.veterinarianCall,
                                    )
                                }
                                // eslint-disable-next-line react/jsx-no-literals
                            >
                                to vet-call
                            </button>
                        </div>
                    )}
                    {RenderMainComponent(sessionStatusToUse)}
                    <Video
                        twilioAuthToken={twilioAuthToken}
                        roomId={sessionId}
                        sessionStatus={sessionStatusToUse}
                        hideChatIcon={
                            sessionStatusToUse !== SessionStatus.nurseCall &&
                            sessionStatusToUse !==
                                SessionStatus.veterinarianCall
                        }
                        chatOpen={chatOpen}
                        setChatOpen={setChatOpen}
                        onAuthExpired={onAuthExpired}
                        onEndCall={handleSwitchEndCallModal}
                        mirrorVideoOpen={!isSyncActive}
                        onDisconnected={handleOnDisconnected}
                        onRoomExpired={handleOnRoomExpired}
                        largeMirrorVideo
                    />
                    <PermissionWarning />
                </div>
                <Modal
                    open={endCallModalOpen}
                    onClose={handleSwitchEndCallModal}
                    aria-labelledby="modal-title"
                >
                    <div className={styles['end-call-modal']}>
                        <h2
                            id="modal-title"
                            className={styles['end-call-title']}
                        >
                            {intl.formatMessage({
                                id: 'callPage.endCall.hint',
                            })}
                        </h2>
                        <div className={styles['end-call-modal-buttons']}>
                            <Button
                                label={intl.formatMessage({
                                    id: 'callPage.endCall.end',
                                })}
                                as="button"
                                variant="tertiary"
                                onClick={handleEndCall}
                            />
                            <Button
                                as="button"
                                onClick={handleSwitchEndCallModal}
                                label={intl.formatMessage({
                                    id: 'callPage.endCall.stay',
                                })}
                            />
                        </div>
                    </div>
                </Modal>
            </ChatContext.Provider>
        </VideoContext.Provider>
    );
}

export default withFirebaseInitialized(CallPage);
